import { Injectable } from '@angular/core';
import {MkaApiRequest} from './mka-api-request.service';

@Injectable({
    providedIn: 'root'
})
export class ExtraFieldsService {

    public extraFields = null;
    constructor(private mkaApiRequest: MkaApiRequest) {
    }

    /**
     * Get all Extrafields (used by ExtraFieldsResolver)
     *
     * @returns {Promise<any> | any}
     */
    getAll(): Promise<any>|any {
        if (this.extraFields) {
            return this.extraFields;
        }
        return this.mkaApiRequest.getRequest('subcategories')
            .toPromise()
            .then((data: Response) => {
                this.extraFields = data;
                return this.extraFields;
            })
            .catch(this.handleError);
    }

    public handleError(error: any): Promise<any> {
        return Promise.reject(error.message);
    }


}
