<table *ngIf="productDetail" class="table table-bordered table-hover sales-list">
  <tbody>
  <ng-container *ngFor="let field of fields">
    <!--<tr class="{{ (field == 'category')? 'thead' : ''}}" *ngIf="(!fieldDetails[field].dependent_to) || (fieldDetails[field].dependent_to && fieldDetails[field].dependent_values.indexOf(productDetail[fieldDetails[field].dependent_to]) >= 0) ">-->
    <tr class="{{ (field == 'category')? 'thead' : ''}}" *ngIf="checkToShow(field)">
      <td [colSpan]=" fieldDetails[field].colspan ? 2 : 1 ">
        <div>{{fieldDetails[field].formattedName | translate}}</div>
        <div><textarea *ngIf="fieldDetails[field].colspan" [attr.name]="field" [disabled]="!editable"
                       [(ngModel)]="productDetail[field]"></textarea></div>
      </td>
      <td *ngIf="field && fieldDetails[field].colspan===false" [ngSwitch]="fieldDetails[field].type">
        <input *ngSwitchCase="'input'" [attr.type]="'text'" [attr.name]="field" [disabled]="!editable"
               [(ngModel)]="productDetail[field]" />
        <div *ngSwitchCase="'select'">
          <div *ngIf="!editable || nonEditableFields.indexOf(field) !== -1">{{productDetail[field]}}</div>
          <select *ngIf="editable && nonEditableFields.indexOf(field) === -1" [attr.name]="field + '_id'"
                  [(ngModel)]="productDetail[field + '_id']" (change)="onChange(field, $event.target.value)"
                  [ngModelOptions]="{standalone: true}">
            <option *ngFor="let item of selects[field]" [value]="item.id"
                    [selected]="(productDetail[field + '_id'] == item.id) ? 'selected' : ''">
              {{(fieldDetails[field].with_other && item.id == 1) ? ('api.other' | translate) : (item[fieldDetails[field].option_attr])}}
            </option>
          </select>
          <input *ngIf="editable && fieldDetails[field].with_other && (productDetail[field + '_id'] == 1)"
                 [attr.type]="'text'" [attr.name]="field" [(ngModel)]="productDetail[field]" />
        </div>
        <div *ngSwitchCase="'select_date'">

          <div *ngIf="!editable">{{(productDetail[field] == 1) ? 'other' : productDetail[field] }}</div>
          <select *ngIf="editable" [attr.name]="field" [(ngModel)]="productDetail[field]"
                  (change)="onChange(field, $event.target.value, 'select_date')" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let item of selects[field]" [value]="item.id"
                    [attr.selected]="(productDetail[field] == item.id) ? 'selected' : ''">{{item[fieldDetails[field].option_attr]}}</option>
          </select>

        </div>
        <ng-container *ngSwitchCase="'test_select_date'" >
          <span *ngIf="!editable">{{productDetail[field] | date: 'yyyy MMM'}}</span>
          <ng-container *ngIf="editable">
            <input [attr.type]="'date'" [attr.name]="field"
                   [(ngModel)]="productDetail[field]"/>
          </ng-container>
        </ng-container>
        <div *ngSwitchCase="'select_direct'">
          <div *ngIf="!editable">{{productDetail[field]}}</div>
          <select *ngIf="editable" [attr.name]="field + '_id'" [(ngModel)]="productDetail[field + '_id']"
                  (change)="onChange(field, $event.target.value)" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let item of selects[field]" [value]="item.id"
                    [attr.selected]="(productDetail[field + '_id'] == item.id) ? 'selected' : ''">{{item[fieldDetails[field].option_attr]}}</option>
          </select>

        </div>
        <div *ngSwitchCase="'boolean'">
          <div *ngIf="!editable">{{ ((productDetail[field] === 1) ? 'api.yes' : 'api.no') | translate}}</div>
          <select *ngIf="editable" [attr.name]="field" [(ngModel)]="productDetail[field]"
                  (change)="onChange(field, $event.target.value)">
            <option [value]="1"
                    [selected]="(productDetail[field] == 1) ? 'selected' : ''">{{ 'api.yes' | translate }}</option>
            <option [value]="0"
                    [selected]="(productDetail[field] == 0) ? 'selected' : ''">{{ 'api.no' | translate }}</option>
          </select>
        </div>
        <div *ngSwitchCase="'rating'">
          <app-rating [value]="productDetail[field]" [editable]="editable"
                      (onChange)="onChange(field, $event)"></app-rating>
        </div>
        <div *ngSwitchDefault>{{ productDetail[field] }}</div>

      </td>

    </tr>
  </ng-container>
  <tr *ngIf="withCustomerName">
    <td>{{'fields.customer_name' | translate}}</td>
    <td>{{productDetail.customer_name}}</td>
  </tr>
  <tr *ngIf="withCustomerLocation">
    <td>{{'api.location' | translate}}</td>
    <td>{{productDetail.location}}</td>
  </tr>
  <tr>
    <td>ID</td>
    <td>{{productDetail.id}}</td>
  </tr>
  <tr *ngIf="withPrices && !productDetail.without_book_value">
    <td>{{'api.book_value' | translate}}</td>
    <td>{{productDetail.book_value | multipipe:'currency:' + productDetail.book_value_currency + ':symbol:1.2-4'}}</td>
  </tr>

  <tr *ngIf="withPrices && !productDetail.without_sales_price">
    <td>{{'api.sales_price' | translate}}</td>
    <td>{{productDetail.sales_price | multipipe:'currency:' + productDetail.sales_price_currency + ':symbol:1.2-4'}}</td>
  </tr>

  <tr *ngIf="productDetail.wholesales_price && role=='trader'">
    <td>{{'api.wholesales_price' | translate}}</td>
    <td>{{productDetail.wholesales_price | multipipe:'currency:' + productDetail.wholesales_price_currency + ':symbol:1.2-4'}}</td>
  </tr>

  <tr>
    <td>{{'api.date_intake' | translate}}</td>
    <td>{{productDetail.created_at | multipipe:'date:yyyy-MM-dd'}}</td>
  </tr>

  </tbody>
</table>
