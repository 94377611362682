<div class="container">

    <app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>

    <h3 class="sales-heading"></h3>

    <div class="row">
        <div class="col-sm-6">
            <form class="form-horizontal" #form>
                <div class="form-group">
                    <label for="name" class="col-sm-4">{{ 'api.group_name' | translate }}</label>
                    <div class="col-sm-8">
                        <input type="text" name="name" class="form-control" id="name"
                               [(ngModel)]="item.name" placeholder="{{ 'api.group_name' | translate }}">
                    </div>
                </div>

                <div class="form-group" *ngIf="dealers.length">
                    <label for="dealer_id" class="col-sm-4">{{ 'api.dealer' | translate }}</label>
                    <div class="col-sm-8">
                        <select [attr.name]="'dealer_id'" class="form-control" [(ngModel)]="item.dealer_id"
                                [ngModelOptions]="{standalone: true}" id="dealer_id">
                            <option *ngFor="let dealer of dealers" [value]="dealer.id"
                                    [selected]="(item.dealer_id == dealer.id) ? 'selected' : ''">{{dealer.name}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group" *ngIf="userList.length">
                    <label class="col-sm-4">{{ 'api.group_users' | translate }}</label>
                    <div class="col-sm-8 select-sm">
                        <ng-select [multiple]="true"
                                   [items]="userList"
                                   [(ngModel)]="item.user_ids"
                                   (add)="refreshValue()"
                                   (remove)="refreshValue()"
                                   (clear)="refreshValue()"
                                   name="user_ids"
                                   bindLabel="name"
                                   bindValue="id"
                                   placeholder="{{ 'api.select_users' | translate }}"></ng-select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4"></label>
                    <div class="col-sm-8">
                        <input type="submit" (click)="onSubmit()" name="login_submit" class="login-name buttons"
                               id="submit"
                               value="{{ 'api.confirm' | translate }}">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
