import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {MkaApiRequest} from './mka-api-request.service';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    constructor(protected mkaApiRequest: MkaApiRequest) {
    }

    getGroupTranslations(group: string): Observable<any> {
        return this.mkaApiRequest.getRequest('translations/' + group);
    }

    saveTranslation(group, key, locale, value): Observable<any> {
        return this.mkaApiRequest.putRequest('translations/' + group, {
            key: key,
            locale: locale,
            value: value
        });
    }

    addTranslation(translationItem): Observable<any> {
        return this.mkaApiRequest.postRequest('translations', translationItem);
    }

    publishTranslations(group) {
        return this.mkaApiRequest.postRequest('publish-translations/' + group, {});
    }

    deleteTranslation(item) {
        return this.mkaApiRequest.postRequest('delete-translation', item);
    }

}

