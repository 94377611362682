import {Component} from '@angular/core';
import {AuthService} from './shared/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {TransApiService} from './shared/services/trans-api.service';
import {LoadingEventService} from './shared/services/loading-event.service';
import {LoadingEvent} from './shared/models/event';
import {Global} from './shared/helper-classes/global';
import {MkaApiRequest} from './shared/services/mka-api-request.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    translationLoaded: boolean = false;

    constructor(public authService: AuthService,
                translate: TranslateService,
                transApi: TransApiService,
                protected loadingEventService: LoadingEventService,
                public app: Global) {
        // initialize AuthService
        this.authService.initialiseService();

        // translation loaded
        this.loadingEventService.subscribeToEvent(LoadingEvent.translationLoaded).subscribe((body) => {
            this.translationLoaded = true;
        });

        // get the translations
        translate.setDefaultLang(transApi.defaultLocale);
        transApi.load(true);
    }

    title = 'app';
}
