import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HeaderMenuItem } from '../../models/header-menu-item';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { GeneralDetailEventService } from '../../services/general-detail-event.service';
import { GeneralDetailEvent } from '../../models/event';
import { filter } from 'rxjs/operators';
import { GeneralService } from '../../services/general.service';


@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  providers: [
    { provide: 'instance3', useClass: GeneralService },
    { provide: 'instance2', useClass: GeneralService },
    { provide: 'uri', useValue: 'specifications' }
  ]
})

export class HeaderMenuComponent implements OnInit, OnChanges {

  currentUrl: string = '';
  lastListingUrl: string = '';
  role: string = '';

  @Input() headerMenuItems: HeaderMenuItem[];
  @Input() withLogin: boolean = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    protected generalDetailEventService: GeneralDetailEventService,
    @Inject('instance2') private exportService: GeneralService,
    @Inject('instance3') private exportXMLService: GeneralService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (localStorage.getItem('current_user')) {
      this.role = JSON.parse(localStorage.getItem('current_user')).roles[0];
    }
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentUrl = this.router.routerState.snapshot.url.substr(1);
      });

    this.lastListingUrl = localStorage.getItem('listing.currentRouteName') ?
      localStorage.getItem('listing.currentRouteName').substr(1) : '';
    this.generalDetailEventService.subscribeToEvent(GeneralDetailEvent.afterListingInitialized).subscribe(() => {
      this.lastListingUrl = localStorage.getItem('listing.currentRouteName') ?
        localStorage.getItem('listing.currentRouteName').substr(1) : '';
    });
    this.generalDetailEventService.subscribeToEvent(GeneralDetailEvent.clearLastListingURL).subscribe(() => {
      this.lastListingUrl = '';
    });
  }

  /**
   * The logout action is performed
   * After this the user is redirected to login.
   */
  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  export(times: any) {
    const item = {
      from_time: times.fromTime,
      until_time: times.untilTime,
    };

    // accept the bid (to API)
    this.exportService.setUri('generateExcel');
    this.exportService.save(item).subscribe(
      data => {
        const response = data.data;
      }
    );
  }

  exportXML() {
    this.exportXMLService.setUri('generateXML');
    this.exportXMLService.save({}).subscribe(
      data => {
        const response = data.data;
      }
    );
  }
}
