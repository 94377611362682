import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { LoginComponent } from './components/login/login.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {AutologinComponent} from './components/autologin/autologin-component';
import {GeneralService} from '../shared/services/general.service';
import {GroupSelectionService} from '../internal-platform-module/services/group-selection.service';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,

        SharedModule
    ],
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        AutologinComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
    ],
    providers: [
        GeneralService,
        GroupSelectionService,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA // allow custom elements in ng-content (e.g <popup-body>)
    ]
})
export class AuthModule { }
