import { Component, Directive, OnInit } from '@angular/core';
import { GeneralList } from '../../../helper-classes/general-list';
import { GeneralService } from '../../../services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralDetailEventService } from '../../../services/general-detail-event.service';
import { FieldsdetailsService } from '../../../services/fieldsdetails.service';
import { LeadsFieldsdetailsService } from '../../../../leads-platform-module/services/leadsFieldsdetails.service';
import { GeneralDetailEvent } from '../../../models/event';
import { StandardPrice } from '../../../models/standard-price';

@Directive()
export abstract class BaseStandardPricesComponent extends GeneralList implements OnInit {
  rows: StandardPrice[];
  section: string = '';

  protected constructor(protected generalService: GeneralService,
                        protected route: ActivatedRoute,
                        protected router: Router,
                        protected generalDetailHandler: GeneralDetailEventService,
                        protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
    super(generalService, route, router, generalDetailHandler);

    this.subscriptions.push(
      this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterDelete).subscribe(() => {
        this.fieldsdetailsService.getAllFieldsDetails(true);
      })
    );
  }

  ngOnInit() {
    // specify the listing columns
    this.columns = [
      {
        name: 'id',
        formattedName: 'api.id'
      },
      {
        name: 'model',
        formattedName: 'api.model'
      },
      {
        name: 'battery_capacity',
        formattedName: 'api.battery_capacity'
      },
      {
        name: 'older_than_x_years',
        formattedName: 'api.older_than_x_years'
      },
      {
        name: 'running_hours_higher_than',
        formattedName: 'api.running_hours_higher_than'
      },
      {
        name: 'standard_trade_in_price',
        formattedName: 'api.standard_trade_in_price'
      },
      {
        name: 'updated_at',
        formattedName: 'api.updated_at',
        pipes: 'year'
      }
    ];

    this.initialize();


    // breadcrumb elements
    this.breadcrumbs = [
      {
        name: 'api.home',
        url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
        isActive: false
      },
      {
        name: 'Standard Prices',
        url: '',
        isActive: true
      }];
  }

}
