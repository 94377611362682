import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../services/general.service';
import {GeneralList} from '../../helper-classes/general-list';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: [
        './company.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        GeneralService,
        {provide: 'uri', useValue: 'companies'},
    ]
})

export class CompanyComponent extends GeneralList implements OnInit {

    @Input() actionLink: string;

    constructor(protected generalService: GeneralService, protected route: ActivatedRoute, protected router: Router) {
        super(generalService, route, router);
    }

    ngOnInit() {
        // general initialization
        this.initialize();

        // specify the listing columns
        this.columns = [
            {
                name: 'name',
                formattedName: 'api.company_name'
            },
        ];

        this.breadcrumbs = [
            {
                name: 'api.companies',
                url: '',
                isActive: true,
            }];
    }
}
