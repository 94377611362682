<div class="container" *ngIf="enablePlatformSelect">


    <div class="row">
        <div class="col-sm-12 text-center">
            <p class="welcome-text">{{'api.platform_selector_text' | translate}}</p>
        </div>
    </div>

    <div class="notfound-container col-sm-12">
        <div *ngIf="platforms">
            <div class="form-group">
                <label class="col-sm-2">{{ 'api.choose_division' | translate }}</label>
                <div class="col-sm-10">
                    <div class="radio" *ngFor="let tr_key of platfrom_keys">
                        <label><input type="radio" name="division" [(ngModel)]="divisionLink"
                                      value="{{ platforms[tr_key] }}">{{ tr_key | translate }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-sm-12 text-center">
                <a class="login-name buttons" (click)="navigate()">{{'api.continue' | translate}}</a>
            </div>
        </div>

    </div>
</div>
