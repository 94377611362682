<div class="container">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
    <h3>{{title | translate}}</h3>
    <app-table-list
            [columns]="columns"
            [rows]="rows"
            [sortBy]="sortBy"
            [hasActions]="true"
            [numberOfPages]="numberOfPages"
            [currentPage]="currentPage"
            [currentSortBy]="currentSortBy"
            (onAdd)="add()"
            (onEdit)="edit($event)"
            (onDelete)="delete($event)"
            (onRowClick)="rowClickAction($event)"
            (onPageChange)="onPageChange($event)"
            (onItemPerPageChange)="onItemPerPageChange($event)"
            (onSortByChange)="onSortByChange($event)"
            (onSearchChange)="onSearchChange($event)"
    ></app-table-list>
    <button
            class="btn btn-sm custom-sm-btn extend"
            data-toggle="modal"
            [attr.data-target] = "'#exportTime'"
    >
        {{'api.generate_export' | translate}}
    </button>
    <app-export-time-popup [modalId]="'exportTime'" (continue)="export($event)"></app-export-time-popup>

    <button
            *ngIf="xmlColumnExists"
            class="btn btn-sm custom-sm-btn extend"
            (click)="exportXML()"
    >
        {{'api.generate_xml_export' | translate}}
    </button>

</div>