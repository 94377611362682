
<div *ngIf="hasHeaderWithFilter" class="breadcrumb">
    <div class="row ">

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center filterbuttons">
<!--            <div class="col-sm-3 col-xs-6 pl0 marginbottom_5">-->
<!--                <input type="button" name="button" title="" class="status-filter-buttons buttons pull-left for_sale width100 {{ (this.status_filter == 'for_sale' || this.status_filter == '') ? ('active') : ('not_active') }}"-->
<!--                   value="{{ 'api.for_sale' | translate }}" (click)="filterByStatus('for_sale')">-->
<!--            </div>-->
<!--            <div class="col-sm-3 col-xs-6 pl0 marginbottom_5">-->
<!--                <input type="button" name="button" title="" class="status-filter-buttons buttons pull-left pending width100 {{ (this.status_filter == 'pending' || this.status_filter == '') ? ('active') : ('not_active') }}"-->
<!--                       value="{{ 'api.pending' | translate }}" (click)="filterByStatus('pending')">-->
<!--            </div>-->
<!--            <div class="col-sm-3 col-xs-6 pl0 marginbottom_5">-->
<!--                <input type="button" name="button" title="" class="status-filter-buttons buttons pull-left sold width100 {{ (this.status_filter == 'sold' || this.status_filter == '') ? ('active') : ('not_active') }}"-->
<!--                       value="{{ 'api.sold' | translate }}" (click)="filterByStatus('sold')">-->
<!--            </div>-->
<!--            <div class="col-sm-3 col-xs-6 pl0 marginbottom_5">-->
<!--                <input type="button" name="button" title="" class="status-filter-buttons buttons pull-left archive width100 {{ (this.status_filter == 'archive' || this.status_filter == '') ? ('active') : ('not_active') }}"-->
<!--                       value="{{ 'api.archive' | translate }}" (click)="filterByStatus('archive')">-->
<!--            </div>-->
          <ng-container *ngFor="let incoming_status of machine_incomming_statuses">
            <div class="col-sm-3 col-xs-6 pl0 marginbottom_5"  >
              <input type="button" name="button" title="" class="status-filter-buttons buttons pull-left width100" [ngStyle]="{'background-color' :  selected_machine_incomming_status_filter == incoming_status.id ? 'red' : 'grey'}"
                     style="text-overflow: ellipsis; overflow: hidden;"
                     tooltip="{{incoming_status.translatedName}}"
                     value="{{ incoming_status.translatedName}}" (click)="filterByIncommingStatus(incoming_status.id)">
            </div>
          </ng-container>
          <ng-container *ngFor="let outgoing_status of machine_outgoing_statuses">
            <div class="col-sm-3 col-xs-6 pl0 marginbottom_5"  *ngIf="outgoing_status.id === 6" >
              <input type="button" name="button" title="" class="status-filter-buttons buttons pull-left width100" [ngStyle]="{'background-color' :  selected_machine_outgoing_status_filter == outgoing_status.id ? 'red' : 'grey'}"
                     style="text-overflow: ellipsis; overflow: hidden;"
                     tooltip="{{outgoing_status.translatedName}}"
                     value="{{ outgoing_status.translatedName}}" (click)="filterByOutgoingStatus(outgoing_status.id)">
            </div>
          </ng-container>
          <div class="col-sm-3 col-xs-6 pl0 marginbottom_5">
            <input type="button" name="button" title="" class="status-filter-buttons buttons pull-left width100" [ngStyle]="{'background-color' :  selected_machine_incomming_status_filter == null && selected_machine_outgoing_status_filter == null ? 'red' : 'grey'}"
                   value="{{ 'api.all' | translate}}" (click)="filterByIncommingStatus(null)">
          </div>
        </div>
        <div class="col-md-2 col-sm-4 col-xs-12 ">
            <form class="form-group marginBottom0" role="search">
                <div class="input-group">
                    <input type="text" class="form-control searchinput" #searchinput
                           placeholder="{{'api.search' | translate}}" (keydown)="keyDownFunction($event, searchinput.value)" value="{{searchText}}">
                    <span class="input-group-addon" (click)="search(searchinput.value)">
            <i class="fa fa-search whiteImportant"></i>
          </span>
                </div>
            </form>
        </div>
        <div class="col-md-2 col-sm-4 col-xs-12 text-center">
            <div class="col-sm-12 pagination ">
                <div *ngFor="let item of createPages(numberOfPages)" class="inlineBlock padding0">
                    <ng-component *ngIf="item!=currentPage; then standard else current_page"></ng-component>

                    <ng-template #standard>
                        <ng-component *ngIf="item!='...'; then link else points"></ng-component>
                        <ng-template #link><a (click)="changePage(item)" class="page">{{item}}</a></ng-template>
                        <ng-template #points><span>.....</span></ng-template>
                    </ng-template>
                    <ng-template #current_page><span *ngIf="item==currentPage || item=='...'" class="page active">{{item}}</span>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-sm-4 col-xs-12 {{(hasHeaderWithFilter && table_filter.length >0) ? '': 'hidden-xs hidden-sm'}}">
            <div class="inlineBlock hidden-xs hidden-sm">
                <select class="form-control box" #t (change)=changeItemPerPage(t.value)>
                  <ng-container *ngFor="let option of itemsPerPageSelect">
                    <option [selected]="(option == itemPerPage) ? 'selected' : ''">{{option}}</option>
                  </ng-container>
                </select>
            </div>
            <div class="inlineBlock">
                <label *ngIf="!(hasHeaderWithFilter && table_filter.length >0)" class="control-label hidden-xs hidden-sm">{{'api.items_per_page' | translate}}</label>
                <button *ngIf="hasHeaderWithFilter && table_filter.length >0" class="reorder" (click)="resetFilters()">{{'api.reset_filters' | translate}}</button>

            </div>
        </div>

    </div>
</div>
<div *ngIf="hasHeader" class="breadcrumb">
    <div class="row">

        <div class="col-md-3 col-sm-6 col-xs-12 breadcrumbPart">
            <form class="form-group marginBottom0" role="search">
                <div class="input-group">
                    <input type="text" class="form-control" #searchinput
                           placeholder="{{'api.search_product_id' | translate}}" id="inputGroup" (keydown)="keyDownFunction($event, searchinput.value)" value="{{searchText}}">
                    <span class="input-group-addon" (click)="search(searchinput.value)">
            <i class="fa fa-search whiteImportant"></i>
          </span>
                </div>
            </form>
        </div>
        <div *ngIf="hasHeaderWithSortBy" class="col-md-4 col-sm-6 col-xs-12 breadcrumbPart">
            <div class="inlineBlock marginRight10">
                <label for="selectbox" class="control-label">{{'api.sort_on' | translate}}:&nbsp;</label>
            </div>
            <div class="inlineBlock width80">
                <select id="selectbox" class="form-control box" #sort (change)=changeSortBy(sort.value)>
                    <option *ngFor="let item of sortBy" value="{{item.value}}" [selected]="(item.value == currentSortBy) ? 'selected' : ''">{{item.name | translate}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 breadcrumbPart">
            <div class="col-sm-12 pagination ">
                <div class="inlineBlock marginRight10">
                    <label>{{'api.pages' | translate}}</label>
                </div>
                <div *ngFor="let item of createPages(numberOfPages)" class="inlineBlock padding0">
                    <ng-component *ngIf="item!=currentPage; then standard else current_page"></ng-component>

                    <ng-template #standard>
                        <ng-component *ngIf="item!='...'; then link else points"></ng-component>
                        <ng-template #link><a (click)="changePage(item)" class="page">{{item}}</a></ng-template>
                        <ng-template #points><span>.....</span></ng-template>
                    </ng-template>
                    <ng-template #current_page><span *ngIf="item==currentPage || item=='...'" class="page active">{{item}}</span>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-sm-6 col-xs-12 hidden-xs hidden-sm breadcrumbPart">
            <div class="inlineBlock marginRight10">
                <select class="form-control box" #t (change)=changeItemPerPage(t.value)>
                  <ng-container *ngFor="let option of itemsPerPageSelect">
                    <option [selected]="(option == itemPerPage) ? 'selected' : ''">{{option}}</option>
                  </ng-container>
                </select>
            </div>
            <div class="inlineBlock">
                <label class="control-label">{{'api.items_per_page' | translate}}</label>
            </div>
        </div>
        <div class="col-md-2 col-sm-6 col-xs-12 breadcrumbPart">
            <button  *ngIf="table_filter.length >0" class="reorder" (click)="resetFilters()">{{'api.reset_filters' | translate}}</button>
        </div>

    </div>
</div>

<div class="col-xs-12 marginBottom15">
    <div class="row">
        <div class="col-sm-10"></div>
        <div class="col-sm-1"><button  *ngIf="draggable && dragged" class="reorder" (click)="reset()">{{'api.reset' | translate}}</button></div>
        <div class="col-sm-1"><button  *ngIf="draggable && dragged" class="reorder" (click)="reOrder()">{{'api.reorder' | translate}}</button></div>
    </div>
</div>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 machines-table">
<table class="table table-bordered table-hover sales-list fontSize11Important marginTop10">

    <thead>
    <tr *ngIf="columns.length > 0">

        <ng-container *ngFor="let column of columns">

        <th ngClass="open-search" style="position: relative; padding-right: 20px;" >
            <!--{{column.formattedName | translate}}-->

          <!--<div class="search-popup" (click)="preventDefault($event)">-->
            <!--<div class="close-btn" (click)="closeSearch($event)" style="float: right;">-->
              <!--<img src="assets/images/close.png">-->
            <!--</div>-->
            <!--<div style="padding-right: 35px;">-->
<!--          todo fix this [()] bindings-->
                <app-table-filter #valami (onFilterChange)="onFilterChange($event)" class="table-filter" [type]="filter_type[column.name]" [column]="column" [column_name]="column.name" [column_searchable]="column.searchable" [tableFilter]="tableFilter" ></app-table-filter>
            <!--</div>-->
          <!--</div>-->
            <!--<div *ngIf="column.searchable">-->
                <!--<div style="border: 1px solid grey; top: 0; right: 0; padding: 2px; position: absolute;" (click)="openSearch($event, column.searchable)" ><i class="fa fa-sort-down" aria-hidden="true"></i></div>-->
                <!--<div style="border: 1px solid grey; top: 0; right: 0; padding: 2px; position: absolute;"><i class="fa fa-filter" aria-hidden="true"></i></div>-->
            <!--</div>-->

            <!--<ng-container *ngFor="let selected of selected_filter" >-->

            <!--&lt;!&ndash;<div class="selected-filter" *ngIf="selected.text != '' && selected.attr == column.name"> {{selected.text}} </div>&ndash;&gt;-->

                    <!--&lt;!&ndash;<div *ngIf="selected.text != '' && selected.attr == column.name" style="border: 1px solid grey; top: 0; right: 0; padding: 2px; position: absolute;"><i class="fa fa-filter" aria-hidden="true"></i></div>&ndash;&gt;-->

            <!--</ng-container>-->


        </th>

        </ng-container>

        <th *ngIf="hasActions" class="actions-column textAlignCenter"><a
                (click)="addAction()"><i class="fa fa-user-plus" aria-hidden="true"></i></a></th>

    </tr>
    </thead>
  <tbody *ngIf="rows" [dragula]='"table-box"' [(dragulaModel)]='rows'>
    <tr *ngIf="hasActions && !draggable">
      <td *ngFor="let column of sortColumnBy('order')"></td>
      <td class="actions-column textAlignCenter"><a (click)="addAction()"><i class="fa fa-user-plus" aria-hidden="true"></i></a></td>
    </tr>
    <tr *ngFor="let row of rows;" (dblclick)="rowClickAction(row)" [ngStyle]="{'backgroundColor': row.deleted_at ? '#c00f2317': ''}">
        <td *ngFor="let column of sortColumnBy('order')" [attr.align]="(!column.align)?'left':column.align" [ngClass]="{'bold': row.attr_is_bold}">
            <span *ngIf="!column.goToDetailPage"
                  [innerHtml]="
                    column.pipes
                        ? (
                            nameOrKey(row, column.name, column.key)  | multipipe:column.pipes.replace('currency_type', row[column.name + '_currency']).replace('customStatuses1', customStatuses1).replace('customStatuses2', customStatuses2).replace('customStatuses3', customStatuses3))
                        : (nameOrKey(row, column.name, column.key) == '1' && (column.type == '3' || column.type == '4' || column.name == 'year_of_manufacture' ))?'other' : nameOrKey(row, column.name, column.key)"
            [ngClass]=" column.name == 'location' ? 'location' : '' "></span>
            <span *ngIf="column.goToDetailPage"
                  [innerHtml]="column.pipes
                        ? ( (column.key ? row[column.name][column.key] : row[column.name]) | multipipe:column.pipes.replace('currency_type', row[column.name + '_currency']) | translate)
                        :((column.key ? row[column.name][column.key] : row[column.name]) =='1' && (column.type == '3' || column.type == '4' || column.name == 'year_of_manufacture' ))?'other' : (column.key ? row[column.name][column.key] : row[column.name])"
                  (click)="rowClickAction(row)" class="link"
            ></span>

            <span *ngIf="column.type == 'checkbox'">
                <input type="checkbox" value="{{row[column.valueField]}}" (change)="checkboxCheckedAction()" [(ngModel)]="formElementsData[column.name][row[column.valueField]]">
            </span>

        </td>
        <td *ngIf="hasActions" class="actions-column">
            <a (click)="editAction(row)"><i class="fa fa-cogs" aria-hidden="true"></i></a>
            <a (click)="deleteAction(row)"><i class="fa fa-minus-circle pull-right sales-delete" aria-hidden="true"></i></a>
        </td>
    </tr>
    <tr *ngIf="hasActions && !draggable">
      <td *ngFor="let column of columns;"></td>
      <td class="actions-column textAlignCenter"><a (click)="addAction()"><i class="fa fa-user-plus" aria-hidden="true"></i></a></td>
    </tr>
    </tbody>
</table>
</div>
