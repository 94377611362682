import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralDetail} from '../../../helper-classes/general-detail';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {GeneralDetailEvent} from '../../../models/event';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {Specification} from '../../../../internal-platform-module/models/specification';
import {GeneralFields} from '../../../helper-classes/general-fields';
import {ProductFieldsComponent} from '../../product-fields/product-fields.component';
import _ from 'lodash';

@Component({
    selector: 'app-specification-detail',
    templateUrl: './specification-detail.component.html',
    styleUrls: [
        './specification-detail.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        GeneralService,
        {provide: 'uri',  useValue: 'specifications'},
    ],
})

export class SpecificationDetailComponent extends GeneralDetail implements OnInit {

    item: Specification;
    title: string = 'api.specification';
    selects: any = [];
    attributes: any = {};
    fields = [];
    fieldDetails: any;

    private createSelect(min: number, max: number) {
        const range: any = [];
        const start = (min) ? min : _.get(this.route.snapshot.data, 'fieldsDetails.data.year_selector_min_value', 1978);
        const stop = (max) ? max : ((new Date()).getFullYear());
        for (let i = start; i <= stop; i++) {
            range.push({'id' : i, 'name' : i});
        }

        range.push({'id' : 1, 'name' : 'other'});

        return range;
    }

    constructor(
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService
        ) {
        super(generalService, router, route, errorEventService, loadingEventService, renderer, generalDetailHandler);

        this.selects['category'] = this.route.snapshot.data.fieldsDetails.data.category.filter(x => x.deleted_at == null);
        // this.fields = this.route.snapshot.data.internalSpecificationColumns.data.map(x => x.name);

        this.fields = ['category', 'type', 'subcategory'];
        for (const attribute of this.route.snapshot.data.fieldsDetails.data.attributes.filter(x => x.specification_type == 1 || x.specification_type == 3)) {
            this.fields.push(attribute.attribute_name);
        }
        for (const attribute of this.route.snapshot.data.fieldsDetails.data.attributes.filter(x => x.specification_type == 2 || x.specification_type == 4)) {
            this.fields.push(attribute.attribute_name + '_from');
            this.fields.push(attribute.attribute_name + '_to');
        }

        for (const attribute of this.route.snapshot.data.fieldsDetails.data.attributes) {
            switch (attribute.type) {
                case 2: // select_with_other - ATTRIBUTE_TYPES[3]
                case 3: // select_with_other - ATTRIBUTE_TYPES[3]
                    this.attributes[attribute.attribute_name] = {
                        formattedName: 'fields.' + attribute.attribute_name,
                        colspan: false,
                        type: 'select',
                        option_attr: (attribute.translatable == 1) ? 'translated' : 'name',
                        with_other: true
                    };
                    break;
                case 4: // dropdown_year - ATTRIBUTE_TYPES[6]
                    this.attributes[attribute.attribute_name] = {
                        formattedName: 'fields.' + attribute.attribute_name,
                        colspan: false,
                        type: 'select_date',
                        option_attr: 'name'
                    };
                    break;
                case 5: // input - ATTRIBUTE_TYPES[6]
                    if (attribute.specification_type === 2 || attribute.specification_type === 4) {
                        this.attributes[attribute.attribute_name + '_from'] = {
                            formattedName: 'fields.' + attribute.attribute_name + '_from',
                            colspan: false,
                            type: 'input'
                        };
                        this.attributes[attribute.attribute_name + '_to'] = {
                            formattedName: 'fields.' + attribute.attribute_name + '_to',
                            colspan: false,
                            type: 'input'
                        };
                    } else {
                        this.attributes[attribute.attribute_name] = {
                            formattedName: 'fields.' + attribute.attribute_name,
                            colspan: false,
                            type: 'input'
                        };
                    }
                    break;
                case 6: // input - ATTRIBUTE_TYPES[6]
                    if (attribute.specification_type === 2 || attribute.specification_type === 4) {
                        this.attributes[attribute.attribute_name + '_from'] = {
                            formattedName: 'fields.' + attribute.attribute_name + '_from',
                            colspan: false,
                            type: 'input'
                        };
                        this.attributes[attribute.attribute_name + '_to'] = {
                            formattedName: 'fields.' + attribute.attribute_name + '_to',
                            colspan: false,
                            type: 'input'
                        };
                    } else {
                        this.attributes[attribute.attribute_name] = {
                            formattedName: 'fields.' + attribute.attribute_name,
                            colspan: false,
                            type: 'input'
                        };
                    }
                    break;
                case 0: // input - ATTRIBUTE_TYPES[6]
                    this.attributes[attribute.attribute_name] = {
                        formattedName: 'fields.' + attribute.attribute_name,
                        colspan: false,
                        type: 'input'
                    };
                    break;
                default:
                    console.log('problem');
            }
        }

        for (const key in this.attributes) {
            if (this.attributes[key]
                && (this.attributes[key].type === 'select' || this.attributes[key].type === 'select_direct')) {
              if (this.route.snapshot.data.fieldsDetails.data[key]) {
                this.selects[key] = this.route.snapshot.data.fieldsDetails.data[key].filter(x => x.id !== 1);
              }
            }
        }

        this.fieldDetails =  {...GeneralFields.FIELD_DETAILS, ...this.attributes};

        // afterGetItem
        this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterGetItem).subscribe(() => {
            if ((this.selects['category'].find(i => i.id === this.item.category_id)) !== undefined) {
                this.selects['type'] = this.route.snapshot.data.fieldsDetails.data.type.filter(
                    type => type.deleted_at == null
                        && this.selects['category'].find(i => i.id === this.item.category_id).type_ids.indexOf(type.id) > -1
                ).sort((n1, n2) => {
                    const n1value = this.selects['category'].find(i => i.id === this.item.category_id).type_ids.indexOf(n1.id);
                    const n2value = this.selects['category'].find(i => i.id === this.item.category_id).type_ids.indexOf(n2.id);
                    if (n1value > n2value) {
                        return 1;
                    }

                    if (n1value < n2value) {
                        return -1;
                    }

                    return 0;
                });
                if ((this.selects['type'].find(i => i.id === this.item.type_id)) !== undefined) {
                    this.selects['subcategory'] = this.route.snapshot.data.fieldsDetails.data.subcategory.filter(
                        subcategory => subcategory.deleted_at == null
                            && this.selects['type']
                            .find(i => i.id === this.item.type_id)
                            .subcategory_ids
                            .indexOf(subcategory.id) > -1
                    ).sort((n1, n2) => {
                        const n1value = this.selects['type'].find(i => i.id === this.item.type_id).subcategory_ids.indexOf(n1.id);
                        const n2value = this.selects['type'].find(i => i.id === this.item.type_id).subcategory_ids.indexOf(n2.id);
                        if (n1value > n2value) {
                            return 1;
                        }

                        if (n1value < n2value) {
                            return -1;
                        }

                        return 0;
                    });
                } else {
                    this.selects['subcategory'] = [];
                }
            } else {
                this.selects['type'] = [];
            }

            // todo: review
            for (const key in this.attributes) {
                if (this.attributes[key]
                    && (this.attributes[key].type === 'select' || this.attributes[key].type === 'select_direct')) {
                    const temp = (this.item.specification_attributes) ? this.item.specification_attributes.find(x => x.attribute.attribute_name === key) : undefined;
                    if (typeof temp !== 'undefined') {
                        if (temp.select_id === 1) {
                            this.item[key] = temp.name;
                        } else {
                            if (temp.select != null) {
                                this.item[key] = temp.select[this.attributes[key].option_attr];
                                // this.item[key] = temp.select.name;
                            }
                        }
                        this.item[key + '_id'] = temp.select_id;

                    }
                }
            }
        });

        this.selects['year_of_manufacture'] = this.createSelect(null, null);


        // console.log('FeildDetails', this.fieldDetails)

        // this.fields.forEach(element => {
        //     console.log(this.fieldDetails[element].type)
        // });
    }

    ngOnInit() {

        // initialize the item
        this.item = {
            id: 0,
            category_id: 0,
            type_id: 0,
            subcategory_id: 0,
            specification_attributes: []
        };

        // general initialization
        this.initialize();

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + '/machines',
                isActive: false,
            },
            {
                name: 'api.specification',
                url: this.platform + '/specifications',
                isActive: false,
            },
            {
                name: 'api.add',
                url: '',
                isActive: true,
            }];
    }

    onChange(field) {
        switch (field) {
            case 'category':
                this.selects['type'] = this.route.snapshot.data.fieldsDetails.data.type.filter(
                    type => type.deleted_at == null
                        && this.selects['category'].find(i => i.id === this.item.category_id).type_ids.indexOf(type.id) > -1
                ).sort((n1, n2) => {
                    const n1value = this.selects['category'].find(i => i.id === this.item.category_id).type_ids.indexOf(n1.id);
                    const n2value = this.selects['category'].find(i => i.id === this.item.category_id).type_ids.indexOf(n2.id);
                    if (n1value > n2value) {
                        return 1;
                    }

                    if (n1value < n2value) {
                        return -1;
                    }

                    return 0;
                });
                if (this.selects['type'][0]) { this.item.type_id = this.selects['type'][0].id; }
                this.onChange('type');
                break;
            case 'type':
                this.selects['subcategory'] = this.route.snapshot.data.fieldsDetails.data.subcategory.filter(
                    subcategory => subcategory.deleted_at == null
                        && this.selects['type']
                        .find(i => i.id === this.item.type_id).subcategory_ids
                        .indexOf(subcategory.id) > -1
                ).sort((n1, n2) => {
                    const n1value = this.selects['type'].find(i => i.id === this.item.type_id).subcategory_ids.indexOf(n1.id);
                    const n2value = this.selects['type'].find(i => i.id === this.item.type_id).subcategory_ids.indexOf(n2.id);
                    if (n1value > n2value) {
                        return 1;
                    }

                    if (n1value < n2value) {
                        return -1;
                    }

                    return 0;
                });
                if (this.selects['subcategory'][0]) { this.item.subcategory_id = this.selects['subcategory'][0].id; }
                this.onChange('subcategory');
                break;
        }

    }
}
