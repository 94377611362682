import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {GeneralList} from '../../../helper-classes/general-list';

@Component({
    selector: 'app-group-listing',
    templateUrl: './group-listing.component.html',
    styleUrls: [
        './group-listing.component.scss'
    ],

    // create a separate instance of the GeneralService
    providers: [
        GeneralService
    ]
})

export class GroupListingComponent extends GeneralList implements OnInit {

    @Input() groupType: string;
    @Input() headerString: string;
    @Input() showDealer: boolean = false;

    constructor(protected generalService: GeneralService, protected route: ActivatedRoute, protected router: Router) {
        super(generalService, route, router);
    }

    ngOnInit() {
        // set the actionLink received from the routing module
        this.actionLink = this.route.snapshot.data.groupsActionLink;

        // set the API URI
        this.generalService.setUri('groups/' + this.groupType);

        // general initialization
        this.initialize();

        this.columns = [];

        if (this.showDealer) {
            this.columns.push(
                {
                    name: 'dealer',
                    formattedName: 'api.dealer',
                    key: 'name'
                }
            );
        }

        // specify the listing columns
        this.columns.push(
            {
                name: 'name',
                formattedName: 'Group',
            },
            {
                name: 'email_list',
                formattedName: 'Email addresses'
            }
        );
    }
}
