<div class="container">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
    <h3>{{title | translate}}</h3>

  <input (click)="syncAttributes()" type="submit" name="submit" title="" class="buttons marginTop5 padding5" value="{{'api.sync_attributes' | translate}}">

  <i *ngIf="loading" class="fa fa-refresh fa-spin fontSize24"></i>
    <app-table-list
            [hasHeader]="false"
            [columns]="columns"
            [rows]="rows"
            [hasActions]="true"
            [draggable]="true"
            (onAdd)="add()"
            (onEdit)="edit($event)"
            (onDelete)="delete($event)"
            (onReOrder)="reOrder($event)"
            (onRowClick)="rowClick($event)"
    ></app-table-list>
</div>
