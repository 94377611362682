<table class="table" *ngIf="bidList && isReadyToShow">
    <tbody>
    <tr *ngFor="let bid of bidList; let i=index" class="status_{{bid.status}}">
        <ng-container
                *ngIf="bid.type === BID_TYPE_SALESMAN; then salesmanBidRowBlock else traderBidRowBlock"></ng-container>
        <ng-template #salesmanBidRowBlock>
            <!-- Bidder name -->
            <td *ngIf="showAllBids || i < MIN_BIDS_TO_SHOW" [innerHtml]="bid.user?.name"></td>

            <!-- Bid shown in propper currency -->
            <td *ngIf="showAllBids || i < MIN_BIDS_TO_SHOW">
                <div *ngIf="bid.status != bidStatuses.bid_status_new.id && bid.status !== bidStatuses.bid_status_not_interested.id">
                    {{convertCurrency(bid)}}
                </div>
            </td>

            <!-- Show the currency and a tooltip with exchange rates -->
            <td *ngIf="showAllBids || i < MIN_BIDS_TO_SHOW">
                <div *ngIf="bid.status != bidStatuses.bid_status_new.id && bid.status !== bidStatuses.bid_status_not_interested.id">
                    {{currentCurrency}}
                    <span *ngIf="currentCurrency != bid.value_currency" tooltip="{{bid.value}}
                        {{bid.value_currency}}" placement="left">
                    <span class="fa fa-money"></span>
                </span>
                </div>
            </td>

            <!-- Place for bid remarks -->
            <td *ngIf="showAllBids || i < MIN_BIDS_TO_SHOW">
                <span *ngIf="bid.remarks != ''" tooltip="{{bid.remarks}}" placement="left">
                <span class="glyphicon glyphicon-info-sign"></span>
            </span>
            </td>

            <!-- <td *ngIf="( showAllBids || i < MIN_BIDS_TO_SHOW ) && bid.bid_type === 'bid'" [ngSwitch]="bid.status"> -->
                <!-- show the buttons -->
                <!-- <div *ngIf="bid.status !== bidStatuses.bid_status_new.id && bid.status !== bidStatuses.bid_status_not_interested.id">
                    <div class="floatRight">
                        <button
                                class="btn btn-sm custom-sm-btn {{(bid.status === bidStatuses.bid_status_accepted.id ? 'accepted': 'accept')}}"
                                [disabled]="bid.status !== bidStatuses.bid_status_bidded.id || anyBidsAccepted"
                                (click)="acceptBid('accept_sales_bid', bid)"
                        >
                            {{((bid.status !== bidStatuses.bid_status_bidded.id) ? 'api.accepted' : 'api.accept') |
                            translate}}
                        </button>
                        <button
                                class="btn btn-sm custom-sm-btn {{(bid.status === bidStatuses.bid_status_rejected.id ? 'rejected': 'reject')}}"
                                [disabled]="bid.status !== bidStatuses.bid_status_bidded.id"
                                (click)="rejectBid('reject_sales_bid', bid)"
                        >
                            {{((bid.status !== bidStatuses.bid_status_bidded.id) ? 'api.rejected' : 'api.reject') |
                            translate}}
                        </button>
                    </div>
                </div>
                <div *ngIf="bid.status === bidStatuses.bid_status_new.id">
                    <div class="floatRight">
                        <button
                                class="btn btn-sm custom-sm-btn extend"
                                data-toggle="modal"
                                [attr.data-target]="'#extendBidTime' + bid.id"
                        >
                            {{'api.extend_bid_time' | translate}}
                        </button>
                        <app-extend-bid-time-popup [modalId]="'extendBidTime' + bid.id" [bidId]="bid.id"
                                                   [bidTime]="bid.valid_until"
                                                   (continue)="extendBidTime($event)"></app-extend-bid-time-popup>
                    </div>

                </div> -->
            <!-- </td> -->
        </ng-template>
        <ng-template #traderBidRowBlock>
            <td *ngIf="showAllBids || i < MIN_BIDS_TO_SHOW" [innerHtml]="bid.trader?.company_name"></td>
            <td *ngIf="showAllBids || i < MIN_BIDS_TO_SHOW" [innerHtml]="convertCurrency(bid)"></td>
            <td *ngIf="showAllBids || i < MIN_BIDS_TO_SHOW">
                {{currentCurrency}}
                <span *ngIf="currentCurrency != bid.value_currency" tooltip="{{bid.value}} {{bid.value_currency}}" placement="left">
                    <span class="fa fa-money"></span>
                </span>
            </td>
            <td *ngIf="showAllBids ||i < MIN_BIDS_TO_SHOW">
                <span *ngIf="bid.remarks != ''" tooltip="{{bid.remarks}}" placement="left">
                    <span class="glyphicon glyphicon-info-sign"></span>
                </span>
            </td>

            <td *ngIf="showAllBids ||i < MIN_BIDS_TO_SHOW" [ngSwitch]="bid.status">
                <div *ngIf="bid.status !== bidStatuses.bid_status_new.id && bid.status !== bidStatuses.bid_status_not_interested.id">
                    <div class="floatRight">
                        <button class="btn btn-sm custom-sm-btn {{(bid.status === bidStatuses.bid_status_pending.id ? 'pended': 'pending')}}"
                                [disabled]="
                                            machine.incoming_status == incomingStatuses.incoming_status_trade_in_order.id
                                            || machine.incoming_status == incomingStatuses.incoming_status_stock.id
                                            || machine.incoming_status == incomingStatuses.incoming_status_demo.id
                                            || bid.status !== bidStatuses.bid_status_bidded.id
                                            || anyBidsAccepted"
                                (click)="acceptBid('pending_trader_bid', bid)"
                                >
                            {{((bid.status !== bidStatuses.bid_status_bidded.id) ? 'api.pending' : 'api.pending') |
                            translate}}
                        </button>
                        <button
                                class="btn btn-sm custom-sm-btn {{(bid.status === bidStatuses.bid_status_accepted.id ? 'accepted': 'accept')}}"
                                [disabled]="
                                            (
                                                machine.incoming_status !== incomingStatuses.incoming_status_trade_in_order.id
                                                && machine.incoming_status !== incomingStatuses.incoming_status_stock.id
                                                && machine.incoming_status !== incomingStatuses.incoming_status_demo.id
                                            )
                                            || (bid.status !== bidStatuses.bid_status_bidded.id
                                            || anyBidsAccepted
                                          )"
                                (click)="acceptBid('accept_trader_bid', bid)"
                                >
                            {{((bid.status !== bidStatuses.bid_status_bidded.id && bid.status !==
                            bidStatuses.bid_status_pending.id) ? 'api.accepted' : 'api.accept') | translate}}
                        </button>
                        <button class="btn btn-sm custom-sm-btn {{(bid.status === bidStatuses.bid_status_rejected.id ? 'rejected': 'reject')}}"
                                [disabled]="
                                            bid.status !== bidStatuses.bid_status_bidded.id
                                        "
                                (click)="rejectBid('reject_trader_bid', bid)"
                                >
                            {{((bid.status !== bidStatuses.bid_status_bidded.id && bid.status !==
                            bidStatuses.bid_status_pending.id) ? 'api.rejected' : 'api.reject') | translate}}
                        </button>
                    </div>
                </div>
        <ng-container *ngIf="!isAdmin">
                <div *ngIf="bid.status === bidStatuses.bid_status_new.id">
                    <div class="floatRight">
                        <button
                                class="btn btn-sm custom-sm-btn extend"
                                data-toggle="modal"
                                [attr.data-target]="'#extendBidTime' + bid.id"
                        >
                            {{'api.extend_bid_time' | translate}}
                        </button>
                        <app-extend-bid-time-popup [modalId]="'extendBidTime' + bid.id" [bidId]="bid.id"
                                                   [bidTime]="bid.valid_until"
                                                   (continue)="extendBidTime($event)"></app-extend-bid-time-popup>
                    </div>

                </div>
        </ng-container>
            </td>
      <div class="floatRight" *ngIf="isAdmin">
        <button class="btn btn-sm custom-sm-btn" data-toggle="modal" (click)="setSelectedBid(bid)"
                [attr.data-target]="'#changeBid'"><span class="fa fa-pencil-alt fontSize10Important"></span>
        </button>
      </div>
      <app-change-bid-popup-component [modalId]="'changeBid'"
                                      [events]="sendBidChange.asObservable()"
                                      (refreshEvent)="refreshEventCall($event)"></app-change-bid-popup-component>
        </ng-template>
    </tr>
    <tr>
        <td [attr.colspan]="5">
            <button *ngIf="bidList?.length > MIN_BIDS_TO_SHOW" (click)="showAllBidsToggle()"
                    class="btn btn-success btn-sm btn-danger custom-sm-btn">{{((!showAllBids) ? 'api.show_all' :
                'api.show_less') | translate}}
            </button>
        </td>
    </tr>
    </tbody>
</table>
