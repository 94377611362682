import {Component, OnInit, Input, Inject, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslatePipe} from '@ngx-translate/core';
import {FieldsdetailsService} from '../../services/fieldsdetails.service';
import { Subject } from "rxjs";

@Component({
    selector: 'app-machine-bids-list',
    templateUrl: './machine-bids-list.component.html',
    styleUrls: ['./machine-bids-list.component.scss'],

    // create a separate instances of the GeneralService which will use the below uri
    providers: [
        TranslatePipe
  ]
})

export class MachineBidsListComponent implements OnInit {
    readonly MIN_BIDS_TO_SHOW = 4;
    readonly BID_TYPE_SALESMAN = 1;
    readonly BID_TYPE_TRADER = 2;

    @Input() bidList: any[];
    @Input() machine: any;
    @Input() anyBidsAccepted: boolean = false;

    @Output() bidAccepted: EventEmitter<any> = new EventEmitter();
    @Output() bidRejected: EventEmitter<any> = new EventEmitter();
    @Output() timeExtended: EventEmitter<any> = new EventEmitter();
  @Output() refreshBid: EventEmitter<any> = new EventEmitter();

    showAllBids: boolean = false;
    bidStatuses: any = {};
    fixedBidStatusIds: any = [];
    sentSalesPriceStatuses: any = {};
    incomingStatuses: any = {};
    outgoingStatuses: any = {};
    currentCurrency = localStorage.currentCurrency;
    currency_exchanges: any;
    currencies: string[];
  selectedBid: any = null;
  isAdmin: boolean = false;

    isReadyToShow: boolean = false;
  sendBidChange: Subject<void> = new Subject<void>();

    constructor(
    private route: ActivatedRoute
    ) {

    const role = JSON.parse(localStorage.getItem('current_user')).roles[0];

    this.isAdmin = role === 'admin';
    }

    ngOnInit() {
        this.initConstants(this.route.snapshot.data.fieldsDetails);
    }

    protected initConstants(fields) {
        for (const status of fields.data.bid_statuses) {
            this.bidStatuses[status.name] = status;
        }

        this.fixedBidStatusIds = [
            this.bidStatuses.bid_status_accepted.id,
            this.bidStatuses.bid_status_rejected.id,
            this.bidStatuses.bid_status_pending.id
        ];

        for (const status of fields.data.sent_sales_price_statuses) {
            this.sentSalesPriceStatuses[status.name] = status;
        }

        for (const status of fields.data.machine_incoming_statuses) {
            this.incomingStatuses[status.name] = status;
        }

        for (const status of fields.data.machine_outgoing_statuses) {
            this.outgoingStatuses[status.name] = status;
        }
        this.currencies = fields.data.currency;

        // set currency_exchanges received from the FieldsDetailsResolver
        this.currency_exchanges = fields.data.currency_exchange;

        this.isReadyToShow = true;
    }

    public showAllBidsToggle() {
        this.showAllBids = !this.showAllBids;
        return this.showAllBids;
    }

    // convert a price to user's currency
    public convertCurrency(bid) {
        if (this.fixedBidStatusIds.indexOf(bid.status) !== -1) {
            return bid.fixed_values.find(x => x.value_currency === localStorage.currentCurrency).value;
        }
        const fromCurrency = this.currency_exchanges.find(x => x.currency === bid.value_currency);
        const toCurrency = this.currency_exchanges.find(x => x.currency === this.currentCurrency);
        if (typeof toCurrency === 'undefined' || typeof fromCurrency === 'undefined') {
            return bid.value;
        }
        return Math.round(bid.value * toCurrency.value / fromCurrency.value * 100) / 100;
    }

    acceptBid(emailTemplate, bid) {
        this.bidAccepted.emit({
            emailTemplate: emailTemplate,
      bidId: bid.id
        });
    }

    rejectBid(emailTemplate, bid) {
        this.bidRejected.emit({
            emailTemplate: emailTemplate,
      bidId: bid.id
        });
    }

    extendBidTime($event) {
        this.timeExtended.emit($event);
    }

  setSelectedBid(bid: any) {
    this.selectedBid = bid;
    this.sendBidChange.next(this.selectedBid);
  }

  refreshEventCall($event) {
    this.refreshBid.emit($event);
  }

}
