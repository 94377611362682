import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { GeneralService } from '../services/general.service';
import { GeneralDetailEvent } from '../models/event';
import { GeneralDetailEventService } from '../services/general-detail-event.service';

@Component({
  selector: 'app-change-bid-popup-component',
  templateUrl: './change-bid-popup-component.component.html',
  styleUrls: ['./change-bid-popup-component.component.scss'],
  providers: [
    TranslatePipe
  ]
})
export class ChangeBidPopupComponentComponent implements OnInit, OnDestroy {
  @Input() modalId: string;
  @Input() events: Observable<void>;
  @Output() refreshEvent = new EventEmitter<any>();
  selectedBid: any = null;
  currentCurrency = localStorage.currentCurrency;
  private eventsSubscription: Subscription;

  constructor(@Inject('instance1') private machineBidService: GeneralService,
              protected generalDetailHandler: GeneralDetailEventService
  ) {
  }

  save() {
    this.machineBidService.setUri('machine_bids');
    this.machineBidService.changeBid(this.selectedBid.id, this.selectedBid).subscribe(
      (data) => {
        this.refreshEvent.emit(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setBid(data) {
    this.selectedBid = JSON.parse(JSON.stringify(data));
  }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe((data) => this.setBid(data));
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

}
