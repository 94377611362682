import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

    breadcrumbs: any[];
    public myrouterLink: string = '/internal-platform';
    public divisionLink: string;
    public has_admin: boolean;
    public enablePlatformSelect: boolean;
    public platforms: any;
    public platfrom_keys: string[];

    constructor(
        private router: Router,
        private authService: AuthService,
    ) {
        if (!environment.enablePlatformSelect) {
            if (localStorage.platform) {
                this.router.navigate(['/' + localStorage.platform]);
            } else {
                this.router.navigate(['/login']);
            }
        }
    }

    ngOnInit() {
        this.has_admin = environment.has_admin;
        this.enablePlatformSelect = environment.enablePlatformSelect;
        this.platforms = environment.platforms;
        if (this.platforms) {
            this.platfrom_keys = Object.keys(this.platforms);
            this.divisionLink = this.platforms[this.platfrom_keys[0]];
        }
        this.breadcrumbs = [
            {
                name: 'api.royal_reesink_remarketing_platform',
                url: '',
                // isActive: true,
            }];
    }

    navigate() {
        if (!localStorage.division || localStorage.division !== this.divisionLink) {
            localStorage.clear();
        }
        localStorage.division = this.divisionLink;
        window.location.href = this.divisionLink;
    }

}
