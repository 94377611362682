<div class="container-fluid grayBg">
    <div class="container login-border">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-8 col-sm-offset-2">
                    <div [innerHTML]="'api.privacy_policy_text' | translate"></div>
                    <p class="right"><input class="back buttons" title="Back" type="button" value="Back" onclick="window.history.back();"></p>
                </div>
            </div>
        </div>
    </div>
</div>
