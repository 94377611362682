<div>
  <h5
    style="font-size: 13px;"
  >{{title}}</h5>
  <table class="table">
    <thead>
      <tr>
        <th>{{'api.date' | translate}}</th>
        <th>{{ 'api.price' | translate}} </th>
        <th>{{ 'api.currency' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let price of pricings" >
        <td *ngIf="price.value && price.value!== 0 && price.value_currency === currentCurrency && price.created_at">{{price.created_at | date }}</td>
        <td *ngIf="price.value && price.value!== 0 && price.value_currency === currentCurrency">{{price.value}}</td>
        <td *ngIf="price.value && price.value!== 0 && price.value_currency === currentCurrency">{{price.value_currency}}</td>
      </tr>
      <tr *ngIf="pricings.length === 0">
      <td style="text-align: center;" colspan="3">
        {{'api.no_data' | translate}}
      </td>
      </tr>
    </tbody>
  </table>
  <!-- date , value value_currency  -->
</div>
