import { Component, Directive, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import {Type} from '../../../models/type';
import {Subcategory} from '../../../models/subcategory';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralDetail} from '../../../helper-classes/general-detail';
import {GeneralService} from '../../../services/general.service';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {GeneralDetailEvent} from '../../../models/event';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';

@Directive()
export abstract class BaseTypeDetailComponent extends GeneralDetail implements OnInit {

    item: Type;
    @ViewChild('fileInput', { static: false }) fileInput;
    subcategories: Subcategory[];
    section: string = '';

    protected constructor(
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected subcategoryService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
        super(generalService, router, route, errorEventService, loadingEventService, renderer, generalDetailHandler);

        // afterOnSubmit
        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterOnSubmit).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );
    }

    ngOnInit() {

        // initialize the item
        this.item = {
            name: '',
            id: 0,
            subcategories: [],
            subcategory_ids: [],
        };

        // general initialization
        this.initialize();

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.types',
                url: this.platform + (this.section ? '/' + this.section : '') + '/types',
                isActive: false,
            },
            {
                name: 'api.add',
                url: '',
                isActive: true,
            }];

        // set subcategories received from the FieldsdetailsResolver
        this.subcategories = this.route.snapshot.data.fieldsDetails.data.subcategory.filter(subcategory => subcategory.deleted_at == null);
    }

    /**
     * Override the GeneralDetail getItemDetail function including the subcategories update from the response
     */
    getItemDetail() {
        this.generalService.getItem(this.id).subscribe(
            data => {
                this.item = this.processItemResponse(data);
                this.subcategories = this.subcategories.filter(item => !this.item.subcategories.some(x => x.id === item.id));
                this.breadcrumbs[this.breadcrumbs.length - 1].name = 'api.edit';
            },
            (error) => {
                console.log(error);
            }
        );
    }
}
