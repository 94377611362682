export class Salesman {
    id?: number = 0;
    company_id?: number;
    name?: string;
    email?: string;
    mobile?: string;
    function?: string;
    role?: string;
    currency?: string;
    dealer_id?: number;
    receive_bid_insertion_email?: boolean;
    user_type?: string;

    constructor() {
    }
}
