<div class="container" *ngIf="this.currency_exchanges">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
    <div class="row">
        <div class="col-sm-6">
            <h3 *ngIf="!editable" class="sales-heading">{{'api.currency' | translate }}</h3>
            <h3 *ngIf="editable" class="sales-heading">{{'api.modify_currency' | translate }}</h3>

        </div>
    </div>
    <div class="row">

        <form>
            <div class="col-sm-6">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>{{'api.currency_type' | translate}}</th>
                            <th>{{'api.exchange_rate' | translate}}</th>
                            <th>{{'api.date' | translate}}</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr *ngFor="let item of currency_exchanges; let index = index;">
                            <td>
                                {{item.name}}
                                <span *ngIf="item.currency == 'EUR'" class="fontSize10Important">(Basic Currency)</span>
                            </td>
                            <td>
                                <input type="text" class="form-control" placeholder="{{ 'api.value' | translate }}" [disabled]="!editable || item.currency == 'EUR'" [(ngModel)]="currency_exchanges[index].value" [ngModelOptions]="{standalone: true}"></td>
                            <td *ngIf="item.currency !== 'EUR'"> {{item.updated_at | date:'dd/MM/yyyy'}}</td>

                        </tr>
                        </tbody>


                        <tfoot>
                        <tr>
                            <td>
                                <input *ngIf="!editable" type="submit" title="" class="login-name buttons marginTop5" id="confirm" value="{{ 'api.modify' | translate }}" (click)="changeEditable()">
                                <input *ngIf="editable" type="submit" title="" class="login-name buttons marginTop5" id="confirm2" value="{{ 'api.confirm' | translate }}" (click)="saveCurrencies()">
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        </tfoot>

                    </table>
                </div>
            </div>

            <div class="col-sm-6"></div>


        </form>


    </div>
</div>