import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorEventService } from '../../../shared/services/error-event.service';
import { LoadingEventService } from '../../../shared/services/loading-event.service';
import { GeneralService } from '../../../shared/services/general.service';
import { AuthService } from '../../../shared/services/auth.service';
import { TranslatePipe } from '@ngx-translate/core';
import { MachineListingService } from '../../../shared/services/machineListing.service';
import {
  CompareBaseMachineDetailComponent
} from 'app/shared/components/abstract-classes/machines/compare/compare-machine-detail.component';
import { ComparisionSetupService } from 'app/shared/services/comparison-setup.service';

@Component({
  selector: 'app-compare-machine-detail-admin',
  templateUrl: '../../../shared/components/abstract-classes/machines/compare/compare-machine-detail.component.html',
  styleUrls: [
    '../../../shared/components/abstract-classes/machines/compare/compare-machine-detail.component.scss'
  ],
  providers: [
    { provide: 'instance1', useClass: GeneralService },
    { provide: 'instance2', useClass: GeneralService },
    { provide: 'instance3', useClass: GeneralService },
    { provide: 'uri', useValue: 'machines' },
    TranslatePipe
  ],
})
export class CompareMachineDetailAdminComponent extends CompareBaseMachineDetailComponent implements OnInit {
  section: string = '';

  selectedMachineIds: number[] = [];


  constructor(
    protected errorEventService: ErrorEventService,
    protected loadingEventService: LoadingEventService,
    @Inject('instance1') protected generalService: GeneralService,
    @Inject('instance2') protected statusService: GeneralService,
    @Inject('instance3') protected machineService: GeneralService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected renderer: Renderer2,
    protected translatePipe: TranslatePipe,
    protected machineListingService: MachineListingService,
    protected authService: AuthService,
    protected comparisonSetupService: ComparisionSetupService
  ) {
    // Call parent constructor (super) with the necessary parameters
    super(route, generalService, translatePipe, machineListingService, comparisonSetupService);
  }

  ngOnInit() {
    super.ngOnInit(); // Call the parent ngOnInit to handle the logic from the base class
    this.url = '/admin-platform/machines/';
  }

  toogleActive(id: any, event): void {
    //  Add the logic to toggle the display property of the machine
    let newmachies = this.machines.map((machine) => {
      if (machine.id === id) {
        return {
          ...machine,
          display: event.target.checked,
        };
      }
      return machine;
    }).sort((a, b) => {
        if (a.display && !b.display) {
          return -1;
        }
        if (!a.display && b.display) {
          return 1;
        }
        return 0;
      }
    );
    this.machines = newmachies;
  }

  onCompareChange(compareArray: number[]) {
    this.onCompareChange(compareArray);
  }

}
