<div class="container">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
    <h3>{{ 'api.company_list' | translate }}</h3>
    <app-table-list
            [hasHeader]="false"
            [columns]="columns"
            [rows]="rows"
            [hasActions]="true"
            (onAdd)="add()"
            (onEdit)="edit($event)"
            (onDelete)="delete($event)">
    </app-table-list>
</div>
