import {Component, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import { Category } from '../../../models/category';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoryService} from '../../../services/category.service';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralService} from '../../../services/general.service';

import {DragulaService} from 'ng2-dragula';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {BaseCategoryDetailComponent} from '../../abstract-classes/categories/category-detail.component';

@Component({
    selector: 'app-category-detail',
    templateUrl: '../../abstract-classes/categories/category-detail.component.html',
    styleUrls: [
        '../../abstract-classes/categories/category-detail.component.scss'
    ],

    // create a separate instances of the GeneralService and CategoryService(which extends the GeneralService) which will use the below uri
    providers: [
        {provide: 'instance1', useClass: CategoryService },
        {provide: 'instance2', useClass: GeneralService },
        {provide: 'uri',  useValue: 'categories'},
    ],
})

export class CategoryDetailComponent extends BaseCategoryDetailComponent implements OnInit {

    section: string = '';

    constructor(
        protected dragulaService: DragulaService,
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        @Inject('instance1') protected categoryService: CategoryService,
        @Inject('instance2') protected typeService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService) {
        super(
            dragulaService,
            errorEventService,
            loadingEventService,
            categoryService,
            typeService,
            route,
            router,
            renderer,
            generalDetailHandler,
            fieldsdetailsService
        );
    }
}
