import { Directive, ElementRef, Renderer2, ViewChild } from '@angular/core';
import {ErrorEventService} from '../services/error-event.service';
import {ErrorHandlerEvent} from '../models/event';

@Directive()
export class Errorhandler {

    @ViewChild('form', { static: true }) form: ElementRef;
    constructor (protected errorEventService: ErrorEventService, protected renderer: Renderer2) {}

    initialize() {
        // subscribe to applyError event
        this.errorEventService.subscribeToEvent(ErrorHandlerEvent.applyErrors).subscribe((error) => {
            status = error.status;
            //error = error.error;
            if (this.form && typeof error !== "undefined" && typeof error.error !== "undefined" && typeof error.error.error !== "undefined" && typeof error.error.error.errors !== "undefined") {
                //const error_array = JSON.parse(error)['error'];
                const error_items = error.error.error.errors;

                // add has_error class to form
                this.renderer.addClass(this.form.nativeElement, 'has_error');

                // remove all old errors
                const error_elements = this.form.nativeElement.querySelectorAll('.error');
                let parent;
                for (let i = 0; i < error_elements.length; i++) {
                    // remove has_error class from form
                    this.renderer.removeClass(error_elements[i], 'error');
                    // remove error texts
                    parent = this.renderer.parentNode(error_elements[i]);
                    const error_texts = this.form.nativeElement.querySelectorAll('.error_text');
                    for (let j = 0; j < error_texts.length; j++) {
                        this.renderer.removeChild(parent, error_texts[j]);
                    }
                }

                // add new errors
                let input;
                let text;
                if (error_items) {
                    for (const error_item_key of Object.keys(error_items)) {
                        // find the input and add error class
                        input = this.form.nativeElement.querySelector('[name="' + error_item_key + '"]');
                        if (input) {
                            this.renderer.addClass(input, 'error');


                            // add error texts
                            parent = this.renderer.parentNode(input);
                            const div = this.renderer.createElement('div');
                            this.renderer.addClass(div, 'error_text');
                            for (const error_text_key of Object.keys(error_items[error_item_key])) {
                                text = this.renderer.createText(error_items[error_item_key][error_text_key]);
                                this.renderer.appendChild(div, text);
                            }
                            this.renderer.appendChild(parent, div);
                        }
                    }
                }
            }
        });

        // subscribe to removeErrors event
        this.errorEventService.subscribeToEvent(ErrorHandlerEvent.removeErrors).subscribe(() => {
            if (this.form) {
                // remove has_error class from form
                this.renderer.removeClass(this.form.nativeElement, 'has_error');

                // remove all input error class
                const error_elements = this.form.nativeElement.querySelectorAll('.error');
                let parent;
                for (let i = 0; i < error_elements.length; i++) {
                    this.renderer.removeClass(error_elements[i], 'error');

                    // remove error texts
                    parent = this.renderer.parentNode(error_elements[i]);
                    const error_texts = this.form.nativeElement.querySelectorAll('.error_text');
                    for (let j = 0; j < error_texts.length; j++) {
                        this.renderer.removeChild(parent, error_texts[j]);
                    }
                }
            }
        });
    }
}
