import {Injectable} from '@angular/core';

import {GeneralService} from './general.service';

@Injectable({
    providedIn: 'root'
})
export class TraderService extends GeneralService {

    // set the expert_in to be able to send to the API
    save(trader) {
        trader.expert_in = trader.expert_in.map((item) => {
            if (item.value) {
                return item.value;
            } else {
                return item;
            }
        });
        return super.save(trader);
    }

}

