import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {BreadcrumbItem} from '../../../models/breadcrumb-item';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import * as moment from 'moment';

@Component({
    selector: 'app-currencies',
    templateUrl: './currencies.component.html',
    styleUrls: [
        './currencies.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        GeneralService,
        {provide: 'uri',  useValue: 'currencies'},
    ],
})

export class CurrenciesComponent implements OnInit {

    editable: boolean = false;
    currency_exchanges: any;
    breadcrumbs: BreadcrumbItem[];
    platform: string = '';

    constructor(protected generalService: GeneralService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected fieldsdetailsService: FieldsdetailsService) {
    }

    ngOnInit() {

        this.platform = this.router.url.split('/')[1];

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + '/machines',
                isActive: false,
            },
            {
                name: 'api.currencies',
                url: '',
                isActive: true,
            }];

        // set currency_exchanges received from the FieldsdetailsResolver
        this.currency_exchanges = this.route.snapshot.data.fieldsDetails.data.currency_exchange.map(function (item) {
                const tmp = item;
                tmp.updated_at = moment(tmp.updated_at).format('YYYY-MM-DD').replace(/-/g, '/');
                return tmp;
            }
        );
    }

    /**
     * Toggle the editable attribute
     */
    changeEditable() {
        this.editable = !this.editable;
    }

    /**
     * Send Currencies to API for saving
     */
    saveCurrencies() {
        const item = {
            currencies: this.currency_exchanges,
        };
        this.generalService.save(item).subscribe(
            data => {
                this.currency_exchanges = data.data;
                this.currency_exchanges.map(function (currency_exchange) {
                        const tmp = currency_exchange;
                        tmp.updated_at = tmp.updated_at.replace(/-/g, '/');
                        return tmp;
                    }
                );
                this.fieldsdetailsService.getAllFieldsDetails(true);
                this.editable = !this.editable;
            }
        );
    }
}
