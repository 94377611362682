import { Pipe, PipeTransform } from '@angular/core';
import {
  CurrencyPipe, DatePipe, DecimalPipe, JsonPipe, LowerCasePipe, PercentPipe, SlicePipe, TitleCasePipe,
  UpperCasePipe
} from '@angular/common';
import { ImagePipe } from './image.pipe';
import { TranslatePipe } from '@ngx-translate/core';
import { GeneralFields } from '../helper-classes/general-fields';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Pipe({
  name: 'multipipe'
})

/**
 * Custom pipe what is used at table-list to be able to use "dynamic" pipes
 */
export class MultiPipe implements PipeTransform {
  lang: string = localStorage.current_locale;
  currency_exchanges: any;

  constructor(public translatePipe: TranslatePipe,
              protected route: ActivatedRoute
  ) {
    if (localStorage.getItem('currency_exchanges') !== null) {
      this.currency_exchanges = JSON.parse(localStorage.getItem('currency_exchanges'));
    }
  }

  transform(value: any, pipestring: string): string {
    let return_value = value;

    if (pipestring !== undefined) {
      // explode pipestring to receive the necessary info
      const pipes = pipestring.trim().split('|');

      for (const pipe of pipes) {
        const splitted = pipe.trim().split(':');
        const pipeType = splitted[0].trim();
        const allParameters = pipe.trim().substring(pipe.indexOf(':') + 1);

        switch (pipeType) {
          // system pipes
          case 'boolean': {
            return_value = (value > 0) ? this.translatePipe.transform('api.true') : this.translatePipe.transform('api.false');
            break;
          }
          case 'translate': {
            return_value = this.translatePipe.transform('api.' + value);
            break;
          }
          case 'lowercase': {
            return_value = new LowerCasePipe().transform(value);
            break;
          }
          case 'titlecase': {
            return_value = new TitleCasePipe().transform(value);
            break;
          }
          case 'uppercase': {
            return_value = new UpperCasePipe().transform(value);
            break;
          }
          case 'decimal': {
            return_value = new DecimalPipe(this.lang).transform(
              value,
              (typeof splitted[1] !== 'undefined') ? splitted[1].trim() : null
            );
            break;
          }
          case 'percent': {
            return_value = new PercentPipe(this.lang).transform(
              value,
              (typeof splitted[1] !== 'undefined') ? splitted[1].trim() : null
            );
            break;
          }
          case 'currency': {
            if (typeof splitted[1] === 'undefined' || splitted[1] === '' || value === 0 || value === null) {
              return_value = '';
            } else {
              return_value = new CurrencyPipe(this.lang).transform(
                this.convertCurrency(
                  return_value,
                  (typeof splitted[1] !== 'undefined') ? splitted[1].trim() : localStorage.currentCurrency
                ),
                localStorage.currentCurrency,
                (typeof splitted[2] !== 'undefined' && splitted[2].trim() === 'symbol') ? 'symbol' : 'code',
                (typeof splitted[3] !== 'undefined') ? splitted[3].trim() : null
              );
            }
            break;
          }
          case 'date': {
            if (value === '0000-00-00' || value === '0000-00-00 00:00:00') {
              return_value = '';
            } else {
              value = moment(new Date(value)).format('YYYY-MM-DD');
              return_value = new DatePipe(this.lang).transform(
                (value) ? value.replace(/-/g, '/') : null,
                (typeof splitted[1] !== 'undefined') ? splitted[1].trim() : null
              );
            }
            break;
          }
          case 'year': {
            if (value === '0000-00-00' || value === '0000-00-00 00:00:00') {
              return_value = '';
            } else {
              return moment(new Date(value)).format('YYYY-MM-DD');
            }
            break;
          }
          case 'json': {
            return_value = new JsonPipe().transform(
              value
            );
            break;
          }
          case 'slice': {
            return_value = new SlicePipe().transform(
              value,
              (typeof splitted[1] !== 'undefined') ? Number(splitted[1].trim()) : null,
              (typeof splitted[2] !== 'undefined') ? Number(splitted[2].trim()) : null
            );
            break;
          }

          // custom status pipe
          case 'customStatus': {
            const statuses = JSON.parse(allParameters);
            const status = statuses.find((item) => item.id === value);
            if (typeof status !== 'undefined') {
              return_value = this.translatePipe.transform('api.' + status.name);
            }
            break;
          }

          // custom image pipe
          case 'image': {
            return_value = new ImagePipe().transform(
              value,
              (typeof splitted[1] !== 'undefined') ? Number(splitted[1].trim()) : null
            );
            break;
          }
          case 'attribute_type':
            const attribute_type = GeneralFields.ATTRIBUTE_TYPES[value];
            if (typeof attribute_type !== 'undefined') {
              return_value = this.translatePipe.transform(attribute_type.name);
            }
            break;
          default: {
            break;
          }
        }
      }
    }
    return return_value;
  }

  // convert a price to user's currency
  public convertCurrency(value, currency) {
    const fromCurrency = this.currency_exchanges.find(x => x.currency === currency);
    const toCurrency = this.currency_exchanges.find(x => x.currency === localStorage.currentCurrency);
    if (typeof toCurrency === 'undefined' || typeof fromCurrency === 'undefined') {
      return value;
    }
    return Math.round(value * toCurrency.value / fromCurrency.value * 100) / 100;
  }
}
