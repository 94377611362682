import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs/Observable';


@Injectable({
    providedIn: 'root'
})
/**
 * This class ensures that the user is logged in
 * It is used by those components that needs to check if user is logged in
 */
export class LoginActivate implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.getIsLoggedIn()) {
            this.router.navigate(['/login']);
        }
        return true;
    }
}
