import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GeneralList } from '../../../helper-classes/general-list';
import { GeneralDetailEventService } from '../../../services/general-detail-event.service';
import { GeneralDetailEvent } from '../../../models/event';
import { FieldsdetailsService } from '../../../services/fieldsdetails.service';
import { Specification } from '../../../../internal-platform-module/models/specification';
import { MachineListingService } from '../../../services/machineListing.service';

@Component({
    selector: 'app-specifications',
    templateUrl: './specifications.component.html',
    styleUrls: [
        './specifications.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        { provide: 'instance1', useClass: GeneralService },
        { provide: 'instance2', useClass: GeneralService },
        { provide: 'instance3', useClass: GeneralService },

        { provide: 'uri', useValue: 'specifications' },
    ],
})

export class SpecificationsComponent extends GeneralList implements OnInit {
    title: string = 'api.specifications';
    rows: Specification[];
    sortBy = [
        {
            value: 'created_at_desc',
            name: 'api.created_desc'
        },
        {
            value: 'created_at_asc',
            name: 'api.created_asc'
        },
        {
            value: 'id_asc',
            name: 'api.id_asc'
        },
        {
            value: 'id_desc',
            name: 'api.id_desc'
        },
    ];

    listType: string = 'SalesmanBidOverviewList';
    xmlColumnExists: boolean = false;

    constructor(@Inject('instance1') protected generalService: GeneralService,
        @Inject('instance2') private exportService: GeneralService,
        @Inject('instance3') private exportXMLService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService,
        protected machineListingService: MachineListingService) {
        super(generalService, route, router, generalDetailHandler);

        this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterRefreshAllItem).subscribe(() => {
            const select_attributes = [];

            for (const column of this.columns) {
                const attribute = this.route.snapshot.data.fieldsDetails.data.attributes.find(x => x.attribute_name == column.name);
                // SELECT or SELECT_WITH_OTHER
                if (attribute && (attribute.type == 3 || attribute.type == 2)) {
                    select_attributes.push(attribute);
                }
            }

            // todo: review
            for (const specification of this.rows) {
                for (const attribute of select_attributes) {
                    const specification_attribute = specification.specification_attributes.find(
                        x => x.attribute.attribute_name === attribute.attribute_name
                    );
                    if (specification_attribute) {
                        if (specification_attribute.select_id === 1) {
                            specification[attribute.attribute_name] = specification_attribute.name;
                        } else {
                            if (specification_attribute.select != null) {
                                specification[attribute.attribute_name] = (attribute.translatable == 1) ? specification_attribute.select.translated : specification_attribute.select.name;
                                //specification[attribute] = specification_attribute.select.name;
                            }
                        }
                    }
                }
            }
        });
    }

    ngOnInit() {
        // specify the listing columns
        this.columns = this.route.snapshot.data.internalSpecificationColumns.data;
        this.xmlColumnExists = typeof this.route.snapshot.data.availableColumns.data.xml !== 'undefined';

        // general initialization
        this.initialize(true);
        this.refresh();

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + '/machines',
                isActive: false,
            },
            {
                name: 'api.specifications',
                url: '',
                isActive: true,
            }];
    }

    onSearchChange(searchObject) {
        const searchText = searchObject.searchText.toLowerCase();
        for (const attribute of this.columns.filter((x) => x.searchable === 2)) {
            if (!this.route.snapshot.data.fieldsDetails.data[attribute.name]) {
                continue;
            }
            this.searchTerm['filtered_' + attribute.name + 's'] =
                this.route.snapshot.data.fieldsDetails.data[attribute.name].filter((item) => {
                    return item.name.toLowerCase().includes(searchText);
                }).map(item => item.id);
        }
        this.searchTerm.filtered_subcategories = this.route.snapshot.data.fieldsDetails.data.subcategory.filter((item) => {
            return item.translated_name.toLowerCase().includes(searchText);
        }).map(item => item.id);

        this.searchTerm.string = searchText;
        this.searchTerm.status_filter = searchObject.status_filter;
        this.searchTerm.table_filter = searchObject.tableFilter;
        this.machineListingService.setSearchTerm(this.searchTerm, this.listType);
        this.refresh();

    }

    delete(item) {
        this.generalService.delete(item.id).subscribe(
            () => {
                this.refresh();

                // emit the afterDelete event if required
                if (typeof this.generalDetailHandler !== 'undefined') {
                    this.generalDetailHandler.emitEvent(GeneralDetailEvent.afterDelete);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    export(times: any) {
        const item = {
            from_time: times.fromTime,
            until_time: times.untilTime,
        };

        // accept the bid (to API)
        this.exportService.setUri('generateExcel');
        this.exportService.save(item).subscribe(
            data => {
                const response = data.data;
            }
        );
    }

    exportXML() {
        this.exportXMLService.setUri('generateXML');
        this.exportXMLService.save({}).subscribe(
            data => {
                const response = data.data;
            }
        );
    }
}
