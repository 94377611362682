import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TableColumn} from '../models/table-column';
import {BreadcrumbItem} from '../models/breadcrumb-item';
import {GeneralFields} from './general-fields';
import {GeneralDetailEvent} from '../models/event';
import {GeneralDetailEventService} from '../services/general-detail-event.service';
import { Directive, OnDestroy } from '@angular/core';

@Directive()
export class GeneralList implements OnDestroy {
    columns: TableColumn[];
    rows: any[];
    actionLink: string = '';
    breadcrumbs: BreadcrumbItem[];
    platform: string = '';
    currentPage: number = 1;
    itemPerPages: number = 25;
    searchTerm = {
        filtered_categories: [],
        filtered_types: [],
        filtered_brands: [],
        filtered_subcategories: [],
        filtered_statuses: [],
        filtered_incoming_statuses: [],
        filtered_outgoing_statuses: [],
        filtered_owners: [],
        string: [],
        status_filter: [],
        table_filter: ''
    };

    currentSortBy = 'id_desc';
    sortBy = GeneralFields.INTERNAL_MACHINES_SORTBY;
    numberOfPages: number;

    protected subscriptions: any[] = [];

    constructor(protected generalService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected generalDetailHandler?: GeneralDetailEventService) {
    }

    initialize(withOutRefreshAllItem?: boolean, stopRefresh = true) {
        // set the platform from url
        this.platform = this.router.url.split('/')[1];

        // if not implicit modified get the actionLink from routes data
        if (!this.actionLink) {
            this.actionLink = this.route.snapshot.data.actionLink;
        }

        if (stopRefresh) {
        // it used in case if pagination used to not get all items
        if (withOutRefreshAllItem !== true) {
            this.refreshAllItem();
         }
        }

        this.loadPersistableItems();
        if (typeof this.generalDetailHandler !== 'undefined') {
            this.generalDetailHandler.emitEvent(GeneralDetailEvent.afterListingInitialized);
        }
    }

    /**
     * Get all items
     */
    refreshAllItem() {
        this.generalService.getAll().subscribe(
            data => {
              if (data.data.data) {
                this.rows = data.data.data;
              } else
                if (data.data) {
                this.rows = data.data;
              } else {
                this.rows = data;
              }

              // emit the afterRefreshAllItem event if required
                if (typeof this.generalDetailHandler !== 'undefined') {
                    this.generalDetailHandler.emitEvent(GeneralDetailEvent.afterRefreshAllItem);
                }

                const tree = this.router.parseUrl(this.router.url);
                if (tree.fragment) {
                    const element = document.querySelector('#' + tree.fragment);
                    if (element) { element.scrollIntoView(); }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * Navigate to add page
     */
    add() {
        this.router.navigate([this.actionLink + '/add']);
    }

    /**
     * Navigate to edit page
     *
     * @param item
     */
    edit(item) {
        this.router.navigate([this.actionLink + '/' + item.id]);

    }

    /**
     * Delete an item
     *
     * @param item
     */
    delete(item) {
        this.generalService.delete(item.id).subscribe(
            () => {
                this.refreshAllItem();

                // emit the afterDelete event if required
                if (typeof this.generalDetailHandler !== 'undefined') {
                    this.generalDetailHandler.emitEvent(GeneralDetailEvent.afterDelete);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * Reorder specified items
     */
    reOrder(rows) {
        const items = {order: rows.map(item => item.id)};
        this.generalService.reOrder(items).subscribe(
            () => {
                this.refreshAllItem();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * Reset items
     */
    reReset() {
        this.refreshAllItem();
    }

    /**
     * Refresh the page after the currentPage was changed
     *
     * @param page
     */
    onPageChange(page) {
        this.currentPage = page;
        this.refresh();
    }

    /**
     * Refresh the page after the itemPerPage was changed
     *
     * @param itemPerPage
     */
    onItemPerPageChange(itemPerPage) {
        this.itemPerPages = itemPerPage;
        this.refresh();
    }

    /**
     * Refresh the page after the sortBy was changed
     *
     * @param sortBy
     */
    onSortByChange(sortBy) {
        this.currentSortBy = sortBy;
        this.refresh();
    }

    /**
     * Refresh the page after the searchText was changed
     *
     * @param searchText
     */
    onSearchChange(searchText) {
        this.searchTerm.string = searchText.searchText;
        this.refresh();
    }

    /**
     * Go to the detail page
     *
     * @param item
     */
    rowClickAction(item) {
        this.router.navigate([this.actionLink + '/' + item.id]);
    }

    /**
     * Get the current list for the specified page
     */
    refresh() {

        this.generalService.paginator(this.currentPage, this.itemPerPages, this.searchTerm, this.currentSortBy).subscribe(
            data => {
                const response = data.data;
                this.rows = response.data;
                if (response.last_page < this.currentPage) {
                    this.currentPage = 1;
                    this.refresh();
                } else {
                    this.currentPage = response.current_page;
                    this.numberOfPages = response.last_page;
                    // emit the afterRefreshAllItem event if required
                    if (typeof this.generalDetailHandler !== 'undefined') {
                        this.generalDetailHandler.emitEvent(GeneralDetailEvent.afterRefreshAllItem);
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; ++i) {
            this.subscriptions[i].unsubscribe();
        }
    }

    loadPersistableItems() {
        // Global
        const storagePageItems = localStorage.getItem('itemPerPage');
        if (storagePageItems) {
            this.itemPerPages = parseInt(storagePageItems, 10);
        }

        // Link based
        const current_link = localStorage.getItem('listing.currentRouteName') ?
          localStorage.getItem('listing.currentRouteName') : this.router.url;
        if (current_link !== this.router.url) {
            localStorage.removeItem('listing.page');
            localStorage.removeItem('listing.sortBy');
        }
        localStorage.setItem('listing.currentRouteName', this.router.url);

        this.currentPage = localStorage.getItem('listing.page') ? parseInt(localStorage.getItem('listing.page')) : this.currentPage;
        this.sortBy = localStorage.getItem('listing.sortBy') ? JSON.parse(localStorage.getItem('listing.sortBy')) : this.sortBy;

        if (typeof this.generalDetailHandler !== 'undefined') {
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.clearLastListingURL).subscribe(() => {
                localStorage.removeItem('listing.currentRouteName');
            });
        }
    }
}
