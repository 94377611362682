import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-compare-prices-table-machine-detail',
  templateUrl: './compare-prices-table-machine-detail.component.html',
  styleUrls: ['./compare-prices--machine-detail.component.scss']
})
export class ComparePricesTableMachineDetailComponent {
  @Input() machineDetails: any;
  @Input() title = '';
  @Input() key = '';
  currentCurrency = localStorage.currentCurrency;
  pricings = [];
  constructor() { }

  ngOnInit(): void {
    // // Initialization logic here
    // if (this.machineDetails) {
    //   this.pricings = JSON.parse(this.machineDetails[this.key]);
    // }
  }
  // on data received
  ngOnChanges(): void {
    // Logic to handle data changes
    try {
      const parsedDetails = JSON.parse(this.machineDetails);
      if (parsedDetails && parsedDetails.length) {
        this.pricings = parsedDetails;
      }
    } catch (error) {
      this.pricings = [];
    }
  }
}
