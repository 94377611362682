import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventService<T> {

    private events: any = {}; // object containing event as { T : Subject<any> }

    /**
     * Emit the type event and use parameters if specified
     *
     * @param {T} type
     * @param parameters
     */
    emitEvent(type: T, ...parameters) {

        const actions = this.events[type];

        if (actions) {
            actions.forEach(action => {
                action.next(...parameters);
            });
        }
    }

    /**
     * Subcribe to type event
     *
     * @param {T} type
     * @returns {Observable<any>}
     */
    subscribeToEvent(type: T): Observable<any> {

        if (!this.events[type]) {
            this.events[type] = [];
        }

        const subject = new Subject<any>();
        this.events[type].push(subject);

        return subject.asObservable();
    }

    // not used yet
    emitEventWithCallback(type: T, subject: any, parameters?: any) {

        const actions = this.events[type];

        if (actions) {
            actions.forEach(action => {
                action.next([subject, parameters]);
            });
        }
    }
}
