import { Directive, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {SelectAttributeOption} from '../../../models/select-attribute-option';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralDetail} from '../../../helper-classes/general-detail';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {GeneralDetailEvent} from '../../../models/event';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';
import {environment} from '../../../../../environments/environment';
import {TransApiService} from '../../../services/trans-api.service';

@Directive()
export abstract class BaseSelectAttributeOptionDetailComponent extends GeneralDetail implements OnInit, OnDestroy {

    item: SelectAttributeOption;
    title: string = 'api.attribute_option';
    section: string = '';
    public machine_links = environment.toro_logo;
    locales: any;
    private sub: any;

    protected constructor(
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService,
        protected transApiService: TransApiService) {
        super(generalService, router, route, errorEventService, loadingEventService, renderer, generalDetailHandler);

        // afterOnSubmit
        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterOnSubmit).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );

        this.sub = this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterGetItem).subscribe(() => {
            if (this.item.attribute.translatable) {
                for ( const local of this.transApiService.getLocaleList()) {
                    if (typeof (this.item.lang.find(i => i.lang === local)) === 'undefined') {
                        this.item.lang.push({lang: local, value: this.item.name});
                    }
                }
            }
        });
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.generalService.setUri((this.section ? this.section + '_' : '') + 'selects/' + params['attribute_id']);
            const basePath = this.route.snapshot.data.basePath;
            this.actionLink = basePath +
                '/select-attribute-options/' + params['attribute_id'];
        });

        // initialize the item
        this.item = {
            name: null,
            id: 0,
            machine_ids: null,
            attribute: null,
            lang: null
        };

        // general initialization
        this.initialize();

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.attributes',
                url: this.platform + (this.section ? '/' + this.section : '') + '/attributes',
                isActive: false,
            },
            {
                name: 'api.selects',
                url: this.actionLink ,
                isActive: false,
            },
            {
                name: 'api.add',
                url: '',
                isActive: true,
            }];

        this.locales = this.transApiService.getLocaleList();
    }

    /**
     * unsubscribes route loading event
     */
    ngOnDestroy() {
        super.ngOnDestroy();
        this.sub.unsubscribe();
    }
}
