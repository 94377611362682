import {Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-mka-tag-input',
    templateUrl: './mka-tag-input.component.html',
    styleUrls: [
        './mka-tag-input.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})

export class MkaTagInputComponent implements OnInit {

    @Input() model;
    @Output() modelChange = new EventEmitter();
    @Input() placeHolder: any = '';
    @Input() maxItems: any = null;
    @Input() dragZone: any = null;
    @Input() identifyBy: any = null;
    @Input() displayBy: any = null;
    @Input() onlyFromAutocomplete: any = false;
    @Input() modelAsStrings: any = false;

    /**
     * Helper funciton for generalize the tag-input and include css to here
     *
     * @param newValue
     */
    change(newValue) {
        this.model = newValue;
        this.modelChange.emit(newValue);
    }

    constructor() {
    }

    ngOnInit() {
    }
}
