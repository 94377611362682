import {
  Directive,
  OnInit, Renderer2,
  ViewChild
} from '@angular/core';
import {Subcategory} from '../../../models/subcategory';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralDetail} from '../../../helper-classes/general-detail';
import {GeneralService} from '../../../services/general.service';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {GeneralDetailEvent} from '../../../models/event';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';

@Directive()
export abstract class BaseSubcategoryDetailComponent extends GeneralDetail implements OnInit {

    item: Subcategory;
    @ViewChild('fileInput', { static: false }) fileInput;
    extrafields;
    section: string = '';

    protected constructor(
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
        super(generalService, router, route, errorEventService, loadingEventService, renderer, generalDetailHandler);

        // afterOnSubmit
        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterOnSubmit).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );
    }

    ngOnInit() {
        // initialize the item
        this.item = {
            name: '',
            id: 0,
            extrafields: [],
            extrafields_array: [],
        };

        // general initialization
        this.initialize();

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.subcategories',
                url: this.platform + (this.section ? '/' + this.section : '') + '/subcategories',
                isActive: false,
            },
            {
                name: 'api.add',
                url: '',
                isActive: true,
            }];

        // set extrafields received from the FieldsdetailsResolver
        this.extrafields = this.route.snapshot.data.fieldsDetails.data.extrafield.filter(extrafield => extrafield.deleted_at == null);

    }

    /**
     * Override the GeneralDetail getItemDetail function including the extrafields update from the response
     */
    getItemDetail() {
        this.generalService.getItem(this.id).subscribe(
            data => {
                this.item = this.processItemResponse(data);
                this.extrafields = this.extrafields.filter(item => !this.item.extrafields.some(x => x.field_name === item));
                this.breadcrumbs[this.breadcrumbs.length - 1].name = 'api.edit';
            },
            (error) => {
                console.log(error);
            }
        );
    }
}
