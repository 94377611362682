import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { ComparisionSetupService } from 'app/shared/services/comparison-setup.service';
import { GeneralService } from 'app/shared/services/general.service';
import { MachineListingService } from 'app/shared/services/machineListing.service';

@Component({
  selector: 'app-compare',
  template: ''
})
export class CompareBaseMachineDetailComponent implements OnInit {
  machines: any[] = [];
  isLoading: boolean = true;
  url: string;
  machineIds: number[] = [];

  comparisonSetup: any = {
    selling_prices: true,
    traders_bids: true,
    trade_price: true,
    salesman_bids: true
  };


  @Input() toCompare: any[] = [];
  @Output() selectedtoCompare: number[] = [];

  // Change 'private' to 'protected' for generalService
  protected constructor(
    protected route: ActivatedRoute,  // Make route protected too
    protected generalService: GeneralService,  // Make generalService protected
    protected translatePipe: TranslatePipe,
    protected machineListingService: MachineListingService,
    protected comparisonSetupService: ComparisionSetupService
  ) {
  }

  ngOnInit(): void {
    this.comparisonSetupService.getComparision().subscribe(
      data => {
        const response = data.data;
        this.comparisonSetup = {
          trade_price: response.comparison_trade_prices == 1 ? true : false,
          selling_prices: response.comparison_selling_prices == 1 ? true : false,
          traders_bids: response.comparison_traders_bids == 1 ? true : false,
          salesman_bids: response.comparison_salesman_bids == 1 ? true : false
        };
      },
      (error) => {
        console.log(error);
      });

  }

  ngOnChanges(): void {
    let comparemachines = this.toCompare.map((machine) => {
      return {
        ...machine,
        display: true
      };
    });
    this.machines = comparemachines;
    this.machineIds = this.toCompare.map(machine => machine.id); // Push to machineIds
    this.isLoading = true;
    this.fetchMachines();

  }

  fetchMachines(): void {
    let body = {
      search: JSON.stringify(this.machineListingService.getSearchTerm()),
      listType: this.machineListingService.getListType(),
      sort: this.machineListingService.getSortBy()
    };
    this.generalService.getItemsByIds(this.machineIds, body).subscribe(
      (responses) => {
        // Create a map of machine IDs to images from the new responses
        const imageMap = new Map(
          responses.map((machine) => [machine.data.id, machine.data.images]) // Extract images array
        );

        // Update only the images property of existing machines
        this.machines.forEach((machine) => {
          if (imageMap.has(machine.id)) {
            machine.images = imageMap.get(machine.id) as string[]; // Update images array
          }
        });

        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching machines:', error);
        this.isLoading = false;
      }
    );

  }

  machineInfo(machine: any): void {
    // replace current machine image with the selected machine image
    this.machines = this.machines.map((m) => {
      if (m.id === machine.id) {
        return {
          ...m,
          images: machine.images,
          display: true
        };
      }
      return m;
    });
  }


  toggleCollapse(targetId: string, e): void {
    const collapseElement = document.getElementById(targetId);
    if (collapseElement) {
      const isCollapsed = collapseElement.classList.contains('show');
      if (isCollapsed) {
        collapseElement.classList.remove('show');
        collapseElement.classList.add('collapse');
        e.target.classList.add('collapsed');
      } else {
        collapseElement.classList.remove('collapse');
        collapseElement.classList.add('show');
        e.target.classList.remove('collapsed');
      }
    }
  }


}


