<div class="tablefilter">
{{column.formattedName | translate}}
<div class="search-popup" *ngIf="showPopup">
  <div style="float: right; clear: both;">
    <div class="close-btn" (click)="closeSearch($event)">
      <img src="assets/images/close.png">
    </div>
    <!--<ng-container *ngIf="type == FILTER_TYPE_INPUT">-->
      <!--<div class="close-btn" (click)="closeSearch($event)">-->
        <!--<i class="fa fa-filter" aria-hidden="true" (click)="getFilterValueSimple(filter_val)"></i>-->
      <!--</div>-->
    <!--</ng-container>-->
  </div>

  <div style="padding-right: 35px;">
<ng-container *ngIf="type == FILTER_TYPE_INPUT">
  <div class="row">
    <div class="col-md-12 paddingRight0">
      <input type = "text"
             [(ngModel)] = "filter_val"
             class = "filter"
             placeholder = "{{'api.search_item' | translate }}"
             (keydown.enter) = "getFilterValueSimple(filter_val)"
      >
    </div>
  </div>
</ng-container>

<!--Not used in current setup-->
<!--<ng-container *ngIf="type == FILTER_TYPE_SELECT">-->
  <!--<div class="row">-->
    <!--<div class="col-md-12">-->

      <!--<ng-multiselect-dropdown-->
              <!--[placeholder]="'custom placeholder3'"-->
              <!--[data]="select_options"-->
              <!--[(ngModel)]="filter_val"-->
              <!--[settings]="dropdownSettings"-->
              <!--(onSelect)="getFilterValue(filter_val)"-->
              <!--(onSelectAll)="getFilterValue(filter_val)"-->
      <!--&gt;-->
      <!--</ng-multiselect-dropdown>-->
    <!--</div>-->
  <!--</div>-->
<!--</ng-container>-->


<ng-container *ngIf="type == FILTER_TYPE_SELECT_YEAR">
  <div class="row">
          <div [ngClass]="this.column_name === 'created_at' ? 'col-xs-12' : 'col-xs-6'">
      <ng-select
              [items]="select_options"
              [multiple]="false"
              bindLabel="text"
              [selectableGroup]="true"
              [closeOnSelect]="false"
              bindValue="id"
              [(ngModel)]="startDate"
              (clear)="selectedYear(startDate, endDate)"
              (change)="selectedYear(startDate, endDate)"
              [isOpen]="true"
      >
      </ng-select>
    </div>
          <ng-container *ngIf="this.column_name !== 'created_at'">
    <div class="col-xs-6 paddingRight0">
      <ng-select
              [items]="select_options"
              [multiple]="false"
              bindLabel="text"
              [selectableGroup]="true"
              [closeOnSelect]="false"
              bindValue="id"
              [(ngModel)]="endDate"
              (clear)="selectedYear(startDate, endDate)"
              (change)="selectedYear(startDate, endDate)"
              [isOpen]="true"
      >
      </ng-select>

    </div>
          </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="type == FILTER_TYPE_SELECT_DATE">
  <div class="row">
    <div class="col-md-12">
      <input type="text" name="daterangeInput" daterangepicker
             class="filter"
             [options]="options"
             (selected)="selectedDate($event, daterange)"
             (cancelDaterangepicker)="emptyPicker($event)"
             />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type == FILTER_TYPE_SELECT_BOOL">
  <div class="row">
    <div class="col-xs-12 paddingRight0">
      <ng-select
        [multiple]="false"
        [items]="select_options"
        placeholder="Select option"
        bindLabel="text"
        [selectableGroup]="true"
        [closeOnSelect]="false"
        bindValue="id"
        [(ngModel)]="filter_val"
        (clear)="getFilterValueSimple(filter_val)"
        (change)="getFilterValueSimple(filter_val)"
        [isOpen]="true"
      >
      </ng-select>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type == FILTER_TYPE_RANGE_PRICE">
  <div class="row" *ngIf="this.range_price_max">
    <div class="col-md-12">
      <ngx-slider [(value)]="fromValue" [(highValue)]="toValue" [options]="range_price_max_option" (userChangeEnd)="myOnFinish($event,true)"></ngx-slider>

<!--       <ion-range-slider #sliderElement-->
<!--                         type="double"-->
<!--                         [min] = this.range_price_min-->
<!--                         [max] = this.range_price_max-->

<!--                         from_shadow="true"-->

<!--                         to_shadow="true"-->
<!--                         grid="true"-->
<!--                         grid_num="10"-->
<!--                         prefix=" "-->
<!--                         [postfix]="' ' + currentCurrency"-->
<!--                         decorate_both="false"-->
<!--                         [from]="fromValue"-->
<!--                         [to]="toValue"-->

<!--                         (onFinish)="myOnFinish($event, true)">-->

<!--         ></ion-range-slider>-->
    </div>
  </div>
  <div *ngIf="!this.range_price_max">
    No data
  </div>
</ng-container>

<ng-container *ngIf="type == FILTER_TYPE_RANGE_RATING">
  <div class="row">
    <div class="col-md-12">
      <ngx-slider [(value)]="fromValue" [(highValue)]="toValue" [options]="range_rating_option" (userChangeEnd)="myOnFinish($event)"></ngx-slider>

      <!--<ion-range-slider #sliderElement
                        type="int"
                        [min]="1"
                        [max]="10"
                        grid="true"
                        grid_num="9"
                        prefix=" "
                        [step]="1"
                        [from]="fromValue"
                        [to]="toValue"
                        (onFinish)="myOnFinish($event)">

        ></ion-range-slider>-->
    </div>
  </div>
</ng-container>


<ng-container *ngIf="type == FILTER_TYPE_MULTI_SELECT">
  <div (click)="selectAll()" style="cursor: pointer; float: right;"><i class="fas fa-check-double"></i>&nbsp;Select all</div>
  <div class="row">
    <div class="col-md-12">
      <ng-select
              [items]="select_options"
              [multiple]="true"
              bindLabel="text"
              [selectableGroup]="true"
              [closeOnSelect]="false"
              bindValue="id"
              [(ngModel)]="filter_val"
              (add)="getFilterValue(filter_val)"
              (remove)="getFilterValue(filter_val)"
              (clear)="getFilterValue(filter_val)"
              [isOpen]="true"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/>
          <div>{{item.text}}</div>
        </ng-template>
      </ng-select>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type == FILTER_TYPE_RANGE">
  <div class="row" *ngIf="this.range_price_max">
    <div class="col-md-12">
      <ngx-slider [(value)]="fromValue" [(highValue)]="toValue" [options]="range_rating_option" (userChangeEnd)="myOnFinish($event)"></ngx-slider>

      <!--<ion-range-slider #sliderElement
                        type="double"
                        [min] = this.range_price_min
                        [max] = this.range_price_max

                        from_shadow="true"

                        to_shadow="true"
                        grid="true"
                        grid_num="10"
                        prefix=" "
                        postfix=""
                        decorate_both="false"
                        [from]="fromValue"
                        [to]="toValue"

                        (onFinish)="myOnFinish($event, true)">

        ></ion-range-slider>-->
    </div>
  </div>
  <div *ngIf="!this.range_price_max">
    No data
  </div>
</ng-container>

  </div>
</div>
<!--<div *ngIf="column.searchable">-->
<div *ngIf="!notSearchable" (click)="preventDefault($event)">
  <div *ngIf="!filtered" style="border: 1px solid grey; top: 0; right: 0; padding: 2px; position: absolute; text-align: center; width: 20px; cursor: pointer;"><i class="fa fa-sort-down" aria-hidden="true"></i></div>
  <div *ngIf="filtered" style="border: 1px solid grey; top: 0; right: 0; padding: 2px; position: absolute; cursor: pointer;"><i class="fa fa-filter" aria-hidden="true"></i></div>
</div>

<!--<ng-container *ngFor="let selected of selected_filter" >-->

  <!--<div class="selected-filter" *ngIf="selected.text != '' && selected.attr == column.name"> {{selected.text}} </div>-->

  <!--<div *ngIf="selected.text != '' && selected.attr == column.name" style="border: 1px solid grey; top: 0; right: 0; padding: 2px; position: absolute;"><i class="fa fa-filter" aria-hidden="true"></i></div>-->

<!--</ng-container>-->

</div>
