import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild
} from '@angular/core';
import { TableColumn } from '../../models/table-column';
import { DragulaService } from 'ng2-dragula';
import { TranslatePipe } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MachineListingService } from '../../services/machineListing.service';

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: [
    './table-list.component.scss'
  ],
  providers: [TranslatePipe]
})

export class TableListComponent implements OnInit {
  @Input() columns: TableColumn[];
  @Input() rows;
  @Input() sortBy;
  @Input() numberOfPages: number = 1;
  @Input() currentPage: number = 1;
  @Input() currentSortBy: string = '';
  @Input() hasHeader: boolean = true;
  @Input() hasHeaderWithFilter: boolean = false;
  @Input() hasHeaderWithSortBy: boolean = true;
  @Input() draggable: boolean = false;
  @Input() hasActions: boolean = false;
  @Input() customStatuses1: any[] = [];
  @Input() customStatuses2: any[] = [];
  @Input() customStatuses3: any[] = [];
  dragged: boolean = false;
  @Output() onPageChange = new EventEmitter<any>();
  @Output() onItemPerPageChange = new EventEmitter<any>();
  @Output() onSortByChange = new EventEmitter<any>();
  @Output() onSearchChange = new EventEmitter<any>();
  @Output() onAdd = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onRowClick = new EventEmitter<any>();
  @Output() onFormElementChange = new EventEmitter<any>();
  @Output() onReOrder = new EventEmitter<any>();
  @Output() onReset ? = new EventEmitter<any>();
  searchText: string = '';
  status_filter: string = '';
  selected_machine_incomming_status_filter: string = null;
  selected_machine_outgoing_status_filter: string = null;
  tableFilter: any = [];
  table_filter: any = [];
  table_filter_selected: any = [];
  attributes: any = [];
  filter_type: any = {};
  machine_incomming_statuses: any = [];
  machine_outgoing_statuses: any = [];
  dropdownSettings = {};


  @ViewChild('valami', { static: true }) valami;


  public selected_filter: any[] = [];

  public body = document.body;

  public formElementsData: any = {};
  public itemPerPage = localStorage.getItem('itemPerPage') ? localStorage.getItem('itemPerPage') : 25;

  public itemsPerPageSelect: any = ['1', '2', '5', '25', '100'];

  constructor(
    private dragulaService: DragulaService,
    public translatePipe: TranslatePipe,
    private eRef: ElementRef,
    protected route: ActivatedRoute,
    protected router: Router,
    protected machineListingService: MachineListingService
  ) {

    // tslint:disable-next-line:max-line-length
    this.machine_incomming_statuses = this.route.snapshot.data && this.route.snapshot.data.fieldsDetails && this.route.snapshot.data.fieldsDetails.data.machine_incoming_statuses;
    // tslint:disable-next-line:max-line-length
    this.machine_outgoing_statuses = this.route.snapshot.data && this.route.snapshot.data.fieldsDetails && this.route.snapshot.data.fieldsDetails.data.machine_outgoing_statuses;

    // check if the table list should be draggable
    const bag: any = this.dragulaService.find('table-box');
    if (bag !== undefined) {
      this.dragulaService.destroy('table-box');
    }

    // settings for dragula
    dragulaService.createGroup('table-box', {
      moves: () => this.draggable
    });

    // subscribe to dragula "dragged" event
    dragulaService.dropModel('table-box').subscribe((value) => {
      this.dragged = true;
    });
  }

  ngOnInit() {
    this.tableFilter = (this.machineListingService.getSearchTerm()) ? this.machineListingService.getSearchTerm().table_filter : [];
    this.table_filter = (this.machineListingService.getSearchTerm()) ? this.machineListingService.getSearchTerm().table_filter : [];
    this.searchText = (this.machineListingService.getSearchTerm()) ? this.machineListingService.getSearchTerm().string : '';
    this.status_filter = (this.machineListingService.getSearchTerm()) ? this.machineListingService.getSearchTerm().status_filter : '';
    for (const column of this.columns) {
      if (column.type) {

        this.formElementsData[column.name] = {};

        this.filter_type[column.name] = column.type;
      }
    }

    if (this.rows) {
      this.rows.forEach((row) => {
        row.updated_at = moment(row.updated_at).format('YYYY-MM-DD');
      });
    }

    this.onSearchChange.emit({
      searchText: this.searchText,
      status_filter: this.status_filter,
      tableFilter: this.table_filter,
      incommingFilter: this.selected_machine_incomming_status_filter
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      defaultOpen: true
    };
  }

  /**
   * Return the page numbers what should be shown
   *
   * @param number
   * @returns {any[]}
   */
  createPages(number) {
    const range = [];
    for (let i = 1; i <= number; i++) {
      range[i - 1] = i;
    }
    if (number > 5) {
      let start = 1;
      let stop = this.currentPage - 3;
      let diff = 0;
      if (start < stop) {
        range[start] = '...';
        range.splice(start + 1, stop - start - 1);
        diff = stop - start - 1;
      }

      start = this.currentPage + 2;
      stop = number - 1;
      if (start < stop) {
        range[start - diff] = '...';
        range.splice(start + 1 - diff, stop - start - 1);
      }
    }

    return range;
  }

  /**
   * fire the onReOrder event
   */
  reOrder() {
    this.onReOrder.emit(this.rows);
    this.dragged = false;
  }

  /**
   * fire the onReset event
   */
  reset() {
    this.onReset.emit();
    this.dragged = false;
  }

  /**
   * fire the onPageChange event
   */
  changePage(page) {
    localStorage.setItem('listing.page', page);
    this.onPageChange.emit(page);
  }

  /**
   * fire the onItemPerPageChange event
   */
  changeItemPerPage(itemPerPage) {
    localStorage.setItem('itemPerPage', itemPerPage);
    this.onItemPerPageChange.emit(itemPerPage);
  }

  /**
   * fire the onSortByChange event
   */
  changeSortBy(sortBy) {
    localStorage.setItem('listing.sortBy', JSON.stringify(sortBy));
    this.onSortByChange.emit(sortBy);
  }


  keyDownFunction(event, searchText) {
    if (event.keyCode === 13) {
      this.searchText = searchText;
      this.onSearchChange.emit({
        searchText: this.searchText,
        status_filter: this.status_filter,
        tableFilter: this.table_filter,
        incommingFilter: this.selected_machine_incomming_status_filter
      });
    }
  }

  /**
   * fire the onSearchChange event
   */
  search(searchText) {
    this.searchText = searchText;
    this.onSearchChange.emit({
      searchText: this.searchText,
      status_filter: this.status_filter,
      tableFilter: this.table_filter,
      incommingFilter: this.selected_machine_incomming_status_filter,
      outgoingFilter: this.selected_machine_outgoing_status_filter
    });
  }

  onFilterChange(event) {

    this.selected_filter = [];

    this.table_filter_selected = event.table_filter;

    for (const selected of this.table_filter_selected) {

      this.selected_filter.push(selected.attr_id);

//             if (Array.isArray(selected.val)) {
//
//                 let text = '';
//
//                 for (const selected_val of selected.val) {
// console.log('selected.val.length');
// console.log(selected.val.length);
//                     if (selected.val.length === 1 && typeof selected_val !== 'undefined') {
//                         text += selected_val.text;
//                     } else {
//                         if (typeof selected_val !== 'undefined') {
//                             text += selected_val.text + ', ';
//                         }
//                     }
//                 }
//
//                 this.selected_filter.push({
//                     attr: selected.attr_id,
//                     text: text
//                 });
//             } else if (selected.start_date && selected.end_date) {
//
//             const start = selected.start_date.toString();
//             const end = selected.end_date.toString();
//
//             if ( start.includes('-') || end.includes('-') ) {
//               this.selected_filter.push({
//                 attr: selected.attr_id,
//                 text: moment(start).format('Y/MM/DD') + ' - ' + moment(end).format('Y/MM/DD')
//               });
//             } else {
//               this.selected_filter.push({
//                 attr: selected.attr_id,
//                 text:  (start === '1'  ? 'other' : start ) + ' - ' +  (end === '1'  ? 'other' : end)
//               });
//             }
//
//             } else if (selected.from || selected.to) {
//
//                 this.selected_filter.push({
//                     attr: selected.attr_id,
//                     text: selected.from + ' - ' + selected.to
//                 });
//
//             } else {
//                 this.selected_filter.push({
//                     attr: selected.attr_id,
//                     text: selected.val
//                 });
//             }
    }

    this.table_filter = event.table_filter;

    this.onSearchChange.emit({
      searchText: this.searchText,
      status_filter: this.status_filter,
      tableFilter: this.table_filter,
      incommingFilter: this.selected_machine_incomming_status_filter
    });
  }

  filterByStatus(status) {
    if (this.status_filter !== status) {
      this.status_filter = status;
    } else {
      this.status_filter = '';
    }
    this.search(this.searchText);
  }

  filterByIncommingStatus(status) {
    this.selected_machine_outgoing_status_filter = null;

    if (status) {
      this.selected_machine_incomming_status_filter = status;
    } else {
      this.selected_machine_incomming_status_filter = null;
    }
    this.search(this.searchText);
  }

  filterByOutgoingStatus(status) {
    this.selected_machine_incomming_status_filter = null;
    if (status) {
      this.selected_machine_outgoing_status_filter = status;
    } else {
      this.selected_machine_outgoing_status_filter = null;
    }
    this.search(this.searchText);
  }

  /**
   * fire the onAdd event
   */
  addAction() {
    this.onAdd.emit();
  }

  /**
   * fire the onEdit event
   */
  editAction(row) {
    this.onEdit.emit(row);
  }

  /**
   * fire the onDelete event
   */
  deleteAction(row) {
    const c = confirm(this.translatePipe.transform('message.are_you_sure_to_delete'));
    if (c === true) {
      this.onDelete.emit(row);
    }
  }

  /**
   * fire the onRowClick event
   */
  rowClickAction(row) {
    this.onRowClick.emit(row);
  }

  /**
   * fire the onFormElementChange event
   */
  checkboxCheckedAction() {
    this.onFormElementChange.emit(this.formElementsData);
  }

  nameOrKey(row, columnName, columnKey) {

    let value = (row[columnName] && columnKey
      ? row[columnName][columnKey]
      : row[columnName]);

    if (value === undefined) {
      value = null;
    }

    if (isNaN(value) && value !== null) {
      value = this.translatePipe.transform(value);
    }

    return value;
  }

  openSearch(event, searchable) {

    if (searchable) {
      const search_popup = this.eRef.nativeElement.querySelectorAll('.search-popup');
      const table_elem = document.querySelector('.table');

      const search = event.target;

      const hasShowClass = search.firstElementChild.classList.contains('show');

      search_popup.forEach(item => {
        item.classList.remove('show');
      });

      if (search.firstElementChild) {
        if (hasShowClass) {
          search.firstElementChild.classList.remove('show');
        } else {
          search.firstElementChild.classList.add('show');
        }

        if (search.offsetLeft + search.firstElementChild.clientWidth > table_elem.clientWidth) {
          search.firstElementChild.style.right = 0;
        }

      } else {
        if (hasShowClass) {
          search.parentElement.firstElementChild.classList.remove('show');
        } else {
          search.parentElement.firstElementChild.classList.add('show');
        }
      }
    }
  }

  closeSearch(event) {

    const close_btn = event.target.parentElement;
    const search = close_btn.parentElement;

    search.classList.remove('show');

    event.stopPropagation();
  }

  preventDefault(event) {
    event.stopPropagation();
  }

  resetFilters() {
    this.table_filter = [];
    this.onSearchChange.emit({
      searchText: this.searchText,
      status_filter: this.status_filter,
      tableFilter: this.table_filter,
      incommingFilter: this.selected_machine_incomming_status_filter
    });
    const tourl = this.router.url;
    this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
      this.router.navigate([tourl]));
  }

  sortColumnBy(prop: string) {
    return this.columns.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }
}
