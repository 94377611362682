import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { GeneralService } from '../../services/general.service';
import { LoadingEventService } from '../../services/loading-event.service';
import { LoadingEvent } from '../../models/event';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-shared-betweed-dealers-details',
  templateUrl: './shared-betweed-dealers-details.component.html',
  styleUrls: ['./shared-betweed-dealers-details.component.scss'],

  // create a separate instances of the GeneralService which will use the below uri
  providers: [
    TranslatePipe
  ]
})

export class SharedBetweedDealersDetailsComponent implements OnInit {

  @Input() machine: any = {};

  public filteredIncommingStatusList: any = {};
  public outgoingStatusList: any = {};
  public incomingStatusList: any = {};
  filterIncommingStatus: any = [3, 4];
  role: string = '';
  selectedIncommingStatus: any = null;

  // create a separate instances of the GeneralService
  constructor(private route: ActivatedRoute,
              protected generalService: GeneralService,
              private notifierService: NotifierService
  ) {
  }

  ngOnInit() {
    const outgoingStatuses = this.route.snapshot.data.fieldsDetails.data.machine_outgoing_statuses;
    const incomingStatuses = this.route.snapshot.data.fieldsDetails.data.machine_incoming_statuses;

    outgoingStatuses.forEach(item => {
      this.outgoingStatusList[item.id] = item.name;
    });
    incomingStatuses.forEach(item => {
      this.incomingStatusList[item.id] = item.name;
    });

    this.filteredIncommingStatusList = incomingStatuses.filter(item => {
      return this.filterIncommingStatus.includes(item.id);
    });

    this.role = JSON.parse(localStorage.getItem('current_user')).roles[0];
    // this.selectedIncommingStatus = this.machine.incoming_status;
    this.selectedIncommingStatus = this.filterIncommingStatus.includes(this.machine.incoming_status) ? this.machine.incoming_status : null;
  }

  incommingStatusOnChange() {
    if (this.selectedIncommingStatus === null) {
      return;
    }
    this.generalService.setUri('update_incoming_status');
    this.machine.incoming_status = this.selectedIncommingStatus;
    this.machine.incoming_status_updated_at = new Date();


    this.generalService.save(this.machine).subscribe((response) => {
      this.notifierService.show({
        type: 'success',
        message: `${response.message.subject} \n ${response.message.description}`
      });
      this.updateMachineOnStatusChange(response.json().data);
    });
  }

  private updateMachineOnStatusChange(data) {
    this.machine.is_for_sale = data.is_for_sale;
  }
}
