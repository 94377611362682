import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TransApiService} from '../../../shared/services/trans-api.service';

@Component({
    selector: 'app-autologin',
  template: ''
})

export class AutologinComponent implements OnInit {

    private scope: string;
    private token: string;

    constructor(private authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private transApi: TransApiService) {
    }

    /**
     * this makes the api call that saves the new token for the user
     * sets the language locale to the user’s language
     * navigates to the link that is set in the autologin link
     */
    autologin() {
        this.authService.getTokenWithAutologin(this.token, this.scope).subscribe(
            data => {
                const response = data.data;
        if (response.token !== undefined) {
                this.authService.saveNewToken(response.token, response.supportUrl);
                this.authService.saveUser(response.user, response.dealer_settings, response.trader);
                this.transApi.changeLocale(response.user.language);
                localStorage.currentCurrency = response.user.currency;
                localStorage.platform = '/' + response.redirect_to.split('/')[0];
                this.router.navigate([response.redirect_to]);
        } else {
          this.authService.logout();
          localStorage.setItem('redirect_to', response.redirect_to);
          this.router.navigate(['/login']);
        }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * interprets the auto login link
     * takes out the user type
     * sets the token
     * calls the autologin() function
     */
    ngOnInit() {
      this.route.data.subscribe((data) => {
        this.scope = 'web-api';
        this.token = this.route.snapshot.paramMap.get('token');
        this.autologin();
      });
    }
}
