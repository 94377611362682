<div class="container">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
    <h3>{{'api.comparison_setup' | translate}}</h3>

    <div>
        <div class="input-group">
            <input type="checkbox" name="trade_price" id="trade_price"  [checked]="comparisonSetup.trade_price" (change)="changeComparisonSetup('trade_price', !comparisonSetup.trade_price)">
            <label for="trade_price">{{'api.trade_price' | translate}}</label>
        </div>
        <div class="input-group">
            <input type="checkbox" name="selling_prices" id="selling_prices"  [checked]="comparisonSetup.selling_prices" (change)="changeComparisonSetup('selling_prices', !comparisonSetup.selling_prices)">
            <label for="selling_prices">{{'api.selling_prices' | translate}}</label>
        </div>
        <div class="input-group">
            <input type="checkbox" name="traders_bids" id="traders_bids" [checked]="comparisonSetup.traders_bids" (change)="changeComparisonSetup('traders_bids', !comparisonSetup.traders_bids)">
            <label for="traders_bids">{{'api.traders_bids' | translate}}</label>
        </div>
        <div class="input-group">
            <input type="checkbox" name="salesman_bids" id="salesman_bids" [checked]="comparisonSetup.salesman_bids" (change)="changeComparisonSetup('salesman_bids', !comparisonSetup.salesman_bids)">
            <label for="salesman_bids">{{'api.salesman_bids' | translate}}</label>
        </div>
        <button class="btn" (click)="saveSetup()" >{{'api.confirm' | translate}}</button>
    </div>
</div>
