export enum AuthEvent {
    AuthenticationState
}

export enum ErrorHandlerEvent {
    applyErrors,
    removeErrors,
}

export enum GeneralDetailEvent {
    afterGetItem,
    afterOnSubmit,
    afterDelete,
    afterReOrder,
    afterRefreshAllItem,
    afterListingInitialized,
    clearLastListingURL,
}

export enum LoadingEvent {
    translationLoaded,
    loadingStart,
    loadingFinished,
}

export enum TableFilterEvent {
    openPopup,
}
