import { Directive, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {Column} from '../../../../internal-platform-module/models/column';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralDetail} from '../../../helper-classes/general-detail';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {GeneralDetailEvent} from '../../../models/event';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';

@Directive()
export abstract class BaseColumnDetailComponent extends GeneralDetail implements OnInit, OnDestroy {

    item: Column;
    title: string = 'api.attribute_option';
    available_columns: any;
    selected_platform: string = '';
    section: string = '';
    showSearchDropdown: boolean = true;

    protected constructor(
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
        super(generalService, router, route, errorEventService, loadingEventService, renderer, generalDetailHandler);

        // afterOnSubmit]
        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterOnSubmit).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );

        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterGetItem).subscribe(() => {
                this.item.formattedName = this.item.formattedName.replace('api.', '');
                this.item.searchable = (this.item.searchable > 0) ? 1 : 0;
                // const item_data = this.route.snapshot.data.availableColumns.data[this.selected_platform][this.item.name];
                // if (!item_data || item_data['isSearchable'] === 0) {
                //     this.showSearchDropdown = false;
                // }
            })
        );
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.generalService.setUri((this.section ? this.section + '_' : '') + 'columns/' + params['platform']);
            const basePath = this.route.snapshot.data.basePath;
            this.actionLink = basePath + (this.section ? '/' + this.section : '') + '/columns/' + params['platform'];
            this.selected_platform = params.platform;
            const available_columns_object = this.route.snapshot.data.availableColumns.data[params.platform];
            this.available_columns = Object.keys(available_columns_object).map(function (index) {
                return available_columns_object[index];
            });
        });

        // initialize the item
        this.item = {
            name: null,
            id: 0,
            formattedName: '',
            pipes: '',
            align: '',
            key: '',
            goToDetailPage: 0,
            searchable: 0,
        };

        // general initialization
        this.initialize();

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.columns_platforms',
                url: this.platform + (this.section ? '/' + this.section : '') + '/columns_platforms',
                isActive: false,
            },
            {
                name: 'api.columns',
                url: this.platform + (this.section ? '/' + this.section : '') + '/columns/' + this.selected_platform,
                isActive: false,
                additional: this.selected_platform
            },
            {
                name: 'api.add',
                url: '',
                isActive: true,
            }];
    }

    attributeChanged() {
        if (this.route.snapshot.data.availableColumns.data[this.selected_platform][this.item.name]['align']) {
            this.item.align = this.route.snapshot.data.availableColumns.data[this.selected_platform][this.item.name]['align'];
        }

        if (this.route.snapshot.data.availableColumns.data[this.selected_platform][this.item.name]['formattedName']) {
            this.item.formattedName = this.route.snapshot.data.availableColumns.data[this.selected_platform][this.item.name]['formattedName'].replace('api.', '');
        }
        // this.showSearchDropdown = !(this.route.snapshot.data.availableColumns.data[this.selected_platform][this.item.name]['isSearchable'] === 0);
    }
}
