import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TraderService} from '../../../services/trader.service';
import {ErrorEventService} from '../../../services/error-event.service';
import {Trader} from '../../../models/trader';
import {GeneralDetail} from '../../../helper-classes/general-detail';
import {TranslateService} from '@ngx-translate/core';
import {LoadingEventService} from '../../../services/loading-event.service';
import { AuthService } from 'app/shared/services/auth.service';


@Component({
    selector: 'app-trader-details',
    templateUrl: './trader-details.component.html',
    styleUrls: [
        './trader-details.component.scss'
    ],

    // create a separate instances of the TraderService (extended from GeneralService) which will use the below uri
    providers: [
        TraderService,
        {provide: 'uri', useValue: 'traders'},
    ],
})

export class TraderDetailsComponent extends GeneralDetail implements OnInit {

    public item: Trader;
    public currencies: any;
    public mandatoryToggle: boolean = false;
    public useRole: string;

    constructor(protected errorEventService: ErrorEventService,
                protected loadingEventService: LoadingEventService,
                protected traderService: TraderService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected authService: AuthService,
                protected translate: TranslateService,
                protected renderer: Renderer2) {
        super(traderService, router, route, errorEventService, loadingEventService, renderer);
        this.currencies = this.route.snapshot.data.fieldsDetails.data.currency;
    }

    ngOnInit() {

        // initialize the item
        this.item = new Trader();
        this.breadcrumbEditText = 'api.edit_trader';
        // general initialization
        this.initialize();

        this.useRole = this.authService.getCurrentUser().user_type;
        
        if(this.useRole === 'sales_manager'){
            this.mandatoryToggle = true
        }

        // set the onSubmitNavigateUrl (where the page redirect after commit) based on groupType
        this.onSubmitNavigateUrl = this.platform + '/traders';

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.trader_management',
                url: this.platform + '/traders',
                isActive: false,
            },
            {
                name: 'api.add_trader',
                url: '',
                isActive: true,
            }];
    }

    handleMandatoryToggle() {
        this.mandatoryToggle = !this.mandatoryToggle;
    }
}
