export class UserGroup {
    id?: number = 0;
    name?: string = '';
    users?: any[] = [];
    user_ids?: any[] = [];
    type?: string = '';
    dealer_id?: number;

    constructor() {
    }
}
