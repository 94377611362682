import {Injectable} from '@angular/core';
import {MkaApiRequest} from './mka-api-request.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyListService {

    public companylist = null;

    constructor(private mkaApiRequest: MkaApiRequest) {
    }

    /**
     * Get all Companies (used by CompanyListResolver)
     *
     * @returns {Promise<any> | any}
     */
    getAll(): Promise<any> | any {
        if (this.companylist) {
            return this.companylist;
        }
        return this.mkaApiRequest.getRequest('companies')
            .toPromise()
            .then((data: Response) => {
                this.companylist = data;
                return this.companylist;
            })
            .catch(this.handleError);
    }

    public handleError(error: any): Promise<any> {
        return Promise.reject(error.message);
    }


}
