import { Directive, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralDetail } from '../../../../helper-classes/general-detail';
import { ErrorEventService } from '../../../../services/error-event.service';
import { LoadingEventService } from '../../../../services/loading-event.service';
import { GeneralService } from '../../../../services/general.service';
import { GeneralDetailEventService } from '../../../../services/general-detail-event.service';
import { GeneralDetailEvent, LoadingEvent } from '../../../../models/event';
import { TranslatePipe } from '@ngx-translate/core';
import { MachineListingService } from '../../../../services/machineListing.service';

declare var $: any;

@Directive()
export abstract class CompareBaseMachineDetailSingleComponent
  extends GeneralDetail
  implements OnInit {
  @Input() id = null;
  item: any;
  @Output() machineInfo = new EventEmitter();
  // productImages: any;
  editable: boolean = false;
  statuses: any = {};
  available_incoming_statuses: any;
  available_outgoing_statuses: any;
  incomingStatuses: any = {};
  @ViewChild('childComponent', { static: false }) childComponent;
  canRevert: boolean = true;
  loading: boolean = false;
  loading_zip: boolean = false;
  section: string = '';
  isLoading = false;
  isSharedBetweenDealershipsActive: boolean = false;
  isEditableByUser: boolean = false;
  finalFilteredStatus: string = null;
  // For nex-prev
  next: any = '';
  prev: any = '';
  url: string;

  protected constructor(
    protected errorEventService: ErrorEventService,
    protected loadingEventService: LoadingEventService,
    protected generalService: GeneralService,
    protected statusService: GeneralService,
    protected machineService: GeneralService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected renderer: Renderer2,
    protected generalDetailHandler: GeneralDetailEventService,
    protected translatePipe: TranslatePipe,
    protected withStatuses: boolean = false,
    protected machineListingService: MachineListingService,
  ) {
    super(
      generalService,
      router,
      route,
      errorEventService,
      loadingEventService,
      renderer,
      generalDetailHandler
    );

    // afterOnSubmit set editable to false
   

    this.loadingEventService.subscribeToEvent(LoadingEvent.loadingStart).subscribe((body) => {
      this.isLoading = true;
    });

    this.loadingEventService.subscribeToEvent(LoadingEvent.loadingFinished).subscribe((body) => {
      this.isLoading = false;
    });

    // this._serviceSubscription = this.isEditingService.isEditing.subscribe({
    //   next: (event: boolean) => {
    //     console.log(`Received message #${event}`);
    //   }
    // });

   
    
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges() {
    if(this.id){
      super.getItemsDetail({
        search: JSON.stringify(this.machineListingService.getSearchTerm()),
        listType: this.machineListingService.getListType(),
        sort: this.machineListingService.getSortBy(),
      } , this.id);
    }
  }

  // change the machine incoming_status
  incomingStatusChange() {
    if (
      !this.editable &&
      this.childComponent.anyTraderBidPending &&
      (this.item.incoming_status ===
        this.incomingStatuses.incoming_status_trade_in_order.id ||
        this.item.incoming_status ===
          this.incomingStatuses.incoming_status_trade_in_lost.id)
    ) {
      let revert = false;
      if (
        this.item.incoming_status ===
        this.incomingStatuses.incoming_status_trade_in_order.id
      ) {
        if (
          confirm(
            this.translatePipe.transform('api.changed_to_trade_in_confirm')
          )
        ) {
          this.acceptPendingTraderBid();
        } else {
          revert = true;
        }
      }
      if (
        this.item.incoming_status ===
        this.incomingStatuses.incoming_status_trade_in_lost.id
      ) {
        if (
          confirm(
            this.translatePipe.transform('api.changed_to_trade_in_lost_confirm')
          )
        ) {
          this.rejectPendingTraderBid();
        } else {
          revert = true;
        }
      }
      if (revert) {
        setTimeout(() => {
          this.revertStatus();
        }, 100);
      }
    } else {
      this.generalService.setUri('update_incoming_status');
      this.generalService.save(this.item).subscribe((response) => {
        this.filterStatuses();
        this.editable = false;
        this.updateMachineOnStatusChange(response.json().data);
      });
    }
  }

  // change the machine outgoing_status
  outgoingStatusChange() {
    this.generalService.setUri('update_outgoing_status');
    this.generalService.save(this.item).subscribe((response) => {
      this.filterStatuses();
      this.editable = false;

      this.updateMachineOnStatusChange(response.json().data);
    });
  }

  filterStatuses() {
    if (this.withStatuses) {
      const item_incoming_status = this.statuses.allIncomingStatuses.find(
        (item) => item.id === this.item.incoming_status
      );
      this.available_incoming_statuses = this.statuses.allIncomingStatuses.filter(
        (item) =>
          item_incoming_status.next_statuses.indexOf(item.id) >= 0 ||
          item.id === this.item.incoming_status
      );

      const item_outgoing_status = this.statuses.allOutgoingStatuses.find(
        (item) => item.id === this.item.outgoing_status
      );
      this.available_outgoing_statuses = this.statuses.allOutgoingStatuses.filter(
        (item) =>
          item_outgoing_status.next_statuses.indexOf(item.id) >= 0 ||
          item.id === this.item.outgoing_status
      );
    }
  }

  editableChanged(editable) {
    if (editable && this.withStatuses) {
      this.available_incoming_statuses = this.statuses.allIncomingStatuses.filter((item) => item.id != 0);
      this.available_outgoing_statuses = this.statuses.allOutgoingStatuses.filter((item) => item.id != 1 && item.id != 4 && item.id != 3 && item.id != 7 && item.id != 8);
    } else {
      this.filterStatuses();
    }
  }

  cancel() {
    this.editable = false;
    this.editableChanged(false);
  }

  productDetailChanged(item) {
    this.item = item;
    this.filterStatuses();
  }

  acceptPendingTraderBid() {
    // create the item which is sent to API
    const item = {
      id: this.item.id,
      emailTemplate: 'accept_pending_trader_bid',
    };

    this.canRevert = false;

    // accept the bid (to API)
    this.statusService.setUri('accept_pending_bid');
    this.statusService.save(item).subscribe((data) => {
      const response = data.data;
      this.childComponent.refreshBids(response);
      this.filterStatuses();
    });
  }

  rejectPendingTraderBid() {
    // create the item which is sent to API
    const item = {
      id: this.item.id,
      emailTemplate: 'reject_pending_trader_bid',
    };

    this.canRevert = false;

    // accept the bid (to API)
    this.statusService.setUri('reject_pending_bid');
    this.statusService.save(item).subscribe((data) => {
      const response = data.data;
      this.childComponent.refreshBids(response);
      this.filterStatuses();
    });
  }

  revertStatus() {
    if (this.canRevert) {
      this.item.incoming_status = this.incomingStatuses.incoming_status_pending_trade_in.id;
      this.filterStatuses();
    }
    this.canRevert = true;
  }

  // show the generated PDF
  downloadPdf() {
    window.open(this.item.pdf_link);
  }

  // (re)generate the PDF
  generatePdf() {
    this.loading = true;
    this.machineService.setUri(
      (this.section ? '_' + this.section : '') + 'machines/generatePdf'
    );
    this.machineService.getItem(this.item.id).subscribe((data) => {
      this.item.pdf_link = data.data;
      this.loading = false;
    });
  }

 

  downloadAllImages() {
    this.loading_zip = true;
    this.machineService.setUri(
      (this.section ? this.section + '_' : '') + 'machines/generateImagesZIP'
    );
    this.machineService.getItem(this.item.id).subscribe((data) => {
      const response = data.data;
      window.open(response);
      this.loading_zip = false;
    });
  }

  /**
   * On status change the machine is sent back, it updates some items from the server on the current machine
   * @param data
   */
  private updateMachineOnStatusChange(data) {
    this.item.is_for_sale = data.is_for_sale;
  }
}
