import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {MachineColumnsService} from '../../internal-platform-module/services/machine-columns.service';

/**
 * Resolver to get all Fieldsdetails
 */
@Injectable({
    providedIn: 'root'
})
export class InternalSpecificationColumnsResolver implements Resolve<any> {
    constructor(
        private machineColumnsService: MachineColumnsService
    ) {}

    resolve(route?: ActivatedRouteSnapshot): Promise<any> {
        return this.machineColumnsService.getColumns('specification');
    }
}
