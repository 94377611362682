import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
    @Input() value: number;
    @Input() min: number = 1;
    @Input() max: number = 10;
    @Input() editable: boolean;
    numbers: number[];
    @Output() onChange = new EventEmitter();

    public static range(min: number, max: number) {
        const range: number[] = [];
        for (let i = min; i <= max; i++) {
            range.push(i);
        }
        return range;
    }

    constructor() { }

    ngOnInit() {
        this.numbers = RatingComponent.range(this.min, this.max);
    }

    rate(newValue: number) {
        if (this.editable) {
            this.value = newValue;
            this.onChange.emit(this.value);
        }
    }
}
