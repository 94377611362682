<div class="container">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
    <div class="row">
        <div class="col-sm-6">
            <h3 class="sales-heading">{{item.id > 0 ? ('api.edit' | translate) : ('api.add' | translate)}} {{'api.category' | translate}}</h3>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <form class="form-horizontal" #form (submit)="onSubmit()" >
                <div class="form-group">
                    <label class="col-sm-4">{{'api.name' | translate}}</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="name" name="name" placeholder="{{'api.name' | translate}}" [(ngModel)]="item.name">
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-4">{{'api.image' | translate}}</label>
                    <div class="col-sm-8">
                        <img src="{{item.image_url}}" *ngIf="item.image_url !== ''" width="300"/>
                        <input #fileInput type="file" class="form-control" id="image" placeholder="{{'api.image' | translate}}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4">{{'api.types' | translate}}</label>
                    <div class="col-sm-8">
                        <div class="row" name="types">
                            <div class="col-sm-6">
                                {{'api.selected_types' | translate}}
                                <div class="draggable" [dragula]='"bag-one"' [(dragulaModel)]='item.types' *ngIf="item.types">
                                    <div *ngFor="let type of item.types">
                                        {{type.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                {{'api.available_types' | translate}}
                                <div class="draggable" [dragula]='"bag-one"' [(dragulaModel)]='types'>
                                    <div *ngFor="let type of types">
                                        {{type.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-4"></label>
                    <div class="col-sm-8">
                        <input type="submit" name="submit" title="" class="login-name buttons marginTop5" id="confirm" value="{{'api.confirm' | translate}}">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
