<div *ngIf="productDetail">

<!--  disabled for pon-->
<!--    <div class="row show-to-all-dealers" *ngIf="isDealersEnabled && (!productDetail.is_shared_between_dealers_by || productDetail.is_shared_by_my_dealership)">-->
<!--        <div class="col-xs-8">-->
<!--            <span>{{'api.show_to_all_dealers' | translate}}</span>-->
<!--        </div>-->
<!--        <div class="col-xs-4 text-right">-->
<!--            <input type="checkbox" [(ngModel)]="isSharedBetweenDealers" (change)="markSharedBetweenDealers()"-->
<!--                   name="show_to_all_dealers" value="1">-->
<!--        </div>-->
<!--    </div>-->

    <!-- <div class="row show-to-all-traders" *ngIf="isDealersEnabled && productDetail.is_for_sale">
        <div class="col-xs-8">
            <span>{{'api.show_to_all_traders' | translate}}</span>
        </div>
        <div class="col-xs-4 text-right">
            <input type="checkbox" [(ngModel)]="isSharedBetweenTraders" (change)="markSharedBetweenTraders()" name="show_to_all_traders" value="1">
        </div>
    </div> -->

  <ng-container *ngIf="role !== 'admin'">

    <span class="fontWeightBold">{{'api.sales_input' | translate}}</span><br/>

    <div class="row">
        <div class="col-xs-6">
            <span>{{'fields.customer_name' | translate}}:</span>
        </div>
        <div class="col-xs-6">
            <input class="editable" [attr.type]="'text'" [attr.name]="'customer_name'" [disabled]="!editable"
                   [(ngModel)]="productDetail.customer_name"/>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-6">
            <span>{{'api.location' | translate}}:</span>
        </div>
        <div class="col-xs-6">
            <input class="editable" [attr.type]="'text'" [attr.name]="'location'" [disabled]="!editable"
                   [(ngModel)]="productDetail.location"/>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-6"><span>{{'api.commercial_trade_in_value' | translate}}:</span></div>
        <div class="col-xs-6">
            <span *ngIf="!editable">{{productDetail.commercial_trade_in_value | multipipe:'currency:' + productDetail.commercial_trade_in_value_currency + ':symbol:1.2-4'}}</span>
            <div *ngIf="editable" class="col-xs-8 nopadding">
                <input class="right" type="text" id="commercial_trade_in_value" name="commercial_trade_in_value"
                       [(ngModel)]="productDetail.commercial_trade_in_value"
                       placeholder="{{'api.commercial_trade_in_value' | translate}}">
            </div>
            <div *ngIf="editable" class="col-xs-4 nopadding">
          <select [disabled]="productDetail.commercial_trade_in_value_available || !editable"
                  [attr.name]="'commercial_trade_in_value_currency'"
                        [(ngModel)]="productDetail.commercial_trade_in_value_currency"
                        [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let item of currencies" [value]="item"
                            [selected]="(productDetail.commercial_trade_in_value_currency == item) ? 'selected' : ''">
                        {{item}}
                    </option>
                </select>
            </div>
        </div>
    </div>

<!--  veractert-->
    <div class="form-group book-value row">
        <div class="col-xs-6"><span>{{'api.book_value' | translate}}:</span></div>
        <div class="col-xs-6">
            <span *ngIf="productDetail.book_value_available && !editable">{{productDetail.book_value | multipipe:'currency:' + productDetail.book_value_currency + ':symbol:1.2-4'}}</span>
            <div *ngIf="!productDetail.book_value_available || editable" class="col-xs-8 nopadding">
                <input class="right" type="text" id="book_value" name="book_value"
                       [(ngModel)]="productDetail.book_value" placeholder="{{'api.book_value' | translate}}">
            </div>
            <div *ngIf="!productDetail.book_value_available || editable" class="col-xs-4 nopadding">
          <select [attr.name]="'book_value_currency'"
                        [(ngModel)]="productDetail.book_value_currency" class="book_value_select"
                        [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let item of currencies" [value]="item"
                            [selected]="(productDetail.book_value_currency == item) ? 'selected' : ''">{{item}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-xs-12 paddingtop5" *ngIf="!productDetail.book_value_available">
            <button (click)="saveBookValue()" class="btn btn-sm pull-right custom-sm-btn">{{'api.reply_book_value' | translate}}</button>
        </div>
    </div>

    <div class="transport-cost row">
        <div class="col-xs-6"><span>{{'api.transport_cost' | translate}}:</span></div>
        <div class="col-xs-6">
            <div class="col-xs-8 nopadding">
                <input class="right" type="text" id="transport_cost" name="transport_cost"
                 [disabled]="!editable"
                       [(ngModel)]="productDetail.transport_cost" placeholder="{{'api.transport_cost' | translate}}"
                       (change)="saveTransportCost()">
            </div>
            <div class="col-xs-4 nopadding">
          <select [disabled]="productDetail.transport_cost_available || !editable"
                  [attr.name]="'transport_cost_currency'"
                  [(ngModel)]="productDetail.transport_cost_currency"
                        class="transport_cost_select" [ngModelOptions]="{standalone: true}"
                        (change)="saveTransportCost()">
                    <option *ngFor="let item of currencies" [value]="item"
                            [selected]="(productDetail.transport_cost_currency == item) ? 'selected' : ''">{{item}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="revision-cost row">
        <div class="col-xs-6"><span>{{'api.revision_cost' | translate}}:</span></div>
        <div class="col-xs-6">
            <div class="col-xs-8 nopadding">
                <input class="right" type="text" id="revision_cost" name="revision_cost"
                 [disabled]="!editable"
                       [(ngModel)]="productDetail.revision_cost" placeholder="{{'api.revision_cost' | translate}}"
                       (change)="saveRevisionCost()">
            </div>
            <div class="col-xs-4 nopadding">
          <select [disabled]="productDetail.revision_cost_available || !editable" [attr.name]="'revision_cost_currency'"
                  [(ngModel)]="productDetail.revision_cost_currency"
                        class="revision_cost_select" [ngModelOptions]="{standalone: true}"
                        (change)="saveRevisionCost()">
                    <option *ngFor="let item of currencies" [value]="item"
                            [selected]="(productDetail.revision_cost_currency == item) ? 'selected' : ''">{{item}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="sales-price row">
        <div class="col-xs-6"><span>{{'api.sales_price' | translate}}:</span></div>
        <div class="col-xs-6">
            <div class="col-xs-8 nopadding">
                <input class="right" type="text" id="sales_price" name="sales_price"
                 [disabled]="!editable"
                       [(ngModel)]="productDetail.sales_price" placeholder="{{'api.sales_price' | translate}}"
                       (change)="saveSalesPrice()">
            </div>
            <div class="col-xs-4 nopadding">
          <select [disabled]="productDetail.sales_price_available ||!editable" [attr.name]="'sales_price_currency'"
                  [(ngModel)]="productDetail.sales_price_currency"
                        class="sales_price_select" [ngModelOptions]="{standalone: true}" (change)="saveSalesPrice()">
                    <option *ngFor="let item of currencies" [value]="item"
                            [selected]="(productDetail.sales_price_currency == item) ? 'selected' : ''">{{item}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-xs-6" *ngIf="productDetail.sales_price > 0">
            <div class="margin_10_0">
                <button *ngIf="sentSalesPrices?.length > 0" (click)="showSentSalesPricesToggle()"
                        class="btn btn-success btn-sm btn-danger custom-sm-btn">{{((!showSentSalesPrices) ?
                    'api.show_sales_prices' : 'api.hide_sales_prices') | translate}}
                </button>
            </div>
        </div>
        <!-- <div class="col-xs-6" *ngIf="productDetail.sales_price > 0">
            <div class="margin_10_0">
                <button class="btn custom-sm-btn pull-right black_btn" [routerLink]="['sales-price-to-salesmen']">
                    {{'api.select_salesman' | translate}}
                </button>
            </div>
        </div> -->
        <div class="col-xs-12 table-one" *ngIf="showSentSalesPrices">
            <table class="table">
                <tbody>
                <tr *ngFor="let sentSalesPrice of sentSalesPrices" class="status_{{sentSalesPrice.status}}">
                    <td [innerHtml]="sentSalesPrice.user?.name"></td>
                    <td>
                        <div *ngIf="
                        (productDetail.outgoing_status === outgoingStatuses.outgoing_status_inform_sales.id  ||
                        productDetail.outgoing_status === outgoingStatuses.outgoing_status_sales_order.id)
                        "
                        >
                            <div class="floatRight">
                                <button *ngIf="!anySalesmanBecameWinner || sentSalesPrice.status === sentSalesPriceStatuses.status_won.id"
                                        class="btn btn-sm custom-sm-btn {{(sentSalesPrice.status === sentSalesPriceStatuses.status_won.id ? 'winner': 'mark_winner')}}"
                                        [disabled]="sentSalesPrice.status === sentSalesPriceStatuses.status_won.id"
                                        (click)="markWinner(sentSalesPrice.id)"
                                >
                                    {{((sentSalesPrice.status !== sentSalesPriceStatuses.status_won.id) ?
                                    'api.mark_winner' : 'api.winner') | translate}}
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-6"><span>{{'api.selling_price' | translate}}:</span></div>
        <div class="col-xs-6">
            <div class="col-xs-8 nopadding">
                <input class="right" type="text" id="selling_price" name="selling_price"
                 [disabled]="!editable"
                       [(ngModel)]="productDetail.selling_price" placeholder="{{'api.selling_price' | translate}}"
                       (change)="saveSellingPrice()">
            </div>
            <div class="col-xs-4 nopadding">
          <select [disabled]="productDetail.selling_price_available || !editable" [attr.name]="'selling_price_currency'"
                  [(ngModel)]="productDetail.selling_price_currency"
                        class="selling_price_select" [ngModelOptions]="{standalone: true}"
                        (change)="saveSellingPrice()">
                    <option *ngFor="let item of currencies" [value]="item"
                            [selected]="(productDetail.selling_price_currency == item) ? 'selected' : ''">{{item}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="showWholesalesPrice">
        <div class="col-xs-6"><span>{{'api.wholesales_price' | translate}}:</span></div>
        <div class="col-xs-6">
            <div class="col-xs-8 nopadding">
                <input class="right" type="text" id="wholesales_price" name="wholesales_price"
                 [disabled]="!editable"
                       [(ngModel)]="productDetail.wholesales_price" placeholder="{{'api.wholesales_price' | translate}}"
                       (change)="saveWholesalesPrice()">
            </div>
            <div class="col-xs-4 nopadding">
          <select [disabled]="productDetail.wholesales_price_available || !editable"
                  [attr.name]="'wholesales_price_currency'"
                  [(ngModel)]="productDetail.wholesales_price_currency"
                        class="wholesales_price_select" [ngModelOptions]="{standalone: true}"
                        (change)="saveWholesalesPrice()">
                    <option *ngFor="let item of currencies" [value]="item"
                            [selected]="(productDetail.wholesales_price_currency == item) ? 'selected' : ''">{{item}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12">
            <textarea id="used_manager_remarks" name="used_manager_remarks"
                      [disabled]="!editable"
                      [(ngModel)]="productDetail.used_manager_remarks"
                      placeholder="{{'api.used_manager_remarks' | translate}}"
                      (change)="saveUsedManagerRemarks()"></textarea>
        </div>
    </div>
  </ng-container>

    <section class="sales-info-block-table">

        <div class="table-one margin-top-30">

            <div class="margin_10_0">
                <span class="bold">{{'api.invite_salesman_to_bid' | translate}}</span>
                <button class="btn custom-sm-btn pull-right black_btn" [routerLink]="['send-to-salesmen']">
                    {{'api.select_salesman' | translate}}
                </button>
            </div>
            <app-machine-bids-list [bidList]="salesmanBids" [machine]="productDetail" [anyBidsAccepted]="anySalesmanBidAccepted" (bidAccepted)="acceptBid($event)"
                                   (bidRejected)="rejectBid($event)"
                                   (timeExtended)="extendBidTime($event)"></app-machine-bids-list>
        </div>


        <div class="table-one margin-top-30">
            <div class="margin_10_0">
                <span class="bold">{{'api.invite_trader_to_bid' | translate}}</span>
                <button class="btn custom-sm-btn pull-right black_btn" [routerLink]="['send-to-traders']">{{
                    'api.select_trader' | translate}}
                </button>
            </div>
            <app-machine-bids-list [bidList]="traderBids" [machine]="productDetail" [anyBidsAccepted]="anyTraderBidAccepted" (bidAccepted)="acceptBid($event)"
                                   (bidRejected)="rejectBid($event)"
                             (timeExtended)="extendBidTime($event)"
                             (refreshBid)="refreshUpdatedBids($event)"
      ></app-machine-bids-list>
        </div>
    </section>
    <button class="btn custom-sm-btn pull-right red_btn delete_btn" (click)="delete()">
        {{'api.delete' | translate}}
    </button>
    <div class="clearBoth"></div>
</div>
