import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { GeneralFields } from '../../helper-classes/general-fields';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { LoadingEventService } from '../../services/loading-event.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-product-fields',
  templateUrl: './product-fields.component.html',
  styleUrls: ['./product-fields.component.scss'],
  providers: [TranslatePipe, DatePipe]
})

export class ProductFieldsComponent implements OnInit, OnChanges {
  @Input() productDetail: any;
  @Input() withCustomerName: boolean = false;
  @Input() withCustomerLocation: boolean = false;
  @Input() withPrices: boolean = false;
  fieldDetails: any;
  role: string = '';
  generalFields: string[];
  fields: any;
  @Input() editable: boolean = false;
  @Output() editableChange = new EventEmitter();
  selects: any = [];
  attributes: any;
  attributes_data: any;
  nonEditableFields: string[] = ['category', 'type', 'subcategory'];
  subcategory_data = this.route.snapshot.data.fieldsDetails.data.subcategory;
  today: any = new Date();

  private createSelect(min: number, max: number) {
    const range: any = [];
    const start = (min) ? min : _.get(this.route.snapshot.data, 'fieldsDetails.data.year_selector_min_value', 1978);
    const stop = (max) ? max : ((new Date()).getFullYear());
    for (let i = start; i <= stop; i++) {
      range.push({ 'id': i, 'name': i });
    }

    range.push({ 'id': 1, 'name': 'other' });

    return range;
  }

  // tslint:disable-next-line:max-line-length
  constructor(private route: ActivatedRoute, private translate: TranslateService, private loadingEventService: LoadingEventService, private datePipe: DatePipe) {

    this.fieldDetails = { ...GeneralFields.FIELD_DETAILS, ...this.attributes };
    this.role = JSON.parse(localStorage.getItem('current_user')).roles[0];
    this.generalFields = GeneralFields.GENERAL_FIELDS;
  }

  /**
   * the field details are initialized
   */
  ngOnInit() {
    this.fields = Array();

    this.attributes = {};

    this.selects = { ...this.route.snapshot.data.fieldsDetails.data };
    this.attributes_data = this.route.snapshot.data.fieldsDetails.data.attributes;

    for (const attribute of this.attributes_data) {
      const dependent_attribute = this.get_dependent_attribute_data(attribute);

      switch (attribute.type) {
        case 1: // checkbox - ATTRIBUTE_TYPES[1]

          this.attributes[attribute.attribute_name] = {
            formattedName: 'fields.' + attribute.attribute_name,
            colspan: false,
            type: 'boolean',
            dependent_to: dependent_attribute.dep_attribute_name,
            dependent_values: dependent_attribute.dep_values
          };
          break;
        case 2: // select - ATTRIBUTE_TYPES[2]

          this.attributes[attribute.attribute_name] = {
            formattedName: 'fields.' + attribute.attribute_name,
            colspan: false,
            type: 'select_direct',
            option_attr: (attribute.translatable == 1) ? 'translated' : 'name',
            dependent_to: dependent_attribute.dep_attribute_name,
            dependent_values: dependent_attribute.dep_values
          };

          break;
        case 3: // select_with_other - ATTRIBUTE_TYPES[3]

          this.attributes[attribute.attribute_name] = {
            formattedName: 'fields.' + attribute.attribute_name,
            colspan: false,
            type: 'select',
            option_attr: (attribute.translatable == 1) ? 'translated' : 'name',
            with_other: true,
            dependent_to: dependent_attribute.dep_attribute_name,
            dependent_values: dependent_attribute.dep_values
          };
          break;
        case 4: // dropdown_year - ATTRIBUTE_TYPES[4]

          this.attributes[attribute.attribute_name] = {
            formattedName: 'fields.' + attribute.attribute_name,
            colspan: false,
            type: 'select_date',
            option_attr: 'name',
            dependent_to: dependent_attribute.dep_attribute_name,
            dependent_values: dependent_attribute.dep_values
          };
          break;
        case 5: // number - ATTRIBUTE_TYPES[5]

          this.attributes[attribute.attribute_name] = {
            formattedName: 'fields.' + attribute.attribute_name,
            colspan: false,
            type: 'input',
            dependent_to: dependent_attribute.dep_attribute_name,
            dependent_values: dependent_attribute.dep_values
          };
          break;
        case 6: // input - ATTRIBUTE_TYPES[6]

          this.attributes[attribute.attribute_name] = {
            formattedName: 'fields.' + attribute.attribute_name,
            colspan: false,
            type: 'input',
            dependent_to: dependent_attribute.dep_attribute_name,
            dependent_values: dependent_attribute.dep_values
          };
          break;
        case 7: // input - ATTRIBUTE_TYPES[7]

          this.attributes[attribute.attribute_name] = {
            formattedName: 'fields.' + attribute.attribute_name,
            colspan: false,
            type: 'rating',
            dependent_to: dependent_attribute.dep_attribute_name,
            dependent_values: dependent_attribute.dep_values
          };
          break;
        case 8: // textarea - ATTRIBUTE_TYPES[7]
          this.attributes[attribute.attribute_name] = {
            formattedName: 'fields.' + attribute.attribute_name,
            colspan: true,
            type: 'textarea',
            dependent_to: dependent_attribute.dep_attribute_name,
            dependent_values: dependent_attribute.dep_values
          };
          break;

        case 9: // test_select_date - ATTRIBUTE_TYPES[9]
          this.attributes[attribute.attribute_name] = {
            formattedName: 'fields.' + attribute.attribute_name,
            colspan: false,
            type: 'test_select_date',
            dependent_to: dependent_attribute.dep_attribute_name,
            dependent_values: dependent_attribute.dep_values
          };
          break;
        default:
          console.log('problem');
      }

      // }
    }

    this.fieldDetails = { ...GeneralFields.FIELD_DETAILS, ...this.attributes };
    this.generalFields = GeneralFields.GENERAL_FIELDS;
  }

  /**
   * on change the product details are updated
   * the extra fields depending on product's subcategory_id are being loaded
   */
  ngOnChanges() {

    if (this.productDetail) {
      const category = this.route.snapshot.data.fieldsDetails.data.category
        .find(item => item.id === this.productDetail.category_id);
      this.productDetail.category = category ? category.translated_name : null;
      const type = this.route.snapshot.data.fieldsDetails.data.type
        .find(item => item.id === this.productDetail.type_id);
      this.productDetail.type = type ? type.translated_name : null;
      const subcategory = this.route.snapshot.data.fieldsDetails.data.subcategory
        .find(item => item.id === this.productDetail.subcategory_id);
      this.productDetail.subcategory = subcategory ? subcategory.translated_name : null;
      const extraFields = this.route.snapshot.data.extraFields.data.find(
        x => x.id === this.productDetail.subcategory_id
      );

      this.fields = this.generalFields.concat(extraFields ? extraFields.extrafields.map(x => x.field_name) : []);

      // todo: review
      for (const key in this.attributes) {
        if (this.attributes[key]
          // tslint:disable-next-line:max-line-length
          && (this.attributes[key].type === 'select' || this.attributes[key].type === 'select_direct' || this.attributes[key].type === 'select_date')) {
          if (this.productDetail[key] == null || this.productDetail[key] == undefined) {
            const temp = this.productDetail.machine_attributes.find(x => x.attribute.attribute_name === key);
            if (typeof temp !== 'undefined') {
              if (temp.select_id === 1) {
                this.productDetail[key] = temp.name;
              } else {
                this.productDetail[key] = temp.select[this.attributes[key].option_attr];
                //this.productDetail[key] = temp.select.name;
              }
              this.productDetail[key + '_id'] = temp.select_id;
            }
          }
        }
      }
    }
  }

  /**
   * prepares the edit screen for product detail page
   */
  edit() {
    this.editable = true;
    this.editableChange.emit(this.editable);
    this.selects['category'] = this.route.snapshot.data.fieldsDetails.data.category.filter(x => x.deleted_at == null);
    if ((this.selects['category'].find(i => i.id === this.productDetail.category_id)) !== undefined) {
      this.selects['type'] = this.route.snapshot.data.fieldsDetails.data.type.filter(
        type => type.deleted_at == null
          && this.selects['category'].find(i => i.id === this.productDetail.category_id).type_ids.indexOf(type.id) > -1
      ).sort((n1, n2) => {
        const n1value = this.selects['category'].find(i => i.id === this.productDetail.category_id).type_ids.indexOf(n1.id);
        const n2value = this.selects['category'].find(i => i.id === this.productDetail.category_id).type_ids.indexOf(n2.id);
        if (n1value > n2value) {
          return 1;
        }

        if (n1value < n2value) {
          return -1;
        }

        return 0;
      });
      if ((this.selects['type'].find(i => i.id === this.productDetail.type_id)) !== undefined) {
        this.selects['subcategory'] = this.route.snapshot.data.fieldsDetails.data.subcategory.filter(
          subcategory => subcategory.deleted_at == null
            && this.selects['type']
              .find(i => i.id === this.productDetail.type_id)
              .subcategory_ids
              .indexOf(subcategory.id) > -1
        ).sort((n1, n2) => {
          const n1value = this.selects['type'].find(i => i.id === this.productDetail.type_id).subcategory_ids.indexOf(n1.id);
          const n2value = this.selects['type'].find(i => i.id === this.productDetail.type_id).subcategory_ids.indexOf(n2.id);
          if (n1value > n2value) {
            return 1;
          }

          if (n1value < n2value) {
            return -1;
          }

          return 0;
        });
      } else {
        this.selects['subcategory'] = [];
      }
    } else {
      this.selects['type'] = [];
    }

    this.selects['year_of_manufacture'] = this.createSelect(null, null);
    this.selects['year_in_operation'] = this.createSelect(this.productDetail.year_of_manufacture, null);

    for (const key in this.attributes) {

      if (this.attributes[key]
        && (this.attributes[key].type === 'select' || this.attributes[key].type === 'select_direct')) {
        this.selects[key] = this.route.snapshot.data.fieldsDetails.data[key];
      } else if (this.attributes[key] && this.attributes[key].type === 'select_date') {

        this.selects[key] = this.createSelect(null, null);
      }

    }

  }

  /**
   * It changes the related fields loaded if a "parent" field is changed
   * It applies to 'category', 'type' and 'subcategory' fields
   * @param field
   * @param value
   */
  onChange(field, value) {

    switch (field) {
      case 'category':
        this.selects['type'] = this.route.snapshot.data.fieldsDetails.data.type.filter(
          type => type.deleted_at == null
            && this.selects['category'].find(i => i.id === this.productDetail.category_id).type_ids.indexOf(type.id) > -1
        );
        if (this.selects['type'][0]) {
          this.productDetail.type_id = this.selects['type'][0].id;
        }
        this.onChange('type', '');
        break;
      case 'type':
        this.selects['subcategory'] = this.subcategory_data.filter(
          subcategory => subcategory.deleted_at == null
            && this.selects['type']
              .find(i => i.id === this.productDetail.type_id).subcategory_ids
              .indexOf(subcategory.id) > -1
        );

        if (this.selects['subcategory'][0]) {
          this.productDetail.subcategory_id = this.selects['subcategory'][0].id;
        }

        this.onChange('subcategory', '');
        break;
      case 'subcategory':
        const extraFields = this.route.snapshot.data.extraFields.data.find(
          (x) => x.id === this.productDetail.subcategory_id
        );
        this.fields = this.generalFields.concat(extraFields ? extraFields.extrafields.map(x => x.field_name) : []);
        break;
      case 'year_of_manufacture':
        this.selects['year_in_operation'] = this.createSelect(this.productDetail.year_of_manufacture, null);

        break;
      default:
        const field_type = this.fieldDetails[field].type;
        switch (field_type) {
          case 'select_direct':
            this.productDetail[field] = this.selects[field].find((x) => x.id === Number(this.productDetail[field + '_id'])).name;
            break;
          case 'select':
            this.productDetail[field] = this.selects[field].find((x) => x.id === Number(this.productDetail[field + '_id'])).name;
            break;
          default:
            this.productDetail[field] = value;
            break;
        }
    }

  }


  get_dependent_attribute_data(attribute) {
    let dep_values = [];
    const dep_attribute = (attribute.dependent_to) ? (this.attributes_data.find(x => x.id === attribute.dependent_to)) : null;

    if (dep_attribute) {
      switch (dep_attribute.type) {
        case 1:
          dep_values = [attribute.dependent_value];
          break;
        default:
          const dependent_values = attribute.dependent_value.split(',').map(function(x) {
            return Number(x);
          });
          for (const dependent_value of dependent_values) {
            if (this.selects[dep_attribute.attribute_name]) {
              const dn = this.selects[dep_attribute.attribute_name].find(x => x.id == dependent_value);
              dep_values.push((attribute.dependent_value && this.selects[dep_attribute.attribute_name]) ?
                (dn ? dn.name : null) : null);
            }
          }
      }

    }

    return { dep_attribute_name: (dep_attribute) ? dep_attribute.attribute_name : null, dep_values: dep_values };
  }

  checkToShow(field_name) {
    if (typeof this.fieldDetails[field_name] === 'undefined') {
      return false;
    }
    if (this.fieldDetails[field_name].dependent_to && this.fieldDetails[this.fieldDetails[field_name].dependent_to].type == 'boolean') {
      return (!this.fieldDetails[field_name].dependent_to)
        || (this.fieldDetails[field_name].dependent_to
          && (
            this.fieldDetails[field_name].dependent_values.indexOf(
              (this.productDetail[this.fieldDetails[field_name].dependent_to] == true) ?
                '1' : '0') >= 0)
          || (this.productDetail[this.fieldDetails[field_name].dependent_to + '_id']
            && this.productDetail[this.fieldDetails[field_name].dependent_to + '_id'] == 1
            && this.fieldDetails[field_name].dependent_values.indexOf('other') >= 0
          )
        );
    }

    return (!this.fieldDetails[field_name].dependent_to)
      || (this.fieldDetails[field_name].dependent_to
        && (
          this.fieldDetails[field_name].dependent_values.indexOf(this.productDetail[this.fieldDetails[field_name].dependent_to]) >= 0
          || (this.productDetail[field_name] != null && this.productDetail[field_name] != undefined))
        || (this.productDetail[this.fieldDetails[field_name].dependent_to + '_id']
          && this.productDetail[this.fieldDetails[field_name].dependent_to + '_id'] == 1
          && this.fieldDetails[field_name].dependent_values.indexOf('other') >= 0
        )
      );
  }

  checkType(value) {
    if (value) {
      if (typeof value === 'object' && value instanceof Date) {
        return 'date';
      } else if (typeof value === 'string') {
        return 'string';
      }
    }
  }

  getFormattedDate(value) {
    if (value) {
      return value.toString().length === 4 ? value : this.datePipe.transform(value, 'yyyy-MM-dd');
    } else {
      return value;
    }
  }
}
