import {Component, OnInit, ViewChild} from '@angular/core';


import {ErrorEventService} from '../../services/error-event.service';
import {ErrorHandlerEvent} from '../../models/event';
import {NotifierService} from 'angular-notifier';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})

export class ErrorComponent implements OnInit {

    @ViewChild('notification', { static: true }) notificationTemplate;

    constructor (protected errorEventService: ErrorEventService, protected notifierService: NotifierService) {}

    /**
     * It shows notification messages if the API returns an error message
     */
    ngOnInit() {
        this.errorEventService.subscribeToEvent(ErrorHandlerEvent.applyErrors).subscribe((error) => {
            //console.log(error);
            status = error.status;
            error = error.error.error;
            //console.log(error);
                if (error.subject === 'WARNING') {
                    this.notifierService.show({
                        type: 'warning',
                        message: error.subject + '<br/>' + error.description,
                        template: this.notificationTemplate
                    });
                } else {
                    this.notifierService.show({
                        type: 'error',
                        message: error.subject + '<br/>' + error.description,
                        template: this.notificationTemplate
                    });
                }

            if (parseInt(status, 10) === 401 || parseInt(status, 10) === 403) {
                setTimeout(() => {
                    const locale = localStorage.getItem('current_locale');
                    localStorage.clear();
                    if (locale) {
                        localStorage.setItem('current_locale', locale);
                    }

                    window.location.reload();
                }, 1000);
            }
        });

        this.errorEventService.subscribeToEvent(ErrorHandlerEvent.removeErrors).subscribe((success) => {
            if (typeof success !== 'undefined') {
                const message_array = JSON.parse(success)['message'];
                if (typeof message_array !== 'undefined') {
                    this.notifierService.show({
                        type: 'success',
                        message: message_array.subject + '<br/>' + message_array.description,
                        template: this.notificationTemplate
                    });
                }

            }
        });
    }
}
