import {Component, Input, ViewEncapsulation} from '@angular/core';
import {GeneralService} from '../../../services/general.service';

@Component({
    selector: 'app-donwload-pdf-button',
    templateUrl: './donwload-pdf-button.component.html',
    styleUrls: [
        './download-pdf-button.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})

export class DownloadPdfButtonComponent {
    @Input() machine;

    constructor(protected machineService: GeneralService) {
    }

    downloadPdf() {
        window.open(this.machine.pdf_link);
    }
}
