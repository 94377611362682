import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { MkaApiRequest } from './mka-api-request.service';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  items: any;

  /**
   * Reject promise
   *
   * @param error
   * @returns {Promise<any>}
   */
  static handleError(error: any): Promise<any> {
    return Promise.reject(error.message);
  }

  constructor(protected mkaApiRequest: MkaApiRequest,
              @Inject('uri') @Optional() public uri?: string) {
    this.uri = uri;
  }

  /**
   * Get the uri
   *
   * @returns {string | undefined}
   */
  getUri() {
    return this.uri;
  }

  /**
   * Set the used uri
   * @param {string} uri
   */
  setUri(uri: string) {
    this.uri = uri;
  }

  /**
   * Call API to get all items
   *
   * @returns {Observable<any>}
   */
  getAll(): Observable<any> {
    return this.mkaApiRequest.getRequest(this.uri);
  }

  getBlob(): Observable<Blob> {
    return this.mkaApiRequest.getRequestBlob(this.uri).pipe(
      // Ensure that the response is of type Blob
      map((response: Blob) => {
        return response;  // Return the Blob response
      })
    );
  }


  /**
   * Call API to get the item
   *
   * @param {number} id
   * @returns {Observable<any>}
   */
  getItem(id: number): Observable<any> {
    return this.mkaApiRequest.getRequest(this.uri + '/' + id);
  }

    getItemsByIds(ids: number[] , body): Observable<any[]> {
        const requests = ids.map(id => this.getItemPost(id , body));
        return forkJoin(requests); // forkJoin waits for all requests to complete
    }

  getItemPost(id: number, data): Observable<any> {
    return this.mkaApiRequest.postRequest(this.uri + '/' + id, data);
  }

  /**
   * Call API to save the item
   *
   * @param item
   * @param fileBrowser
   * @returns {Observable<any>}
   */
  save(item, fileBrowser?): Observable<any> {
    if (typeof item.id === 'undefined' || item.id === 0) {
      return this.mkaApiRequest.postRequest(this.uri, item);
    } else {
      return this.mkaApiRequest.putRequest(this.uri + '/' + item.id, item);
    }
  }

  /**
   * Call API to delete the item
   *
   * @param {number} id
   * @returns {Observable<any>}
   */
  delete(id: number): Observable<any> {
    return this.mkaApiRequest.deleteRequest(this.uri + '/' + id);
  }

  // not used yet
  getAllPromise(): Promise<any> | any {
    /*if (this.items) {
        return this.items;
    }*/
    return this.mkaApiRequest.getRequest(this.uri)
      .toPromise()
      .then((data: Response) => {
        this.items = data;
        return this.items;
      })
      .catch(GeneralService.handleError);
  }

  /**
   * Call API to reorder the items
   *
   * @param items
   * @returns {Observable<any>}
   */
  public reOrder(items): Observable<any> {
    return this.mkaApiRequest.postRequest(this.uri + '/reorder', items);
  }

  /**
   * Get the list with pagination
   *
   * @param {number} page
   * @param {number} itemPerPage
   * @param {string} search
   * @param {string} currentSortBy
   * @returns {Observable<any>}
   */
  public paginator(page: number, itemPerPage: number, search: any, currentSortBy: string): Observable<any> {

    return this.mkaApiRequest.getRequest(this.uri, {
      page: page,
      per_page: itemPerPage,
      search: JSON.stringify(search),
      sort: currentSortBy
    });
  }

  public syncInternalAttributes() {
    return this.mkaApiRequest.postRequest(this.uri + '/sync_internal_attributes', 'all');
  }

  public syncAttributes() {
    return this.mkaApiRequest.postRequest(this.uri + '/sync_leads_attributes', 'all');
  }

  /**
   * Restore Deleted machine
   *
   * @param id
   * @returns {Observable<any>}
   */
  restoreMachine(id): Observable<any> {
    return this.mkaApiRequest.getRequest(`${this.uri}/${id}/restore`);
  }

  changeBid(bid_id: number, bid: any): Observable<any> {
    return this.mkaApiRequest.putRequest(`${this.uri}/${bid_id}`, bid);
  }
}

