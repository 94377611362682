import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MkaApiRequest } from './services/mka-api-request.service';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { AuthEventService } from './services/auth-event.service';
import { TableListComponent } from './components/table-list/table-list.component';
import { LoginActivate } from './access-guards/logged-in-guard';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { ProductFieldsComponent } from './components/product-fields/product-fields.component';
import { ErrorComponent } from './components/error/error.component';
import { MultiPipe } from './pipes/multi.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ProductImageComponent } from './components/product-images/product-images.component';
import { DetailPagingComponent } from './components/detail-paging/detail-paging.component';
import { MkaTagInputComponent } from './components/mka-tag-input/mka-tag-input.component';
import { TagInputModule } from 'ngx-chips';
import { DragulaModule } from 'ng2-dragula';
import { NgxCurrencyModule } from 'ngx-currency';
import { ImagePipe } from './pipes/image.pipe';
import { RatingComponent } from './components/rating/rating.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ExtendBidTimePopupComponent } from './components/extend-bid-time-popup/extend-bid-time-popup.component';
import { ExportTimePopupComponent } from './components/export-time-popup/export-time-popup.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SalesmanListingComponent } from './components/salesmen/salesman-listing/salesman-listing.component';
import { SalesmanDetailsComponent } from './components/salesmen/salesman-details/salesman-details.component';
import { TraderDetailsComponent } from './components/traders/trader-details/trader-details.component';
import { TraderListingComponent } from './components/traders/trader-listing/trader-listing.component';
import { GroupListingComponent } from './components/groups/group-listing/group-listing.component';
import { GroupDetailsComponent } from './components/groups/group-details/group-details.component';
import { AttributeDetailComponent } from './components/admin-section/attributes/attribute-detail.component';
import { AttributesComponent } from './components/admin-section/attributes/attributes.component';
import { CategoriesComponent } from './components/admin-section/categories/categories.component';
import { CategoryDetailComponent } from './components/admin-section/categories/category-detail.component';
import { ColumnDetailComponent } from './components/admin-section/columns/column-detail.component';
import { ColumnsComponent } from './components/admin-section/columns/columns.component';
import { ColumnsPlatformsComponent } from './components/admin-section/columns/columns-platforms.component';
import { CurrenciesComponent } from './components/admin-section/currencies/currencies.component';
import {
  SelectAttributeOptionDetailComponent
} from './components/admin-section/select-attribute-options/select-attribute-option-detail.component';
import { SelectAttributeOptionsComponent } from './components/admin-section/select-attribute-options/select-attribute-options.component';
import { SpecificationsComponent } from './components/admin-section/specifications/specifications.component';
import { SpecificationDetailComponent } from './components/admin-section/specifications/specification-detail.component';
import { SubcategoriesComponent } from './components/admin-section/subcategories/subcategories.component';
import { SubcategoryDetailComponent } from './components/admin-section/subcategories/subcategory-detail.component';
import { TranslationsComponent } from './components/admin-section/translations/translations.component';
import { TypeDetailComponent } from './components/admin-section/types/type-detail.component';
import { TypesComponent } from './components/admin-section/types/types.component';
import { CompanyComponent } from './components/company/company.component';
import { CompanyDetailComponent } from './components/company-detail/company-detail.component';
import { TranslationService } from './services/translation.service';
import { InternalSpecificationColumnsResolver } from './resolves/internal-specification-columns.resolver';
import { AvailableColumnsResolver } from './resolves/available-columns.resolver';
import { CompanyListResolver } from './resolves/company-list.resolver';
import { CompanyListService } from './services/company-list.service';
import { SharedBetweedDealersDetailsComponent } from './components/shared-between-dealers-details/shared-betweed-dealers-details.component';
import { MachineBidsListComponent } from './components/machine-bids-list/machine-bids-list.component';

import { TableFilterComponent } from './components/table-filter/table-filter.component';
// import { IonRangeSliderModule } from 'ng2-ion-range-slider';
import { Daterangepicker } from 'ng2-daterangepicker';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { DownloadPdfButtonComponent } from './components/pdf-buttons/download-pdf/download-pdf-button.component';
import { RegenaretePdfButtonComponent } from './components/pdf-buttons/regenerate-pdf/regenarete-pdf-button.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HttpClientModule } from '@angular/common/http';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserProfileResolver } from './resolves/user-profile.resolver';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { StandardPricesComponent } from './components/admin-section/standard-prices/standard-prices.component';
import { StandardPricesDetailComponent } from './components/admin-section/standard-prices/standard-prices-detail.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ChangeBidPopupComponentComponent } from './change-bid-popup-component/change-bid-popup-component.component';
import { MachineBidSectionComponent } from '../internal-platform-module/components/machines/machine-bid-section.component';
import { GuestListingComponent } from './components/salesmen/guest-listing/guest-listing.component';
import { CompareMachineDetailAdminComponent } from 'app/admin-platform-module/components/machines/compare-machine-admin-component';
import { ComparePricesTableMachineDetailComponent } from './components/abstract-classes/machines/compare-prices-table/compare-prices-table-prices-table-machine-detail.component';
import { ComparisonSetupComponent } from './components/abstract-classes/comparison-setup/comparison-setup.component';
import { CompareMachineDetailAdminSingleComponent } from 'app/admin-platform-module/components/machines/compare-machine-admin-component-single';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    TagInputModule,
    DragulaModule.forRoot(),
    NgxCurrencyModule,
    NgSelectModule,
    CarouselModule.forRoot(),
    TooltipModule,
    // IonRangeSliderModule,
    NgxSliderModule,
    Daterangepicker,
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    NgxPicaModule,
    NgxFileDropModule,
    NgSelectModule,
    BsDatepickerModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    WelcomeComponent,
    DisclaimerComponent,
    PrivacyComponent,
    TableListComponent,
    BreadcrumbComponent,
    HeaderMenuComponent,
    ProductFieldsComponent,
    ProductImageComponent,
    DetailPagingComponent,
    MultiPipe,
    ImagePipe,
    MkaTagInputComponent,
    RatingComponent,
    ContactFormComponent,
    ExtendBidTimePopupComponent,
    ExportTimePopupComponent,
    SalesmanListingComponent,
    GuestListingComponent,
    SalesmanDetailsComponent,
    TraderDetailsComponent,
    TraderListingComponent,
    GroupListingComponent,
    GroupDetailsComponent,
    AttributeDetailComponent,
    AttributesComponent,
    CategoriesComponent,
    CategoryDetailComponent,
    ColumnDetailComponent,
    ColumnsComponent,
    ColumnsPlatformsComponent,
    CurrenciesComponent,
    SelectAttributeOptionDetailComponent,
    SelectAttributeOptionsComponent,
    SpecificationsComponent,
    SpecificationDetailComponent,
    SubcategoriesComponent,
    SubcategoryDetailComponent,
    StandardPricesComponent,
    StandardPricesDetailComponent,
    TranslationsComponent,
    TypeDetailComponent,
    TypesComponent,
    CompanyComponent,
    CompanyDetailComponent,
    SharedBetweedDealersDetailsComponent,
    MachineBidSectionComponent,
    MachineBidsListComponent,
    TableFilterComponent,
    UnsubscribeComponent,
    DownloadPdfButtonComponent,
    RegenaretePdfButtonComponent,
    ChangeBidPopupComponentComponent,
    CompareMachineDetailAdminComponent,
    CompareMachineDetailAdminSingleComponent,
    ComparePricesTableMachineDetailComponent,
    ComparisonSetupComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    HeaderComponent,
    FooterComponent,
    TableListComponent,
    BreadcrumbComponent,
    ProductFieldsComponent,
    ProductImageComponent,
    DetailPagingComponent,
    HeaderMenuComponent,
    MkaTagInputComponent,
    WelcomeComponent,
    DisclaimerComponent,
    PrivacyComponent,
    ExtendBidTimePopupComponent,
    ExportTimePopupComponent,
    RatingComponent,
    MultiPipe,
    SalesmanListingComponent,
    GuestListingComponent,
    SalesmanDetailsComponent,
    TraderDetailsComponent,
    TraderListingComponent,
    GroupListingComponent,
    GroupDetailsComponent,
    AttributeDetailComponent,
    AttributesComponent,
    CategoriesComponent,
    CategoryDetailComponent,
    ColumnDetailComponent,
    ColumnsComponent,
    ColumnsPlatformsComponent,
    CurrenciesComponent,
    SelectAttributeOptionDetailComponent,
    SelectAttributeOptionsComponent,
    SpecificationsComponent,
    SpecificationDetailComponent,
    SubcategoriesComponent,
    SubcategoryDetailComponent,
    TranslationsComponent,
    TypeDetailComponent,
    TypesComponent,
    CompanyComponent,
    CompanyDetailComponent,
    SharedBetweedDealersDetailsComponent,
    MachineBidSectionComponent,
    MachineBidsListComponent,
    TableFilterComponent,
    DownloadPdfButtonComponent,
    RegenaretePdfButtonComponent,
    ChangeBidPopupComponentComponent,
    CompareMachineDetailAdminComponent,
    ComparePricesTableMachineDetailComponent,
    ComparisonSetupComponent,
    CompareMachineDetailAdminSingleComponent,
  ],
  providers: [
    AuthEventService,
    LoginActivate,
    TranslationService,
    InternalSpecificationColumnsResolver,
    AvailableColumnsResolver,
    CompanyListResolver,
    CompanyListService,
    HttpClientModule,
    UserProfileResolver
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // allow custom elements in ng-content (e.g <popup-body>)
  ]
})
export class SharedModule {
}
