import { Directive, OnInit } from '@angular/core';
import {SelectAttributeOption} from '../../../models/select-attribute-option';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {GeneralList} from '../../../helper-classes/general-list';
import {GeneralDetailEvent} from '../../../models/event';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';

@Directive()
export abstract class BaseSelectAttributeOptionsComponent extends GeneralList implements OnInit {

    title: string = 'api.selects';
    rows: SelectAttributeOption[];

    section: string = '';

    protected constructor(protected generalService: GeneralService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected generalDetailHandler: GeneralDetailEventService,
                protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
        super(generalService, route, router, generalDetailHandler);

        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterDelete).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );


    }

    ngOnInit() {

        this.route.params.subscribe(params => {
            this.generalService.setUri((this.section ? this.section + '_' : '') + 'selects/' + params['attribute_id']);
            const basePath = this.route.snapshot.data.basePath;
            this.actionLink = basePath +
                '/select-attribute-options/' + params['attribute_id'];

            // general initialization
            this.initialize();
        });


        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.attributes',
                url: this.platform + (this.section ? '/' + this.section : '') + '/attributes',
                isActive: false,
            },
            {
                name: 'api.selects',
                url: '',
                isActive: true,
            }];

        // specify the listing columns
        this.columns = [
            {
                name: 'id',
                formattedName: 'api.id'
            },
            {
                name: 'name',
                formattedName: 'api.value',
                pipes: ' '
            }
        ];
    }
}
