import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {GeneralService} from './general.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SalesmenService extends GeneralService {

    // extend the default getItem function to modify some attributes
    getItem(id: number): Observable<any> {
        return super.getItem(id).pipe(map(item => {
            const data = item.data;
            data.company_id = data.company.id;
            data.role = data.roles[0].id;
            return data;
        }));
    }

}
