import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ExtraFieldsService } from '../services/extra-fields.service';


/**
 * Resolver to get all Extrafields
 */
@Injectable({
    providedIn: 'root'
})
export class ExtraFieldsResolver implements Resolve<any> {
    constructor(
        private extraFieldsService: ExtraFieldsService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Promise<any>|any {
        return this.extraFieldsService.getAll();
    }
}
