<app-header></app-header>

<app-error></app-error>

<!--<a *ngIf="app.localStorageItem('auth_token') && app.localStorageItem('auth_token') !== 'undefined'" href="contact" id="feedback-button" class="button tiny radius" target="support">-->
<!--    {{'api.feedback' | translate}}-->
<!--</a>-->

<router-outlet *ngIf="translationLoaded"></router-outlet>

<app-footer></app-footer>
