import {
    Component, Input, Output, EventEmitter
} from '@angular/core';
import {MkaApiRequest} from '../../services/mka-api-request.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-extend-bid-time-popup',
    templateUrl: './extend-bid-time-popup.component.html',
    styleUrls: [
        './extend-bid-time-popup.component.scss'
    ],
})

export class ExtendBidTimePopupComponent {

    @Input() bidTime: any;
    @Input() modalId: string;
    @Input() bidId: number;
    @Output() continue = new EventEmitter<any>();

    constructor(protected mkaApiRequest: MkaApiRequest, protected route: ActivatedRoute) {
    }

    continueClicked() {
        this.continue.emit({bidTime: this.bidTime, bidId: this.bidId});
    }
}
