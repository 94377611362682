<div class="container">
  <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
  <div class="row">
    <div class="col-sm-6">
      <h3 class="sales-heading">{{item.id > 0 ? ('api.edit' | translate) : ('api.add' | translate)}} {{'api.standard_prices' | translate}}</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <form class="form-horizontal" #form (submit)="onSubmit()">
        <div class="form-group">
          <label class="col-sm-4">{{'api.model' | translate}}</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" id="model" name="model" placeholder="{{'api.model' | translate}}" [(ngModel)]="item.model">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-4">{{'api.battery_capacity' | translate}}</label>
          <div class="col-sm-8">
            <input type="number" class="form-control" id="battery_capacity" name="battery_capacity" placeholder="{{'api.battery_capacity' | translate}}" [(ngModel)]="item.battery_capacity">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-4">{{'api.older_than_x_years' | translate}}</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" id="older_than_x_years" name="older_than_x_years" placeholder="{{'api.older_than_x_years' | translate}}" [(ngModel)]="item.older_than_x_years">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-4">{{'api.running_hours_higher_than' | translate}}</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" id="running_hours_higher_than" name="running_hours_higher_than" placeholder="{{'api.running_hours_higher_than' | translate}}" [(ngModel)]="item.running_hours_higher_than">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-4">{{'api.standard_trade_in_price' | translate}}</label>
          <div class="col-sm-8">
            <input type="number" class="form-control" id="standard_trade_in_price" name="standard_trade_in_price" placeholder="{{'api.standard_trade_in_price' | translate}}" [(ngModel)]="item.standard_trade_in_price">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-4"></label>
          <div class="col-sm-8">
            <input type="submit" name="submit" title="" class="login-name buttons marginTop5" id="confirm" value="{{'api.confirm' | translate}}">
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
