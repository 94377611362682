<div class="container">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
    <div class="row">
        <div class="col-sm-6">
            <h3 class="sales-heading">{{item.id > 0 ? ('api.edit' | translate) : ('api.add' | translate)}} {{title | translate}}</h3>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <form class="form-horizontal" #form (submit)="onSubmit()">
                <div class="form-group">
                    <label class="col-sm-4">{{'api.type' | translate}}</label>
                    <div class="col-sm-8">
                        <select name="'type'" class="form-control" [(ngModel)]="item.type" (change)="attributeChanged()">
                            <option *ngFor="let option of types" [ngValue]="option.id">{{option.name | translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-4">{{'api.value' | translate}}</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="name" name="attribute_name" placeholder="{{'api.value' | translate}}" [(ngModel)]="item.attribute_name">
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4">{{'api.specification_type' | translate}}</label>
                    <div class="col-sm-8">
                        <select name="'specification_type'" class="form-control" [(ngModel)]="item.specification_type">
                            <option *ngFor="let option of specification_types" [ngValue]="option.id">{{option.name | translate}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4">{{'api.mandatory' | translate}}</label>
                    <div class="col-sm-8">
                        <select name="'type'" class="form-control" [(ngModel)]="item.mandatory" [ngModelOptions]="{standalone: true}">
                            <option [value]="0" [selected]="(item.mandatory === 0) ? 'selected' : ''">{{'api.false' | translate}}</option>
                            <option [value]="1" [selected]="(item.mandatory === 1) ? 'selected' : ''">{{'api.true' | translate}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4">{{'api.dependent_to' | translate}}</label>
                    <div class="col-sm-8">
                        <select [(ngModel)]="item.dependent_to" (change)="getAttributeValues(item.dependent_to)" name="'dependent_to'" class="form-control" >
                            <option value="">none</option>
                            <ng-container *ngFor="let option of attributes" >
                                    <option  *ngIf="( option.type == 1 || option.type == 2 || option.type == 3 ) && item.id !== option.id" [value]="option.id" [selected]="(item.dependent_to == option.id) ? 'selected' : ''">{{option.attribute_name}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>

                <div class="form-group" *ngIf="dependent_attribute_type != '' ">
                    <label class="col-sm-4">{{'api.dependent_to_value' | translate}}</label>
                    <div class="col-sm-8">

                        <select [(ngModel)]="item.dependent_value" name="'dependent_to_value'" multiple="true" class="form-control" >

                            <ng-container *ngIf="dependent_attribute_type == 'select_direct'" >
                                <option *ngFor="let option of attribute_options" [value]="option.id" [selected]="(item.dependent_value.indexOf(option.id) >= 0) ? 'selected' : ''">{{option.name | translate}}</option>
                            </ng-container>

                            <ng-container *ngIf="dependent_attribute_type == 'select'" >
                                <ng-container *ngFor="let option of attribute_options">
                                    <option [value]="option.id" [selected]="(item.dependent_value.indexOf(option.id) >= 0 ) ? 'selected' : ''">{{option.name | translate}}</option>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="dependent_attribute_type == 'checkbox'" >
                                <option [value]="0" [selected]="(item.dependent_value.indexOf(0) >= 0) ? 'selected' : ''">{{'api.false' | translate}}</option>
                                <option [value]="1" [selected]="(item.dependent_value.indexOf(1) >= 0) ? 'selected' : ''">{{'api.true' | translate}}</option>
                            </ng-container>

                        </select>
                    </div>
                </div>

                <!-- type select and select_with_other-->
                <div class="form-group" *ngIf="item.type == 2 || item.type == 3">
                    <label class="col-sm-4">{{'api.show_type' | translate}}</label>
                    <div class="col-sm-8">
                        <select name="'show_type'" class="form-control" [(ngModel)]="item.show_type">
                            <option *ngFor="let option of show_types" [value]="option.id" [selected]="(item.show_type == option.id) ? 'selected' : ''">{{option.name | translate}}</option>
                        </select>
                    </div>
                </div>

                <!-- type select and select_with_other-->
                <div class="form-group" *ngIf="item.type == 2 || item.type == 3">
                    <label class="col-sm-4">{{'api.translatable' | translate}}</label>
                    <div class="col-sm-8">
                        <input type="checkbox" [(ngModel)]="item.translatable" name="'translatable'"/>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4"></label>
                    <div class="col-sm-8">
                        <input type="submit" name="submit" title="" class="buttons marginTop5" id="confirm" value="{{'api.confirm' | translate}}">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
