import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'image'
})

/**
 * Custom pipe to show image
 */
export class ImagePipe implements PipeTransform {

    transform(value: any, width?: number): string {
        return '<img src="' + value + '" ' + (width ? 'width="' + width + '"' : '') + '/>';

    }
}
