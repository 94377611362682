import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {ErrorEventService} from '../../../services/error-event.service';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {BaseColumnDetailComponent} from '../../abstract-classes/columns/column-detail.component';

@Component({
    selector: 'app-attribute-option-detail',
    templateUrl: '../../abstract-classes/columns/column-detail.component.html',
    styleUrls: [
        '../../abstract-classes/columns/column-detail.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        GeneralService,
        {provide: 'uri',  useValue: 'columns'},
    ],
})

export class ColumnDetailComponent extends BaseColumnDetailComponent implements OnInit, OnDestroy {

    section: string = '';

    constructor(
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService) {
        super(errorEventService, loadingEventService, generalService, route, router, renderer, generalDetailHandler, fieldsdetailsService);
    }

}
