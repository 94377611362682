import { Component, OnInit } from '@angular/core';
import {Attribute} from '../../../models/attribute';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {BaseAttributesComponent} from '../../abstract-classes/attributes/attributes.component';

@Component({
    selector: 'app-attributes',
    templateUrl: '../../abstract-classes/attributes/attributes.component.html',
    styleUrls: [
        '../../abstract-classes/attributes/attributes.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        GeneralService,
        {provide: 'uri',  useValue: 'attributes'},
    ],
})

export class AttributesComponent extends BaseAttributesComponent implements OnInit {

    section: string = '';

    constructor(protected generalService: GeneralService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected generalDetailHandler: GeneralDetailEventService,
                protected fieldsdetailsService: FieldsdetailsService) {
        super(generalService, route, router, generalDetailHandler, fieldsdetailsService);
    }
}
