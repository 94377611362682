import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralFields } from '../../../shared/helper-classes/general-fields';
import { GeneralService } from '../../../shared/services/general.service';
import { TranslatePipe } from '@ngx-translate/core';
import { Machine } from '../../models/machine';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-machine-bid-section',
  templateUrl: './machine-bid-section.component.html',
  styleUrls: ['./machine-bid-section.component.scss'],

  // create a separate instances of the GeneralService which will use the below uri
  providers: [
    { provide: 'instance1', useClass: GeneralService },
    { provide: 'instance2', useClass: GeneralService },
    { provide: 'instance3', useClass: GeneralService },
    { provide: 'instance4', useClass: GeneralService },
    { provide: 'instance5', useClass: GeneralService },
    { provide: 'instance6', useClass: GeneralService },
    { provide: 'uri', useValue: 'machine_bids' },
    TranslatePipe
  ]
})

export class MachineBidSectionComponent implements OnInit, OnChanges {
  @Input() productDetail: Machine;
  @Input() editable: boolean = false;
  @Output() anyTraderBidAcceptedChanged = new EventEmitter();
  @Output() productDetailChanged = new EventEmitter();
  salesmanBids: any;
  traderBids: any;
  sentSalesPrices: any;
  currencies: string[];
  incomingStatuses: any = {};
  outgoingStatuses: any = {};
  bidStatuses: any = {};
  fixedBidStatusIds: any = [];
  sentSalesPriceStatuses: any = {};
  group: any = GeneralFields.GROUP;
  currentCurrency = localStorage.currentCurrency;
  currency_exchanges: any;
  showAllSalesBids: boolean = false;
  showAllTraderBids: boolean = false;
  showSentSalesPrices: boolean = false;
  anySalesmanBidAccepted: boolean = false;
  anySalesmanBecameWinner: boolean = false;
  anyTraderBidAccepted: boolean = false;
  anyTraderBidPending: boolean = false;
  isDealersEnabled: boolean = false;
  isSharedBetweenDealers: boolean = false;
  isSharedBetweenTraders: boolean = false;
  currentMachineId: number;
  showWholesalesPrice: boolean = false;
  role: any = null;

  // create a separate instances of the GeneralService
  constructor(
    @Inject('instance1') private machineBidService: GeneralService,
    @Inject('instance2') private bookValueService: GeneralService,
    @Inject('instance3') private bidService: GeneralService,
    @Inject('instance4') private updatePriceService: GeneralService,
    @Inject('instance5') private markMachineSharedService: GeneralService,
    @Inject('instance6') private machineService: GeneralService,
    private route: ActivatedRoute,
    protected router: Router,
    private translatePipe: TranslatePipe) {

    for (const status of this.route.snapshot.data.fieldsDetails.data.bid_statuses) {
      this.bidStatuses[status.name] = status;
    }

    this.fixedBidStatusIds = [
      this.bidStatuses.bid_status_accepted.id,
      this.bidStatuses.bid_status_rejected.id,
      this.bidStatuses.bid_status_pending.id
    ];

    for (const status of this.route.snapshot.data.fieldsDetails.data.sent_sales_price_statuses) {
      this.sentSalesPriceStatuses[status.name] = status;
    }

    for (const status of this.route.snapshot.data.fieldsDetails.data.machine_incoming_statuses) {
      this.incomingStatuses[status.name] = status;
    }

    for (const status of this.route.snapshot.data.fieldsDetails.data.machine_outgoing_statuses) {
      this.outgoingStatuses[status.name] = status;
    }

    this.role = JSON.parse(localStorage.getItem('current_user')).roles[0];

  }

  ngOnInit() {
    this.showWholesalesPrice = environment.showWholesalesPrice;
    // set currency_exchanges received from the FieldsDetailsResolver
    this.currencies = this.route.snapshot.data.fieldsDetails.data.currency;
    this.currentMachineId = this.productDetail.id;

    // set currency_exchanges received from the FieldsDetailsResolver
    this.currency_exchanges = this.route.snapshot.data.fieldsDetails.data.currency_exchange;

    this.getBidsFromAPI();

    this.isDealersEnabled = environment.has_admin;
    this.isSharedBetweenDealers = !!this.productDetail.is_shared_between_dealers_by;
    this.isSharedBetweenTraders = !!this.productDetail.is_shared_between_traders_by;

    this.productDetail.selling_price_currency = !this.productDetail.selling_price_currency ? this.currentCurrency : this.productDetail.selling_price_currency;
    this.productDetail.sales_price_currency = !this.productDetail.sales_price_currency ? this.currentCurrency : this.productDetail.sales_price_currency;
    this.productDetail.revision_cost_currency = !this.productDetail.revision_cost_currency ? this.currentCurrency : this.productDetail.revision_cost_currency;
    this.productDetail.transport_cost_currency = !this.productDetail.transport_cost_currency ? this.currentCurrency : this.productDetail.transport_cost_currency;
    this.productDetail.book_value_currency = !this.productDetail.book_value_currency ? this.currentCurrency : this.productDetail.book_value_currency;
    this.productDetail.commercial_trade_in_value_currency = !this.productDetail.commercial_trade_in_value_currency ? this.currentCurrency : this.productDetail.commercial_trade_in_value_currency;
    this.productDetail.wholesales_price_currency = !this.productDetail.wholesales_price_currency ? this.currentCurrency : this.productDetail.wholesales_price_currency;
  }

  ngOnChanges() {
    if (parseInt(this.route.snapshot.paramMap.get('id')) !== this.currentMachineId) {
      this.getBidsFromAPI();
      this.currentMachineId = this.productDetail.id;
      this.isSharedBetweenDealers = !!this.productDetail.is_shared_between_dealers_by;
      this.isSharedBetweenTraders = !!this.productDetail.is_shared_between_traders_by;
    }
  }

  getBidsFromAPI() {
    this.machineBidService.setUri('machine_bids');
    this.machineBidService.getItem(this.productDetail.id).subscribe(
      data => {
        const response = data.data;
        this.refreshBids(response);
      }
    );
  }

  // save the book value
  saveBookValue() {
    // create the item which is sent to API
    const bookValueDetail = {
      id: this.productDetail.id,
      book_value: this.productDetail.book_value,
      book_value_currency: this.productDetail.book_value_currency
    };

    // save the book value (to API)
    this.bookValueService.setUri('book_value');
    this.bookValueService.save(bookValueDetail).subscribe(
      data => {
        this.productDetail = data.data;
        this.productDetail.book_value_available = true;
        this.productDetailChanged.emit(this.productDetail);
      }
    );
  }

  delete() {
    const c = confirm(this.translatePipe.transform('message.are_you_sure_to_delete'));
    if (c === true) {
      this.machineService.setUri('machines');
      this.machineService.delete(this.productDetail.id).subscribe(
        () => {
          this.router.navigate(['/internal-platform/machines']);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  saveTransportCost() {
    const transportCostDetail = {
      id: this.productDetail.id,
      transport_cost: this.productDetail.transport_cost,
      transport_cost_currency: this.productDetail.transport_cost_currency
    };
    this.productDetail.transport_cost_available = (!!this.productDetail.transport_cost && !!this.productDetail.transport_cost_currency) || this.productDetail.transport_cost_available;

    // save the transportCost (to API)
    this.updatePriceService.setUri('transport_cost');
    this.updatePriceService.save(transportCostDetail);
  }

  saveRevisionCost() {
    const revisionCostDetail = {
      id: this.productDetail.id,
      revision_cost: this.productDetail.revision_cost,
      revision_cost_currency: this.productDetail.revision_cost_currency
    };
    this.productDetail.revision_cost_available = (!!this.productDetail.revision_cost && !!this.productDetail.revision_cost_currency) || this.productDetail.revision_cost_available;

    // save the revisionCost (to API)
    this.updatePriceService.setUri('revision_cost');
    this.updatePriceService.save(revisionCostDetail);
  }

  saveSalesPrice() {
    const salesPriceDetail = {
      id: this.productDetail.id,
      sales_price: this.productDetail.sales_price,
      sales_price_currency: this.productDetail.sales_price_currency
    };
    this.productDetail.sales_price_available = (!!this.productDetail.sales_price && !!this.productDetail.sales_price_currency) || this.productDetail.sales_price_available;

    // save the revisionCost (to API)
    this.updatePriceService.setUri('sales_price');
    this.updatePriceService.save(salesPriceDetail);
  }

  saveSellingPrice() {
    const sellingPriceDetail = {
      id: this.productDetail.id,
      selling_price: this.productDetail.selling_price,
      selling_price_currency: this.productDetail.selling_price_currency
    };
    this.productDetail.selling_price_available = (!!this.productDetail.selling_price && !!this.productDetail.selling_price_currency) || this.productDetail.selling_price_available;

    // save the revisionCost (to API)
    this.updatePriceService.setUri('selling_price');
    this.updatePriceService.save(sellingPriceDetail);
  }

  saveWholesalesPrice() {
    const wholesalesPriceDetail = {
      id: this.productDetail.id,
      wholesales_price: this.productDetail.wholesales_price,
      wholesales_price_currency: this.productDetail.wholesales_price_currency
    };
    this.productDetail.wholesales_price_available = (!!this.productDetail.wholesales_price && !!this.productDetail.wholesales_price_currency) || this.productDetail.wholesales_price_available;

    // save the revisionCost (to API)
    this.updatePriceService.setUri('wholesales_price');
    this.updatePriceService.save(wholesalesPriceDetail);
  }

  saveUsedManagerRemarks() {
    const usedManagerRemarksDetail = {
      id: this.productDetail.id,
      used_manager_remarks: this.productDetail.used_manager_remarks
    };

    // save the used_manager_remarks
    this.updatePriceService.setUri('used_manager_remarks');
    this.updatePriceService.save(usedManagerRemarksDetail);
  }

  // accept the Bid
  acceptBid(acceptEmail: any) {
    // create the item which is sent to API
    const item = {
      id: acceptEmail.bidId,
      emailTemplate: acceptEmail.emailTemplate
    };

    // accept the bid (to API)
    this.bidService.setUri('accept_bid');
    this.bidService.save(item).subscribe(
      data => {
        const response = data.data;
        this.refreshBids(response);
        this.productDetail = response.machine;
        this.productDetailChanged.emit(this.productDetail);
      }
    );
  }

  extendBidTime(bid: any) {
    const item = {
      id: bid.bidId,
      bid_time: bid.bidTime
    };

    // accept the bid (to API)
    this.bidService.setUri('extend_bid_time');
    this.bidService.save(item).subscribe(
      data => {
        const response = data.data;
        this.refreshBids(response);
      }
    );
  }

  markWinner(sentSalesPriceId: any) {
    // create the item which is sent to API
    const item = {
      id: sentSalesPriceId
    };

    // accept the bid (to API)
    this.bidService.setUri('mark_winner');
    this.bidService.save(item).subscribe(
      data => {
        const response = data.data;
        this.refreshBids(response);
        this.productDetail = response.machine;
        this.productDetailChanged.emit(this.productDetail);
      }
    );
  }

  // reject the Bid
  rejectBid(rejectEmail: any) {
    // create the item which is sent to API
    const item = {
      id: rejectEmail.bidId,
      emailTemplate: rejectEmail.emailTemplate
    };

    // accept the bid (to API)
    this.bidService.setUri('reject_bid');
    this.bidService.save(item).subscribe(
      data => {
        const response = data.data;
        this.refreshBids(response);
        this.productDetail = response.machine;
        this.productDetailChanged.emit(this.productDetail);
      }
    );
  }

  // convert a price to user's currency
  public convertCurrency(bid) {
    if (this.fixedBidStatusIds.indexOf(bid.status) !== -1) {
      return bid.fixed_values.find(x => x.value_currency === localStorage.currentCurrency).value;
    }
    const fromCurrency = this.currency_exchanges.find(x => x.currency === bid.value_currency);
    const toCurrency = this.currency_exchanges.find(x => x.currency === this.currentCurrency);
    if (typeof toCurrency === 'undefined' || typeof fromCurrency === 'undefined') {
      return bid.value;
    }
    return Math.round(bid.value * toCurrency.value / fromCurrency.value * 100) / 100;
  }

  // helper function to sort the bids
  private sort(toSort) {
    return toSort.sort((n1, n2) => {
      const n1value = this.convertCurrency(n1);
      const n2value = this.convertCurrency(n2);
      if (n1value > n2value) {
        return -1;
      }

      if (n1value < n2value) {
        return 1;
      }

      return 0;
    });
  }

  // toggle to show more sales bids
  public showAllSalesBidsToggle() {
    this.showAllSalesBids = !this.showAllSalesBids;
    return this.showAllSalesBids;
  }

  // toggle to show more sales bids
  public showSentSalesPricesToggle() {
    this.showSentSalesPrices = !this.showSentSalesPrices;
    return this.showSentSalesPrices;
  }

  // toggle to show more trader bids
  public showAllTraderBidsToggle() {
    this.showAllTraderBids = !this.showAllTraderBids;
    return this.showAllTraderBids;
  }

  public checkAnySalesmanBidAccepted() {
    this.anySalesmanBidAccepted = this.salesmanBids.some(x => x.status === this.bidStatuses.bid_status_accepted.id);
  }

  public checkAnySalesmanBecameWinner() {
    this.anySalesmanBecameWinner = this.sentSalesPrices.some(x => x.status === this.sentSalesPriceStatuses.status_won.id);
  }

  public checkAnyTraderBidAccepted() {
    this.anyTraderBidAccepted = this.traderBids.some(
      x => x.status === this.bidStatuses.bid_status_accepted.id
        || x.status === this.bidStatuses.bid_status_pending.id
    );
    this.anyTraderBidPending = this.traderBids.some(
      x => x.status === this.bidStatuses.bid_status_pending.id
    );

    this.anyTraderBidAcceptedChanged.emit(this.anyTraderBidAccepted);

  }

  public refreshBids(response) {
    this.salesmanBids = this.sort(response.salesman_bids);
    this.checkAnySalesmanBidAccepted();
    this.traderBids = this.sort(response.trader_bids);
    this.checkAnyTraderBidAccepted();
    if (typeof response.sent_sales_prices !== 'undefined') {
      this.sentSalesPrices = this.sort(response.sent_sales_prices);
      this.checkAnySalesmanBecameWinner();
    }
  }

  public markSharedBetweenDealers() {
    this.markMachineSharedService.setUri('machine/mark_delaer_sharing');
    this.markMachineSharedService.save({
      id: this.productDetail.id,
      shared: this.isSharedBetweenDealers
    }).subscribe(
      data => {
        const response = data.data;
      }
    );
  }

  public markSharedBetweenTraders() {
    this.markMachineSharedService.setUri('machine/mark_trader_sharing');
    this.markMachineSharedService.save({
      id: this.productDetail.id,
      shared: this.isSharedBetweenTraders
    }).subscribe(
      data => {
        const response = data.data;
      }
    );
  }

  public refreshUpdatedBids($event: any) {
    this.getBidsFromAPI();
  }
}
