import { Component, OnChanges, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HeaderMenuItem } from '../../models/header-menu-item';
import { environment } from '../../../../environments/environment';
import { TransApiService } from '../../services/trans-api.service';
import { AuthEventService } from '../../services/auth-event.service';
import { AuthEvent } from '../../models/event';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  @Output() headerMenus: HeaderMenuItem[];
  public platform;
  public withoutHeaderImage: boolean = false;
  public withBannerLogo: boolean = false;
  public logoImage = environment.toro_logo;
  public bannerLogo = environment.toro_banner_logo;
  public bannerSlides = environment.toro_slides;
  public slides = [];
  public leads_platform = environment.leads_platform;
  public leads_menu = [];
  public showSeparatorLine: boolean = true;

  public languages = [];
  public flags = {};
  public current_locale = '';

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private transApiService: TransApiService,
              private authEventService: AuthEventService,
              private router: Router) {
    this.authEventService.subscribeToEvent(AuthEvent.AuthenticationState).subscribe(data => {
      this.current_locale = this.transApiService.getCurrentLocale();
    });
  }

  /**
   * This constructs the header and if the user is logged in it also constructs the menu that is listed for each platform
   */
  ngOnInit() {
    this.languages = this.transApiService.getLocaleList();
    this.flags = TransApiService.FLAG_MAPPING;
    this.current_locale = this.transApiService.getCurrentLocale();
    if (environment.enablePlatformSelect === true) {
      this.showSeparatorLine = false;
    }

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((event: NavigationEnd) => {
        this.withoutHeaderImage = !!event['withoutHeaderImage'];

        if (this.router.url === '/welcome') {
          this.slides = this.bannerSlides.split(',');
        } else {
          this.slides = [this.bannerSlides.split(',')[0]];
        }
        this.platform = this.router.url.split('/')[1];
        this.headerMenus = [];
        if (!this.authService.getIsLoggedIn()) {
          // no header if logged out
          return;
        }
        // header for sale platform

        if (this.platform === 'sales-platform') {
          this.headerMenus = [
            // {
            //   name: 'api.bidding_overview',
            //   url: this.platform + '/bidding-overview',
            //   hide: true,
            //   children: []
            // },
            {
              name: 'api.my_machines',
              url: this.platform + '/my-machines',
              children: []
            },
            {
              name: 'api.create_machine',
              url: this.platform + '/create-machine',
              children: []
            },
            {
              name: 'api.other_machines',
              url: this.platform + '/general-create-machine',
              children: [],
              // hide: !environment.has_admin || !environment.show_shared_between_dealerships_tab_for_sales,
              hide: true
            }
          ];
        }

        // header for internal platform
        if (this.platform === 'internal-platform') {
          this.headerMenus = [
            {
              name: 'api.admin_section',
              url: '',
              hide: environment.has_admin,
              children: [
                {
                  name: 'api.categories',
                  url: this.platform + '/categories',
                  children: []
                },
                {
                  name: 'api.types',
                  url: this.platform + '/types',
                  children: []
                },
                {
                  name: 'api.subcategories',
                  url: this.platform + '/subcategories',
                  children: []
                },
                {
                  name: 'api.currency',
                  url: this.platform + '/currencies',
                  children: []
                },

                {
                  name: 'api.translations',
                  url: this.platform + '/translations',
                  children: []
                },
                {
                  name: 'api.specifications',
                  url: this.platform + '/specifications',
                  children: []
                },
                {
                  name: 'api.attributes',
                  url: this.platform + '/attributes',
                  children: []
                },
                {
                  name: 'api.columns_platforms',
                  url: this.platform + '/columns_platforms',
                  children: []
                }
              ]
            },
            {
              name: 'api.create_machine_section',
              url: this.platform + '/create-machine',
              children: []
            },
            {
              name: 'api.content_management',
              url: this.platform + '/machines',
              children: []
            },
            {
              name: 'api.leads_machines',
              url: this.platform + '/leads/machines',
              children: [],
              hide: !environment.leads_platform
            },
            {
              name: 'api.shared_between_dealerships',
              url: this.platform + '/machines-shared',
              children: [],
              // hide: !environment.has_admin,
              hide: true
            },
            // disabled for pon
            // {
            //   name: 'api.all_machines',
            //   url: this.platform + '/all-machines',
            //   children: [],
            //   hide: !environment.has_admin || (this.authService.getDealerSetting('app_show_all_machines_for_internal') === 0)
            // },
            {
              name: 'api.bidding_overview',
              url: this.platform + '/bidding-overview',
              children: [],
              // hide: !environment.has_admin,
              hide: true
            },
            {
              name: 'api.sales_management',
              url: '',
              hide: environment.has_admin,
              children: [
                {
                  name: 'api.add_sales',
                  url: this.platform + '/salesmen/add',
                  children: []
                },
                {
                  name: 'api.modify_sales',
                  url: this.platform + '/salesmen',
                  children: []
                },
                {
                  name: 'api.add_sales_mailing_list',
                  url: this.platform + '/groups/salesman/add',
                  children: []
                },
                {
                  name: 'api.modify_sales_mailing_list',
                  url: this.platform + '/salesmen',
                  fragment: 'mailing_list',
                  children: []
                },
                {
                  name: 'api.companies',
                  url: this.platform + '/companies',
                  children: []
                }
              ]
            },
            {
              name: 'api.trader_management',
              url: '',
              // hide: environment.has_admin,
              children: [
                {
                  name: 'api.add_trader',
                  url: this.platform + '/traders/add',
                  children: []
                },
                {
                  name: 'api.modify_trader',
                  url: this.platform + '/traders',
                  children: []
                },
                {
                  name: 'api.add_trader_mailing_list',
                  url: this.platform + '/groups/trader/add',
                  children: []
                },
                {
                  name: 'api.modify_trader_mailing_list',
                  url: this.platform + '/traders',
                  fragment: 'mailing_list',
                  children: []
                }
              ]
            }
          ];

        }
        // header for sale platform
        if (this.platform === 'trader-platform') {
          this.headerMenus = [
            {
              name: 'api.dealer_inventory',
              url: this.platform + '/dealer-inventory',
              children: [],
              hide: !this.authService.getTraderData('has_dealer_inventory')
            },
            {
              name: 'api.bidding_overview',
              url: this.platform + '/bidding-overview',
              children: []
            },
            {
              name: 'api.my_machines',
              url: this.platform + '/my-machines',
              children: [],
              hide: !environment.tradersHaveOwnMachines
            },
            {
              name: 'api.account_management',
              url: this.platform + '/profile',
              children: []
            },
          ];
        }

        // header for sale platform
        if (this.platform === 'guest-platform') {
          this.headerMenus = [
            {
              name: 'api.machines',
              url: this.platform + '/machines',
              children: [],
            }
          ];
        }

        if (this.platform === 'admin-platform') {
          this.headerMenus = [
            {
              name: 'api.admin_section',
              url: '',
              children: [
                {
                  name: 'api.categories',
                  url: this.platform + '/categories',
                  children: []
                },
                {
                  name: 'api.types',
                  url: this.platform + '/types',
                  children: []
                },
                {
                  name: 'api.subcategories',
                  url: this.platform + '/subcategories',
                  children: []
                },
                {
                  name: 'api.currency',
                  url: this.platform + '/currencies',
                  children: []
                },
                {
                  name: 'api.translations',
                  url: this.platform + '/translations',
                  children: []
                },
                {
                  name: 'api.specifications',
                  url: this.platform + '/specifications',
                  children: []
                },
                {
                  name: 'api.attributes',
                  url: this.platform + '/attributes',
                  children: []
                },
                {
                  name: 'api.columns_platforms',
                  url: this.platform + '/columns_platforms',
                  children: []
                },
                {
                  name: 'api.comparison_setup',
                  url: this.platform + '/comparison_setup',
                  children: []
                },
                {
                  name: 'api.standard_prices',
                  url: this.platform + '/standard-prices',
                  children: []
                },
                {
                  name: 'api.power_bi_report',
                  url: this.platform + '/power-bi',
                  children: []
                }
              ]
            },
            {
              name: 'api.machines',
              url: this.platform + '/machines',
              children: []
            },
            {
              name: 'api.admins',
              url: '',
              children: [
                {
                  name: 'api.add_admin',
                  url: this.platform + '/admins/add',
                  children: []
                },
                {
                  name: 'api.modify_admin',
                  url: this.platform + '/admins',
                  children: []
                }
              ]
            },
            {
              name: 'api.dealers',
              url: '',
              children: [
                {
                  name: 'api.add_dealer',
                  url: this.platform + '/dealers/add',
                  children: []
                },
                {
                  name: 'api.modify_dealer',
                  url: this.platform + '/dealers',
                  children: []
                }
              ]
            },
            {
              name: 'api.sales_management',
              url: '',
              children: [
                {
                  name: 'api.add_sales',
                  url: this.platform + '/salesmen/add',
                  children: []
                },
                {
                  name: 'api.modify_sales',
                  url: this.platform + '/salesmen',
                  children: []
                },
                {
                  name: 'api.add_sales_mailing_list',
                  url: this.platform + '/groups/salesman/add',
                  children: []
                },
                {
                  name: 'api.modify_sales_mailing_list',
                  url: this.platform + '/salesmen',
                  fragment: 'mailing_list',
                  children: []
                },
                {
                  name: 'api.companies',
                  url: this.platform + '/companies',
                  children: []
                }
              ]
            },
            {
              name: 'api.trader_management',
              url: '',
              children: [
                {
                  name: 'api.add_trader',
                  url: this.platform + '/traders/add',
                  children: []
                },
                {
                  name: 'api.modify_trader',
                  url: this.platform + '/traders',
                  children: []
                },
                {
                  name: 'api.add_trader_mailing_list',
                  url: this.platform + '/groups/trader/add',
                  children: []
                },
                {
                  name: 'api.modify_trader_mailing_list',
                  url: this.platform + '/traders',
                  fragment: 'mailing_list',
                  children: []
                }
              ]
            }
          ];
        }


        if (this.leads_platform === true && this.platform === 'internal-platform') {

          this.leads_menu = [{
            name: 'Leads',
            url: '',
            children: [
              {
                name: 'api.categories',
                url: this.platform + '/leads/categories',
                children: []
              },
              {
                name: 'api.types',
                url: this.platform + '/leads/types',
                children: []
              },
              {
                name: 'api.subcategories',
                url: this.platform + '/leads/subcategories',
                children: []
              },
              {
                name: 'api.attributes',
                url: this.platform + '/leads/attributes',
                children: []
              },
              {
                name: 'api.columns_platforms',
                url: this.platform + '/leads/columns_platforms',
                children: []
              }
            ]
          }];

          this.headerMenus = this.headerMenus.concat(this.leads_menu);
        }

      });


  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  changeLocale(locale) {
    this.transApiService.changeLocale(locale);
    window.location.reload();
  }
}
