import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../../shared/services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {GeneralService} from '../../../shared/services/general.service';
import {GroupSelectionService} from '../../../internal-platform-module/services/group-selection.service';
import {ErrorEventService} from '../../../shared/services/error-event.service';
import {LoadingEventService} from '../../../shared/services/loading-event.service';
import {GeneralDetail} from '../../../shared/helper-classes/general-detail';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-reset-password',
    templateUrl: 'reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent extends GeneralDetail implements OnInit, OnDestroy {

    public credentials: any = {
        token: '',
        password: '',
        password_confirmation: '',
    };
    private sub: any;
    public bannerLogo = environment.toro_logo;
    /**
     * @param {AuthService} authService
     * @param errorEventService
     * @param loadingEventService
     * @param generalService
     * @param {ActivatedRoute} route
     * @param {Router} router
     * @param renderer
     * @param groupSelectionService
     */
    constructor(
        private authService: AuthService,
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected groupSelectionService: GroupSelectionService) {
        super(generalService, router, route, errorEventService, loadingEventService, renderer);
        super.parentInitialize();
    }

    /**
     * makes the reset password API call
     * navigates to the the path after login for different interfaces
     */
    resetPassword() {
        this.authService.sendResetPassword(this.credentials).subscribe(
            () => {
                this.router.navigate(['/login']);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * interprets the parameters
     * sets token, sets credential token and redirect url
     */
    ngOnInit() {
        this.sub = this.route.params.subscribe( params => {
            this.credentials.token = params['token'];
        });
    }

    /**
    * unsubscribes route loading event
    */
    ngOnDestroy() {
        super.ngOnDestroy();
        this.sub.unsubscribe();
    }
}
