<div class="container text-center padding10x0" #form>
    <div class="container login-bg">
        <img class="reesink-logo" src={{bannerLogo}}>
    </div>
    <h3 class="marginTop15 marginBottom20">{{'api.forgot_your_password' | translate}}</h3>
    <p>{{'api.type_your_email_below_to_receive_your_password' | translate}}</p>

    <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
            <div class="col-sm-12 reset-background">
                <i class="fa fa-user fontSize150" aria-hidden="true"></i>
                <form method="post">
                    <div class="login-form">
                        <div class="form-group marginBottom0">
                            <input [(ngModel)]="credentials.email" name="email" type="text" title="Username or Email" value=""
                                   class="form-control login-field" id="login" placeholder="{{'api.type_your_email' | translate}}"
                                   required="required">
                            <label class="" for="login"></label>
                        </div>
                        <p><input (click)="forgotPassword()"  type="submit" name="password_reset_submit" title="" value="{{'api.send_password' | translate}}"
                                  id="submit-button" class="btn btn-primary btn-lg btn-block reset-btn"></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
