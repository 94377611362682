<div class="row" *ngIf="role !== 'salesman'">
  <div class="col-6">
    <span>{{'api.outgoing_status' | translate}}:</span>
  </div>
  <div class="col-6">
    <label *ngIf="outgoingStatusList[machine.outgoing_status]">{{'api.' +
    outgoingStatusList[machine.outgoing_status] | translate}}</label>
  </div>
</div>

<div class="row" *ngIf="role !== 'salesman'">
  <div class="col-6">
    <span>{{'api.incoming_status' | translate}}:</span>
  </div>
  <div class="col-6">
    <label *ngIf="incomingStatusList[machine.incoming_status]">{{'api.' +
    incomingStatusList[machine.incoming_status] | translate}}</label>
  </div>
</div>


<div class="row paddingBottom10" *ngIf="role == 'salesman'">
  <div class="col-6">
    <h5>{{'api.incoming_status' | translate}}:</h5>
  </div>
  <div class="col-6">
    <select class="form-control input-style" [(ngModel)]="selectedIncommingStatus" (change)="incommingStatusOnChange()">
      <option [ngValue]="null">{{'api.select_incoming_status' | translate}}</option>
      <option *ngFor="let item of filteredIncommingStatusList" [ngValue]="item.id">{{'api.' + item.name | translate}}
    </select>
  </div>
</div>

<div class="row paddingBottom10" *ngIf="machine.sales_price">
  <div class="col-6">
    <span>{{'api.sales_price' | translate}}:</span>
  </div>
  <div class="col-6">
    <label>{{machine.sales_price | multipipe:'currency:' + machine.sales_price_currency + ':symbol:1.2-4'}}</label>
  </div>
</div>

<div class="row paddingBottom10" *ngIf="role === 'guest'">
  <div class="col-6">
    <span>{{'api.book_value' | translate}}:</span>
  </div>
  <div class="col-6">
    <label>{{machine.book_value | multipipe:'currency:' + machine.book_value + ':symbol:1.2-4'}}</label>
  </div>
</div>
