import { Directive, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import {Category} from '../../../models/category';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoryService} from '../../../services/category.service';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralDetail} from '../../../helper-classes/general-detail';
import {GeneralService} from '../../../services/general.service';

import {DragulaService} from 'ng2-dragula';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {GeneralDetailEvent} from '../../../models/event';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';

@Directive()
export abstract class BaseCategoryDetailComponent extends GeneralDetail implements OnInit {

    item: Category;
    @ViewChild('fileInput', { static: true }) fileInput;
    types: any;
    section: string = '';

    protected constructor(
        protected dragulaService: DragulaService,
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected categoryService: CategoryService,
        protected typeService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
        super(categoryService, router, route, errorEventService, loadingEventService, renderer, generalDetailHandler);

        // afterOnSubmit
        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterOnSubmit).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );
    }

    ngOnInit() {
        // initialize the item
        this.item = {
            name: '',
            id: 0,
            image_url: '',
            image: '',
            type_ids: [],
            types: []
        };

        // general initialization
        this.initialize();

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.categories',
                url: this.platform + (this.section ? '/' + this.section : '') + '/categories',
                isActive: false,
            },
            {
                name: (this.item.id > 0) ? 'api.edit' : 'api.add',
                url: '',
                isActive: true,
            }];

        // set types received from the FieldsdetailsResolver
        this.types = this.route.snapshot.data.fieldsDetails.data.type.filter(type => type.deleted_at == null);
    }

    /**
     * Override the GeneralDetail getItemDetail function including the types update from the response
     */
    getItemDetail() {
        this.generalService.getItem(this.id).subscribe(
            data => {
                this.item = this.processItemResponse(data);
                // this is different from the generalDetail getItemDetail function
                this.types = this.types.filter(item => !this.item.types.some(x => x.id === item.id));
                this.breadcrumbs[this.breadcrumbs.length - 1].name = 'api.edit';
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * Call the categoryService save function instead of the GeneralDetail
     *
     * Needed to be able to send images from form
     */
    onSubmit() {
        this.categoryService.save(this.item, this.fileInput.nativeElement).subscribe(
            data => {
                /*const response = data.json();
                this.item = response.data;*/
                this.router.navigate([this.actionLink]);
            },
            (error) => {
                console.log(error);
            }
        );
    }
}
