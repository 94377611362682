<div #image_section *ngIf="productDetail" class="row product-img-section">
    <div class="row">
        <div [dragula]='"bag-one"' [(dragulaModel)]='productDetail.images'>
            <div *ngFor="let image of productDetail.images; let i=index" class="col-sm-{{productDetail.images.indexOf(image) < 6 ? 6 : 2}}">
                <div class="image_container {{i > 6 ? 'thumb' : ''}}">
                    <button type="button" (click)="deleteImage(image)" class="close" aria-label="Close" *ngIf="editable">
                        <span class="glyphicon glyphicon-remove-circle"></span>
                    </button>
                    <a download href="{{image.src}}" class="download" aria-label="Download" target="_blank">
                        <span class="glyphicon glyphicon-download-alt"></span>
                    </a>

                    <img [ngClass]="{'img-small': createPage}" class="img-rounded {{productDetail.images.indexOf(image) < 6 ? 'img-fluid' : 'img-fluid'}}" [src]="image.image_url" (click)="openLightbox(i); "/>

                </div>
            </div>
        </div>
    </div>

    <div *ngIf="editable && refresh">

        <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [dropZoneClassName]="''">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" class="img-ul-file-upload img-ul-hr-inline-group">
                <div filedrop="" class="img-ul">
                    <div class="img-ul-file-upload img-ul-hr-inline-group">
                        <label class="img-ul-upload img-ul-button">
                            <span>Upload images</span><input multiple="" type="file" accept="image/*" (change)="fileProgress($event)"></label>
                        <div class="img-ul-drag-box-msg">Drop your images here!</div>
                    </div>
                </div>
            </ng-template>
        </ngx-file-drop>

        <h2 class="text-center" *ngIf="uploadedImagesCounter > 0">{{'api.nr_of_images_uploaded' | translate}} {{uploadedImagesCounter}}</h2>
    </div>
</div>
