import {
  Component, OnInit, Input, Output, EventEmitter, Renderer2, Inject, OnDestroy,
  ElementRef, ViewChild, AfterViewInit, OnChanges
} from '@angular/core';
import { ProductDetail } from '../../../sales-platform-module/models/productDetail';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { DragulaService } from 'ng2-dragula';
import { environment } from '../../../../environments/environment';
import { GeneralDetail } from '../../helper-classes/general-detail';
import { GeneralService } from '../../services/general.service';
import { ErrorEventService } from '../../services/error-event.service';
import { ErrorHandlerEvent, GeneralDetailEvent } from '../../models/event';
import { GeneralDetailEventService } from '../../services/general-detail-event.service';
import { LoadingEventService } from '../../services/loading-event.service';
import { TranslatePipe } from '@ngx-translate/core';
import { Subscription } from 'rxjs/Subscription';
import { LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { HttpClient } from '@angular/common/http';
import { NgxPicaErrorInterface, NgxPicaService } from '@digitalascetic/ngx-pica';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';


declare var $: any;

@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss'],
  providers: [
    { provide: 'instance1', useClass: GeneralService },
    { provide: 'instance2', useClass: GeneralService },
    { provide: 'uri', useValue: 'machine_images' },
    TranslatePipe
  ]
})

export class ProductImageComponent extends GeneralDetail implements OnInit, OnDestroy {
  @Input() productDetail: ProductDetail;
  @Output() changed = new EventEmitter();
  @Output() uploadStart = new EventEmitter();
  @ViewChild('image_section', { static: false }) imageSection: ElementRef;
  authToken: string;
  saveImagesUrl: string;
  @Input() editable: boolean = false;
  @Input() uploadUrl: string = 'saveMachineImage';
  @Input() resourceUrl: string;
  @Input() createPage: boolean = false;
  subscribe: any;
  refresh: boolean = true;
  counter = 0;
  uploadedImagesCounter = 0;

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;

  private _subscription: Subscription;

  public files: NgxFileDropEntry[] = [];

  constructor(
    private dragulaService: DragulaService,
    @Inject('instance1') protected generalService: GeneralService,
    @Inject('instance2') protected generalService2: GeneralService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected errorEventService: ErrorEventService,
    protected loadingEventService: LoadingEventService,
    protected renderer: Renderer2,
    private _lightbox: Lightbox,
    // private _lightboxOverlayComponent: LightboxOverlayComponent,
    private _lightboxEvent: LightboxEvent,
    protected generalDetailHandler: GeneralDetailEventService,
    private errorHandler: ErrorEventService,
    private translatePipe: TranslatePipe,
    private http: HttpClient,
    private _ngxPicaService: NgxPicaService
  ) {
    super(
      generalService,
      router,
      route,
      errorEventService,
      loadingEventService,
      renderer,
      generalDetailHandler
    );

    super.parentInitialize();
    this.subscribe = dragulaService.dropModel('bag-one').subscribe((value) => {
      this.reOrder(value.targetModel);
    });

    const bag: any = this.dragulaService.find('bag-one');
    if (bag !== undefined) {
      this.dragulaService.destroy('bag-one');
    }
    dragulaService.createGroup('bag-one', {
      // moves: () => { return this.editable; }
      moves: () => this.editable
    });
    this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterReOrder).subscribe(() => {
    });


  }

  private _onReceivedEvent(event: any): void {
    // remember to unsubscribe the event when lightbox is closed
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      $('.lightboxOverlay').remove();
      $('.lightbox').remove();
      this._subscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscribe.unsubscribe();
  }


  /**
   * sets the save image URLproduct
   * sets the authToken
   */
  ngOnInit() {
    if (this.resourceUrl) {
      this.generalService.setUri(this.resourceUrl);
    }
    this.saveImagesUrl = environment.apiUrl + this.uploadUrl;
    this.authToken = 'Bearer ' + localStorage.auth_token;
    this.counter = 0;
  }

  /**
   * Opens the lighbox for the image with the specific index
   * @param {number} index
   */
  openLightbox(index: number): void {
    // open lightbox
    this._lightbox.open(this.productDetail.images, index);

    this._subscription = this._lightboxEvent.lightboxEvent$
      .subscribe(event => this._onReceivedEvent(event));
  }

  /**
   * after confirm deletes the specific image
   * @param image
   */
  deleteImage(image) {
    const c = confirm(this.translatePipe.transform('message.are_you_sure_to_delete'));
    if (c === true) {
      this.delete(image);
      const index = this.productDetail.images.indexOf(image);
      if (index > -1) {
        this.productDetail.images.splice(index, 1);
      }
    }
  }

  fileProgress(fileInput: any) {
    this.counter += 1;
    if (this.counter === 1) {
      this.renderer.addClass(this.imageSection.nativeElement, 'loading');
    }

    const files: File[] = fileInput.target.files;

    this._ngxPicaService.resizeImages(files, 1024, 1024, {
      exifOptions: { 'forceExifOrientation': true },
      'aspectRatio': { 'keepAspectRatio': true }
    })
      .subscribe((imageResized: File) => {
        this.fileData = imageResized;

        this.onSubmit();

      }, (err: NgxPicaErrorInterface) => {
        throw err.err;
      });

  }

  onSubmit() {
    this.uploadStart.emit('true');
    this.uploadedImagesCounter += 1;
    const formData = new FormData();
    formData.append('machine_id', this.productDetail.id.toString());
    formData.append('image', this.fileData);
    this.generalService2.setUri(this.uploadUrl);
    this.generalService2.save(formData).subscribe(
      data => {
        this.counter -= 1;
        this.changed.emit(JSON.stringify(data));

        if (this.counter === 0) {
          this.uploadedImagesCounter = 0;
          this.renderer.removeClass(this.imageSection.nativeElement, 'loading');

          // trick
          this.refresh = false;
          setTimeout(() => {
            this.refresh = true;
          }, 100);
        }
      }
    );
    // this.http.post(saveImagesUrl, formData)
    //     .subscribe(res => {
    //         console.log(res);
    //         //this.uploadedFilePath = res.data.filePath;
    //         alert('SUCCESS !!');
    //     });
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          this.counter += 1;
          if (this.counter === 1) {
            this.renderer.addClass(this.imageSection.nativeElement, 'loading');
          }

          this._ngxPicaService.resizeImages([file], 1024, 1024, {
            exifOptions: {
              'forceExifOrientation': true
            }, 'aspectRatio': { 'keepAspectRatio': true }
          })
            .subscribe((imageResized: File) => {
              this.fileData = imageResized;

              this.onSubmit();

            }, (err: NgxPicaErrorInterface) => {
              throw err.err;
            });
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
}
