<tag-input [(ngModel)]="model"
           (ngModelChange)="change($event)"
           theme='bootstrap'
           [maxItems]="maxItems"
           [dragZone]="dragZone"
           [identifyBy]="identifyBy"
           [displayBy]="displayBy"
           [onlyFromAutocomplete]="onlyFromAutocomplete"
           [modelAsStrings]="modelAsStrings"
           placeholder="{{placeHolder}}">
</tag-input>