import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ErrorEventService } from '../services/error-event.service';
import { GeneralDetailEvent, LoadingEvent } from '../models/event';
import { GeneralService } from '../services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Errorhandler } from './errorhandler';
import { BreadcrumbItem } from '../models/breadcrumb-item';
import { GeneralDetailEventService } from '../services/general-detail-event.service';
import { LoadingEventService } from '../services/loading-event.service';

@Directive()
export class GeneralDetail extends Errorhandler implements OnInit, OnDestroy {
  id: any;
  item: any;
  actionLink: string = '';
  @ViewChild('form', { static: true }) form: ElementRef;
  breadcrumbs: BreadcrumbItem[];
  platform: string = '';
  onSubmitNavigateUrl = '';
  breadcrumbEditText: string = 'api.edit';
  protected route_subscription: any;
  protected subscriptions: any[] = [];
  public saving: boolean = false;

  // loadingItems: Array<any> = [];

  constructor(
    protected generalService: GeneralService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected errorEventService: ErrorEventService,
    protected loadingEventService: LoadingEventService,
    protected renderer: Renderer2,
    protected generalDetailHandler?: GeneralDetailEventService,
  ) {
    super(errorEventService, renderer);
  }

  parentInitialize() {
    super.initialize();
  }

  initialize(id?: any) {
    // not used yet, but it is for loading
    this.loadingInitialize();

    // setup the current platform
    this.platform = this.router.url.split('/')[1];

    // call parent initialize function
    this.parentInitialize();

    // get the Item by id if the id in specified (from code or from parameters)
    if (id !== undefined) {
      this.id = id;
      this.getItemDetail();
    } else {
      if (!this.route_subscription) {
        this.route_subscription = this.route.params.subscribe((params) => {
          const route_id = params['id'];
          if (!isNaN(Number(route_id)) && Number(route_id) > 0) {
            this.id = route_id;
            this.getItemDetail();
          } else if (this.item.id) {
            this.ngOnInit();
          }
        });
      }
    }

    // if (this.actionLink === undefined || this.actionLink === '') {
    //     // the actionLink is the "baseUri" from the API for the specified component
    //     this.actionLink = this.route.snapshot.data.actionLink;
    // }

    if (this.actionLink === undefined || this.actionLink === '') {
      // the actionLink is the "baseUri" from the API for the specified component
      if (this.route.snapshot.data.actionLink != 'internal-platform/machines') {
        this.actionLink = this.route.snapshot.data.actionLink;
      } else {
        this.actionLink = undefined;
      }
    }

    // initial set the onSubmitNavigateUrl to actionLink (onSubmitNavigateUrl used to know where to redirect after submit)
    this.onSubmitNavigateUrl = this.actionLink;
  }

  /**
   * Get the details of a specified item by id
   */
  getItemDetail(postData = {}) {
    if (postData && Object.keys(postData).length > 0) {
      this.generalService.getItemPost(this.id, postData).subscribe(
        (data) => this.onItemGetSuccess(data),
        (error) => this.onItemGetError(error)
      );
    } else {
      this.generalService.getItem(this.id).subscribe(
        (data) => this.onItemGetSuccess(data),
        (error) => this.onItemGetError(error)
      );
    }
  }

  protected onItemGetSuccess(data) {
    // interpret the response with different format
    this.item = this.processItemResponse(data);

    // Modify the breadcrumb if needed

    if (this.breadcrumbs != null) {
      this.breadcrumbs[
        this.breadcrumbs.length - 1
      ].name = this.breadcrumbEditText;
    }

    // emit the afterGetItem event if required
    if (typeof this.generalDetailHandler !== 'undefined') {
      this.generalDetailHandler.emitEvent(GeneralDetailEvent.afterGetItem);
    }
  }

  protected onItemGetError(error) {
    console.log(error);
  }

  /**
   * Default interpretation format
   */
  processItemResponse(data) {
    return data.data;
  }

  /**
   * Post the details of a specified item for saving
   */
  onSubmit() {
    this.loadingEventService.emitEvent(LoadingEvent.loadingStart);
    this.saving = true;
    this.generalService.save(this.item).subscribe(
      (data) => {
        // interpret the response with different format
        this.item = this.processItemResponse(data);

        // emit the afterOnSubmit event if required
        if (typeof this.generalDetailHandler !== 'undefined') {
          this.generalDetailHandler.emitEvent(GeneralDetailEvent.afterOnSubmit);
        }

        // redirect to onSubmitNavigateUrl if it is specified
        if (this.onSubmitNavigateUrl !== undefined) {
          this.router.navigate([this.onSubmitNavigateUrl]);
        }
        this.saving = false;
        this.loadingEventService.emitEvent(LoadingEvent.loadingFinished);
      },
      (error) => {
        this.saving = false;
        this.loadingEventService.emitEvent(LoadingEvent.loadingFinished);
        console.log(error);
      }
    );
  }

  /**
   * Post the details of a specified item for saving
   */
  restoreMachine() {
    this.loadingEventService.emitEvent(LoadingEvent.loadingStart);
    this.saving = true;
    this.generalService.restoreMachine(this.item.id).subscribe(
      (data) => {
        // interpret the response with different format
        this.item = this.processItemResponse(data);

        // emit the afterOnSubmit event if required
        if (typeof this.generalDetailHandler !== 'undefined') {
          this.generalDetailHandler.emitEvent(GeneralDetailEvent.afterOnSubmit);
        }

        // redirect to onSubmitNavigateUrl if it is specified
        if (this.onSubmitNavigateUrl !== undefined) {
          this.router.navigate([this.onSubmitNavigateUrl]);
        }
        this.saving = false;
        this.loadingEventService.emitEvent(LoadingEvent.loadingFinished);
      },
      (error) => {
        this.saving = false;
        this.loadingEventService.emitEvent(LoadingEvent.loadingFinished);
      }
    );
  }

  /**
   * Delete a specified item
   */
  delete(item) {
    this.generalService.delete(item.id).subscribe(
      () => {
        // emit the afterDelete event if required
        if (typeof this.generalDetailHandler !== 'undefined') {
          this.generalDetailHandler.emitEvent(GeneralDetailEvent.afterDelete);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * Reorder specified items
   */
  reOrder(items) {
    // create the request data
    const data = { order: items.map((item) => item.id) };
    this.generalService.reOrder(data).subscribe(
      () => {
        // emit the afterReOrder event if required
        if (typeof this.generalDetailHandler !== 'undefined') {
          this.generalDetailHandler.emitEvent(GeneralDetailEvent.afterReOrder);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  loadingInitialize() {
    /*this.loadingEventService.subscribeToEvent(LoadingEvent.loadingStart).subscribe((body) => {

            this.loadingItems.push(body);
            if (!this.form.nativeElement.classList.contains('loading')) {
                this.renderer.addClass(this.form.nativeElement, 'loading');
            }
        });

        this.loadingEventService.subscribeToEvent(LoadingEvent.loadingFinished).subscribe((body) => {
            const index = this.loadingItems.indexOf(body);
            if ( index !== -1) {
                this.loadingItems.splice(index, 1);
            }
            if (this.loadingItems.length === 0) {
                setTimeout(() => {
                    if (this.loadingItems.length === 0) {
                        this.renderer.removeClass(this.form.nativeElement, 'loading');
                    }
                }, 2000);

            }

        });*/
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.route_subscription) {
      this.route_subscription.unsubscribe();
    }
    for (let i = 0; i < this.subscriptions.length; ++i) {
      this.subscriptions[i].unsubscribe();
    }
  }
}
