import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import { share } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ErrorEventService} from './error-event.service';
import {ErrorHandlerEvent, LoadingEvent} from '../models/event';

import {LoadingEventService} from './loading-event.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class MkaApiRequest {

    private headers: any = new HttpHeaders();
    token: any = '';

    constructor(private http: HttpClient, private errorHandler: ErrorEventService, private loadingEventService: LoadingEventService) {
    }

    /**
     * Set the header's Authorization for API calls
     *
     * @param {string | boolean} token
     */
    setAuthHeader(token: string | boolean) {
        if (environment.enablePlatformSelect == true) {
             return false;
        }
        if (token === false && this.headers) {
            this.headers.delete('Authorization');
            this.token = null;
        } else if (this.headers) {
            this.headers = this.headers.set('Authorization', 'Bearer ' + token);
            this.token = token;
        } else {
            this.headers = new HttpHeaders({
                'Authorization': 'Bearer ' + token
            });
            this.token = token;
        }
    }

    /**
     *Set the header's Language for API calls
     *
     * @param {string} locale
     */
    setLanguageHeader(locale: string) {
        if (this.headers) {
            this.headers = this.headers.set('Use-Language', locale);
        } else {
            this.headers = new HttpHeaders({
                'Use-Language': locale
            });
        }
    }

    /**
     * Handling the response all call the correspondent events
     *
     * @param {Observable<any>} observableRequest
     */
    responseHandling(observableRequest: Observable<any>) {
        const errorHandlerSubscription = observableRequest.subscribe(result => {
                errorHandlerSubscription.unsubscribe();
                this.errorHandler.emitEvent(ErrorHandlerEvent.removeErrors, result._body);
                this.loadingEventService.emitEvent(LoadingEvent.loadingFinished, result.url);

                if (result._debugbar) {
                    // console.log('debugbar: ');
                    const queries = result._debugbar.queries;
                    // console.log('result_url: ', result.url);
                    // console.log('Nr. of queries: ', queries.nb_statements);
                    for (const statement of queries.statements) {
                        // console.log('Sql: ', statement.sql, '----------------', statement.duration_str);
                    }

                }
            },
            error => {
                errorHandlerSubscription.unsubscribe();
                this.errorHandler.emitEvent(ErrorHandlerEvent.applyErrors, error);
                this.loadingEventService.emitEvent(LoadingEvent.loadingFinished, error.url);
            },
            () => {
                errorHandlerSubscription.unsubscribe();
            });
    }

    /**
     * Call a GET request
     *
     * @param uri
     * @param data
     * @returns {Observable<any>}
     */
    getRequest(uri, data?): Observable<any> {
        this.loadingEventService.emitEvent(LoadingEvent.loadingStart, environment.apiUrl + uri);
        const observableRequest = this.http.get(environment.apiUrl + uri, {
            headers: this.headers,
            params: data
        }).pipe(share());
        this.responseHandling(observableRequest);

        return observableRequest;
    }

    /**
     * Call a POST request
     *
     * @param uri
     * @param data
     * @returns {Observable<any>}
     */
    postRequest(uri, data): Observable<any> {
        if (uri.substr(uri.length - 7) !== 'reorder') {
          this.loadingEventService.emitEvent(LoadingEvent.loadingStart, environment.apiUrl + uri);
        }
        const observableRequest = this.http.post(environment.apiUrl + uri,
            data,
            {
                headers: this.headers
            }).pipe(share());

        this.responseHandling(observableRequest);
        return observableRequest;
    }

    /**
     * Call a PUT request
     *
     * @param uri
     * @param data
     * @returns {Observable<any>}
     */
    putRequest(uri, data): Observable<any> {

        this.loadingEventService.emitEvent(LoadingEvent.loadingStart, environment.apiUrl + uri);
        const observableRequest = this.http.put(environment.apiUrl + uri,
            data,
            {
                headers: this.headers
            }).pipe(share());

        this.responseHandling(observableRequest);
        return observableRequest;
    }

    /**
     * Call a DELETE request
     *
     * @param uri
     * @returns {Observable<any>}
     */
    deleteRequest(uri): Observable<any> {
        this.loadingEventService.emitEvent(LoadingEvent.loadingStart, environment.apiUrl + uri);
        const observableRequest = this.http.delete(environment.apiUrl + uri,
            {
                headers: this.headers
            }).pipe(share());
        this.responseHandling(observableRequest);
        return observableRequest;
    }
}
