import { Directive, OnInit, Renderer2 } from '@angular/core';
import {Attribute} from '../../../models/attribute';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralDetail} from '../../../helper-classes/general-detail';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {GeneralDetailEvent} from '../../../models/event';
import {GeneralFields} from '../../../helper-classes/general-fields';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';

@Directive()
export abstract class BaseAttributeDetailComponent extends GeneralDetail implements OnInit {

    section: string = '';

    item: Attribute;
    title: string = 'api.attribute';

    types = Object.keys(GeneralFields.ATTRIBUTE_TYPES).map(function (index) {
        return GeneralFields.ATTRIBUTE_TYPES[index];
    });

    specification_types_simple = Object.keys(GeneralFields.SPECIFICATION_TYPES_SIMPLE).map(function (index) {
        return GeneralFields.SPECIFICATION_TYPES_SIMPLE[index];
    });

    specification_types_from_to = Object.keys(GeneralFields.SPECIFICATION_TYPES_FROM_TO).map(function (index) {
        return GeneralFields.SPECIFICATION_TYPES_FROM_TO[index];
    });

    specification_types: any = [];
    show_types = GeneralFields.SHOW_TYPES;
    attributes: any = [];
    selects: any = [];
    attribute_options: any = [];
    selectedValue: null;
    dependent_attribute_type: any = '';

    protected constructor(
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
        super(generalService, router, route, errorEventService, loadingEventService, renderer, generalDetailHandler);

        // afterOnSubmit
        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterOnSubmit).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );

        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterGetItem).subscribe(() => {
                this.attributeChanged();
            })
        );

        this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterGetItem).subscribe(() => {
            if (this.item.dependent_value == '0') {
                this.item.dependent_value = '';
            }
            if (typeof this.item.dependent_value === 'string') {
                this.item.dependent_value = this.item.dependent_value.split(',').map(function (x) {
                    return Number(x);
                });
            }
        });
    }

    // public selectedValue;

    ngOnInit() {

        this.attributes = this.route.snapshot.data.fieldsDetails.data.attributes;

        this.selects = this.route.snapshot.data.fieldsDetails.data;

        // initialize the item
        this.item = {
            name: null,
            id: 0,
            type: 0,
            attribute_name: '',
            specification_type: 0,
            mandatory: 0,
            dependent_to: null,
            dependent_value: [],
            show_type: 0,
            translatable: false
        };

        // general initialization
        this.initialize();

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.attributes',
                url: this.platform + (this.section ? '/' + this.section : '') + '/attributes',
                isActive: false,
            },
            {
                name: 'api.add',
                url: '',
                isActive: true,
            }];
    }

    getAttributeValues(selected_val) {

        if (!selected_val) {
            return;
        }

        selected_val = parseInt(selected_val, 10);
        const attribute = this.attributes.find(x => x.id === selected_val);

        this.dependent_attribute_type = '';

        let attribute_name = null;
        let select_options = null;

        // ATTRIBUTE_TYPES - checkbox
        if (attribute.type === 1) {
            this.dependent_attribute_type = 'checkbox';
        }

        // ATTRIBUTE_TYPES - select
        if (attribute.type === 2) {
            this.dependent_attribute_type = 'select_direct';
            attribute_name = attribute.attribute_name;
            select_options = this.selects;
            this.attribute_options = select_options[attribute_name];
        }

        // ATTRIBUTE_TYPES - select with other
        if (attribute.type === 3) {
            this.dependent_attribute_type = 'select';
            attribute_name = attribute.attribute_name;
            select_options = this.selects;
            this.attribute_options = select_options[attribute_name];
        }
    }

    attributeChanged() {
        // todo: input but when it is number
        if (this.item.type === 6 || this.item.type === 5) {
            this.specification_types = this.specification_types_from_to;
        } else {
            this.specification_types = this.specification_types_simple;
        }

        if (this.item.type === 2 || this.item.type === 3) {
            if (this.item.show_type == null) {
                this.item.show_type = GeneralFields.SHOW_TYPES[0].id;
            }
        } else {
            if (this.item.show_type != null) {
                this.item.show_type = null;
            }
        }

        this.getAttributeValues(this.item.dependent_to);
    }
}
