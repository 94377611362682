import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralDetail} from '../../../helper-classes/general-detail';
import {GeneralService} from '../../../services/general.service';
import {UserGroup} from '../../../models/user-group';
import {GroupsService} from '../../../services/groups.service';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEvent} from '../../../models/event';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';

@Component({
    selector: 'app-group-details',
    templateUrl: './group-details.component.html',
    styleUrls: [
        './group-details.component.scss'
    ],

    // create a separate instance of the GeneralService
    providers: [
        GeneralService,
    ],
})

export class GroupDetailsComponent extends GeneralDetail implements OnInit {

    public item: UserGroup;
    public groupType: string;
    public userList: any[] = [];
    public dealers: any[] = [];

    constructor(protected errorEventService: ErrorEventService,
                protected loadingEventService: LoadingEventService,
                protected groupsService: GroupsService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected generalDetailHandler: GeneralDetailEventService,
                protected renderer: Renderer2) {
        super(groupsService, router, route, errorEventService, loadingEventService, renderer);
        this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterGetItem).subscribe(() => {
            this.item.user_ids = this.item.users.map((x) => x.id);
        });
    }

    ngOnInit() {
        // set the groupType from params

        this.groupType = this.route.snapshot.paramMap.get('type');

        // set the API URI
        this.generalService.setUri('groups/' + this.groupType);

        // initialize the item
        this.item = new UserGroup();

        if (this.route.snapshot.data.dealersList) {
            this.dealers = this.route.snapshot.data.dealersList.data;
        }

        this.getUserList();

        this.breadcrumbEditText = 'api.modify_mailing_list_' + this.groupType;
        // general initialization
        this.initialize();

        // set the onSubmitNavigateUrl (where the page redirect after commit) based on groupType
        this.onSubmitNavigateUrl = this.platform + '/' + (this.groupType === 'trader' ? 'traders' : 'salesmen');

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: (this.groupType === 'salesman') ? 'api.salesman_group_management' : 'api.trader_management',
                url: this.platform + '/' + ((this.groupType === 'salesman') ? 'salesmen' : 'traders'),
                isActive: false,
            },
            {
                name: 'api.add_mailing_list_' + this.groupType,
                url: '',
                isActive: true,
            }];
    }


    // needed because we use a map function for getItem -> needed for ng-select
    processItemResponse(data) {
        return data;
    }

    /**
     * Refresh the item users
     *
     * @param value
     */
    refreshValue(value) {
        let tmp = [];
        for (const user_id of this.item.user_ids) {
            tmp.push(this.userList.find((x) => x.id == user_id ));
        }
        this.item.users = tmp;
    }


    /**
     * Populates the user list
     */
    protected getUserList() {
        this.groupsService.getUserList(this.groupType).subscribe(
            data => {
                this.userList = data;
            },
            (error) => {
                console.log(error);
            });
    }

}
