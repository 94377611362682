import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbItem} from '../../../models/breadcrumb-item';
import {TranslationService} from '../../../services/translation.service';
import {TranslatePipe} from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-translations',
    templateUrl: './translations.component.html',
    styleUrls: [
        './translations.component.scss'
    ],
    providers: [TranslatePipe]
})

export class TranslationsComponent implements OnInit {
    title: string = 'api.translations';
    translationGroups: any[];
    selectedGroup: string;
    platform: string;
    breadcrumbs: BreadcrumbItem[];
    translationItems: any[];
    languageList: any[];

    currentTranslationValue: string = '';
    currentTranslationKey: string = '';
    currentTranslationLocale: string = '';
    currentTranslationObject: any;
    currentTranslationIsNew: boolean = false;
    newTranslation: any;

    constructor(protected translationsService: TranslationService, protected route: ActivatedRoute, protected router: Router, protected translatePipe: TranslatePipe) {
    }

    ngOnInit() {
        this.translationGroups = this.route.snapshot.data.fieldsDetails.data.translation_groups;
        this.platform = this.router.url.split('/')[1];
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + '/machines',
                isActive: false,
            },
            {
                name: 'api.translations',
                url: '',
                isActive: true,
            }];

    }

    /**
     * When group is selected, or selection changed change the translation list
     * @param value
     */
    groupSelectionChanged(value) {
        this.updateGroupTranslations(value);
    }

    /**
     * Getting the list from server
     * @param group
     */
    protected updateGroupTranslations(group) {
        this.translationsService.getGroupTranslations(this.selectedGroup).subscribe(
            data => {
                const items = data.data.data;
                this.translationItems = Object.keys(items).map(function (key, index) {
                    items[key]['values'] = Object.keys(items[key]['values']).map(subkey => items[key]['values'][subkey]);
                    return items[key];
                });
                this.languageList = data.data.languages;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * Opens the modal window for editing a specific translation
     * @param key
     * @param locale
     * @param value
     * @param object
     */
    openModal(key, locale, value, object) {
        this.currentTranslationKey = key;
        this.currentTranslationLocale = locale;
        this.currentTranslationValue = value;
        this.currentTranslationObject = object;
        this.newTranslation = null;
        $('#translationEditModal').modal('show');
    }

    /**
     * Opens modal for adding new item
     */
    openModalForAdd() {
        this.newTranslation = {
            'group': this.selectedGroup,
            'key': '',
            'values': {}
        };
        $('#translationEditModal').modal('show');
    }

    /**
     * Saves the edited translation from modal
     */
    saveTranslation() {
        if (!this.newTranslation) {
            this.translationsService.saveTranslation(
                this.selectedGroup,
                this.currentTranslationKey,
                this.currentTranslationLocale,
                this.currentTranslationValue
            ).subscribe(
                data => {
                    const item = data.data;
                    this.currentTranslationObject.status = item.status;
                    this.currentTranslationObject.value = item.value;
                    $('#translationEditModal').modal('hide');
                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            this.translationsService.addTranslation(this.newTranslation).subscribe(
                data => {
                    $('#translationEditModal').modal('hide');
                    this.updateGroupTranslations(this.selectedGroup);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    /**
     * Calls the publish translations from server. This "commits" the editing, by writing the changes to file.
     */
    publishTranslations() {
        this.translationsService.publishTranslations(this.selectedGroup).subscribe(
            data => {
                this.updateGroupTranslations(this.selectedGroup);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    deleteTranslation(key) {
        const c = confirm(this.translatePipe.transform('message.are_you_sure_to_delete'));
        if (c === true) {
            this.translationsService.deleteTranslation({'key': key, 'group': this.selectedGroup}).subscribe(
                data => {
                    this.updateGroupTranslations(this.selectedGroup);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }
}
