<!-- Modal -->
<div class="modal fade" id="{{modalId}}" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">{{'api.extend_bid_time' | translate}}</h4>
            </div>
            <div class="modal-body" *ngIf="bidTime">
                <div class="form-group row">
                    <label class="col-sm-4">{{'api.bid_time' | translate}}</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="subject" name="subject" placeholder="{{'api.bid_time' | translate}}" [(ngModel)]="bidTime">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" (click)="continueClicked()">{{'api.continue' | translate}}</button>
            </div>
        </div>

    </div>
</div>