<div *ngIf="isLoading">Loading {{ 'api.machines' | translate }}...</div>

<div *ngIf="!isLoading" class="compare-machine-header">
  <div class="row compare-machine-body-checkbox">
    <div class="col-md-4" *ngFor="let machine of machines">
      <input type="checkbox" name="" id="{{machine.id}}" [(ngModel)]="machine.display"
             (change)="toogleActive(machine.id , $event)">
    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="accordion accordion-flush">
  <div class="accordion-item">

    <h5 class="accordion-header compare-sales-heading" id="flush-headingOne">
      <button (click)="toggleCollapse('flush-collapseOne', $event)" class="accordion-button" type="button"
              data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
              aria-controls="flush-collapseOne">
        {{ 'api.compare' | translate }} {{ 'api.machines' | translate }}
      </button>
    </h5>

    <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne"
         data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div class="accordion-body-inner">
          <div class="row compare-machine-body">
            <div class="col-md-4" *ngFor="let machine of machines">
              <div *ngIf="machine.display">
                <app-compare-machine-detail-single-admin
                  [id]="machine.id"
                  (machineInfo)="machineInfo($event)"
                ></app-compare-machine-detail-single-admin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="accordion-item">

    <h5 class="accordion-header compare-sales-heading" id="flush-headingTwo">
      <button (click)="toggleCollapse('flush-collapseTwo', $event)" class="accordion-button" type="button"
              data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false"
              aria-controls="flush-collapseTwo">
        {{ 'api.prices_comparisons' | translate }}
      </button>
    </h5>

    <div id="flush-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="flush-headingTwo"
         data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">

        <div class="row compare-machine-body">
          <div class="col-md-4" *ngFor="let machine of machines">
            <div *ngIf="machine.display && comparisonSetup.trade_price">
              <app-compare-prices-table-machine-detail [machineDetails]="
                  [{
                    created_at : machine.created_at,
                    value : machine.book_value,
                    value_currency : machine.book_value_currency
                  }]
                  | json

              "
                                                       key="trade_price"
                                                       title="{{'api.trade_price' | translate}}"></app-compare-prices-table-machine-detail>
            </div>
          </div>
        </div>

        <div class="row compare-machine-body">
          <div class="col-md-4" *ngFor="let machine of machines">
            <div *ngIf="machine.display && comparisonSetup.selling_prices">
              <app-compare-prices-table-machine-detail [machineDetails]="[
                  {
                    created_at :  machine.selling_prices &&
  machine.selling_prices.length &&
  machine.selling_prices[machine.selling_prices.length - 1] &&
  machine.selling_prices[machine.selling_prices.length - 1].created_at
    ? machine.selling_prices[machine.selling_prices.length - 1].created_at
    : null,
                    value : machine.selling_price,
                    value_currency : machine.selling_price_currency
                  }
                ] | json
              "

                                                       key="selling_prices"
                                                       title="{{'api.selling_prices' | translate}}"></app-compare-prices-table-machine-detail>
            </div>
          </div>
        </div>

        <div class="row compare-machine-body">
          <div class="col-md-4" *ngFor="let machine of machines">
            <div *ngIf="machine.display && comparisonSetup.traders_bids">
              <app-compare-prices-table-machine-detail
                machineDetails="{{machine.trader_bids | json}}" key="trader_bid"
                title="{{'api.trader_bid' | translate}}">
              </app-compare-prices-table-machine-detail>
            </div>
          </div>
        </div>

        <div class="row compare-machine-body">
          <div class="col-md-4" *ngFor="let machine of machines">
            <div *ngIf="machine.display && comparisonSetup.salesman_bids">
              <app-compare-prices-table-machine-detail
                machineDetails="{{machine.salesman_bids | json}}"
                title="{{'api.salesman_bids' | translate}}"
                key="salesman_bids">
              </app-compare-prices-table-machine-detail>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">

    <h5 class="accordion-header compare-sales-heading" id="flush-headingThree">
      <button (click)="toggleCollapse('flush-collapseThree', $event)" class="accordion-button" type="button"
              data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false"
              aria-controls="flush-collapseThree">
        {{ 'api.machine_images' | translate }}
      </button>
    </h5>

    <div id="flush-collapseThree" class="accordion-collapse collapse show" aria-labelledby="flush-headingThree"
         data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div class="row compare-machine-body">
          <div class="col-md-4" *ngFor="let machine of machines">
            <div *ngIf="machine.display">
              <app-product-images
                [resourceUrl]="(section ? section + '_' :'') + 'machine_images'"
                [uploadUrl]="(section ? section + '_' :'') + 'saveMachineImage'"
                [productDetail]="machine"
                [editable]="editable" (changed)="getItemDetail()">
              </app-product-images>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
