import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralList} from '../../../helper-classes/general-list';
import {GeneralService} from '../../../services/general.service';

@Component({
    selector: 'app-trader-listing',
    templateUrl: './trader-listing.component.html',
    styleUrls: [
        './trader-listing.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        GeneralService,
        {provide: 'uri', useValue: 'traders'},
    ]
})

export class TraderListingComponent extends GeneralList implements OnInit {

    @Input() actionLink: string;

    constructor(protected generalService: GeneralService, protected route: ActivatedRoute, protected router: Router) {
        super(generalService, route, router);
    }

    ngOnInit() {
        // general initialization
        this.initialize();

        // specify the listing columns
        this.columns = [
            {
                name: 'company_name',
                formattedName: 'api.company_name'
            },
            {
                name: 'contact_lastname',
                formattedName: 'api.contact_person'
            },
            {
                name: 'contact_email',
                formattedName: 'api.email_address'
            },
            {
                name: 'billing_country',
                formattedName: 'api.country'
            },
            {
                name: 'billing_place',
                formattedName: 'api.place'
            },
            {
                name: 'expert_in',
                formattedName: 'api.expert_in',
                key: '0'
            },
            {
                name: 'expert_in',
                formattedName: 'api.expert_in',
                key: '1'
            },
            {
                name: 'expert_in',
                formattedName: 'api.expert_in',
                key: '2'
            },
            {
                name: 'expert_in',
                formattedName: 'api.expert_in',
                key: '3'
            }
        ];
    }
}
