import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralDetail} from '../../../helper-classes/general-detail';
import {TranslateService} from '@ngx-translate/core';
import {Salesman} from '../../../models/salesman';

import {SalesmenService} from '../../../services/salesmen.service';
import {LoadingEventService} from '../../../services/loading-event.service';
import {Role} from '../../../models/roles';


@Component({
    selector: 'app-salesman-details',
    templateUrl: './salesman-details.component.html',
    styleUrls: [
        './salesman-details.component.scss'
    ],

    // create a separate instance of the SalesmenService (extended from GeneralService) which will use the below uri
    providers: [
        SalesmenService,
        {provide: 'uri', useValue: 'salesmen'},
    ],
})

export class SalesmanDetailsComponent extends GeneralDetail implements OnInit {

    public item: Salesman;
    public roles: any[];
    public currencies: any;
    public dealers: any[];
    public companies: any[];
    public salesManagerRole: any;

    constructor(protected errorEventService: ErrorEventService,
                protected loadingEventService: LoadingEventService,
                protected salesmenService: SalesmenService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected translate: TranslateService,
                protected renderer: Renderer2) {
        super(salesmenService, router, route, errorEventService, loadingEventService, renderer);
    }

    ngOnInit() {

        // initialize the item
        this.item = new Salesman();

        // set roles received from the FieldsdetailsResolver
        this.roles = this.route.snapshot.data.fieldsDetails.data.roles;
        this.item.company_id = null;
        this.breadcrumbEditText = 'api.edit_salesman';
        // general initialization
        this.initialize();

        // set the onSubmitNavigateUrl (where the page redirect after commit) based on groupType
        this.onSubmitNavigateUrl = this.platform + '/salesmen';

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.salesman_management',
                url: this.platform + '/salesmen',
                isActive: false,
            },
            {
                name: 'api.add_salesman',
                url: '',
                isActive: true,
            }];

        // set currencies received from the FieldsdetailsResolver
        this.currencies = this.route.snapshot.data.fieldsDetails.data.currency;
        if (this.route.snapshot.data.dealersList) {
            this.dealers = this.route.snapshot.data.dealersList.data;
        }
        this.companies = this.route.snapshot.data.companyList.data;
    }

    // needed because we use a map function for getItem -> needed for ng-select
    processItemResponse(data) {
        return data;
    }

    isSalesManager() {
        return this.item.role == this.roles.filter(function (item) {
            return item.name == Role.SalesManager;
        })[0].id;
    }
}
