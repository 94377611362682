import {Injectable} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {GeneralService} from './general.service';

@Injectable({
    providedIn: 'root'
})
export class CategoryService extends GeneralService {
    uri = 'categories';

    // save the category with images -- needed to be able to send image to API
    save(category, fileBrowser): Observable<any> {
        const formData = new FormData();
        formData.append('name', category.name);
        for (const type of category.types) {
            formData.append('types[]', type.id);
        }
        if (fileBrowser.files && fileBrowser.files[0]) {
            const file = fileBrowser.files[0];
            formData.append('image', file, file.name);
        }
        if (category.id === 0) {
            return this.mkaApiRequest.postRequest('categories', formData);
        } else {
            formData.append('_method', 'PUT');
            return this.mkaApiRequest.postRequest('categories/' + category.id, formData);
        }
    }
}
