import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {MkaApiRequest} from './mka-api-request.service';
import {TranslateService} from '@ngx-translate/core';
import {LoadingEventService} from './loading-event.service';
import {LoadingEvent} from '../models/event';
import {environment} from '../../../environments/environment';

/**
 * Service for translations
 */
@Injectable({
    providedIn: 'root'
})
export class TransApiService {

    static readonly FLAG_MAPPING = {
        'en': 'gb',
        'nl': 'nl',
    };
    public defaultLocale = 'en';

    constructor(private mkaApiRequest: MkaApiRequest,
                private translate: TranslateService,
                private loadingEventService: LoadingEventService) {
        this.defaultLocale = environment.default_locale;
    }

    /**
     * Call the API
     *
     * @param locale
     * @returns {Observable<any>}
     */
    private getTranslationsFromAPI(locale): Observable<any> {
        this.mkaApiRequest.setLanguageHeader(locale);
        return this.mkaApiRequest.getRequest('translations');
    }

    /**
     * Load the translations
     *
     * @param {boolean} forceLoad
     */
    load(forceLoad: boolean = false) {
        let locale = this.defaultLocale;
        if (localStorage.current_locale) {
            locale = localStorage.current_locale;
        }

        if (localStorage.translations && forceLoad === false) {
            this.initTransModule(locale, JSON.parse(localStorage.translations));
        } else {
            this.getTranslationsFromAPI(locale).subscribe(
                data => {
                    const transObj = data.data;
                    localStorage.translations = JSON.stringify(transObj);
                    localStorage.current_locale = locale;
                    this.initTransModule(locale, transObj);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    /**
     * Change the location and load the correct translations
     *
     * @param {string} locale
     */
    changeLocale(locale: string) {
        if (!locale) {
            locale = this.defaultLocale;
        }
        localStorage.current_locale = locale;
        this.load(true);
    }

    /**
     * Get current locale - not used yet
     *
     * @returns {string}
     */
    getCurrentLocale(): string {
        let locale = this.defaultLocale;
        if (localStorage.current_locale) {
            locale = localStorage.current_locale;
        }
        return locale;
    }

    /**
     * Initialize the TransModule
     *
     * @param locale
     * @param translations
     */
    private initTransModule(locale, translations) {
        this.translate.use(locale);
        this.translate.setTranslation(locale, translations);
        this.loadingEventService.emitEvent(LoadingEvent.translationLoaded);
    }

    public getLocaleList() {
        return environment.locales;
    }


}
