import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {MachineColumnsService} from '../../internal-platform-module/services/machine-columns.service';
import {AuthService} from '../services/auth.service';

/**
 * Resolver to get all Fieldsdetails
 */
@Injectable()
export class UserProfileResolver implements Resolve<any> {
    constructor(
        private authService: AuthService
    ) {}

    resolve(route?: ActivatedRouteSnapshot): Promise<any> {
        return this.authService.updateUserProfile();
    }
}
