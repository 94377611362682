<div class="container">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>

    <div class="row">
        <div class="col-sm-6">
            <h3 class="sales-heading">{{item.id > 0 ? ('api.edit' | translate) : ('api.add' | translate)}} {{'api.type' | translate}}</h3>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <form class="form-horizontal" #form (submit)="onSubmit()">
                <div class="form-group">
                    <label class="col-sm-4">{{'api.name' | translate}}</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="name" name="name" placeholder="{{'api.name' | translate}}" [(ngModel)]="item.name">
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-4">{{'api.subcategories' | translate}}</label>
                    <div class="col-sm-8">
                        <div class="row" name="subcategories">
                            <div class="col-sm-6">
                                {{'api.selected_subcategories' | translate}}
                                <div class="draggable" [dragula]='"bag-one"' [(dragulaModel)]='item.subcategories' *ngIf="item.subcategories">
                                    <div *ngFor="let subcategory of item.subcategories">
                                        {{subcategory.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                {{'api.available_subcategories' | translate}}
                                <div class="draggable" [dragula]='"bag-one"' [(dragulaModel)]='subcategories'>
                                    <div *ngFor="let subcategory of subcategories">
                                        {{subcategory.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-4"></label>
                    <div class="col-sm-8">
                        <input type="submit" name="submit" title="" class="login-name buttons marginTop5" id="confirm" value="{{'api.confirm' | translate}}">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>