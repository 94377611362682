import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-detail-paging',
    templateUrl: './detail-paging.component.html',
    styleUrls: ['./detail-paging.component.scss']
})

/**
 * This component is used to make possible to navigate to previous and next item when a machine item detail page is opened.
 */
export class DetailPagingComponent {
    @Input() next: any;
    @Input() prev: any;
    @Input() url: string;

    constructor() { }
}
