<header>
  <nav class="navbar navbar-default custom-navbar">
    <div class="container">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header">
        <div
          class="mobile-nav-flag"
        >
          <div class="dropdown hidden-md" id="language-selector" *ngIf="languages">
            <a
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span
                class="flag-icon flag-icon-{{ flags[current_locale] }}"
              ></span>
            </a>
            <ul class="dropdown-menu">
              <li *ngFor="let language of languages">
                <a
                  *ngIf="language !== current_locale"
                  href="javascript:void(0);"
                  (click)="changeLocale(language)"
                >
                  <span
                    class="flag-icon flag-icon-{{ flags[language] }}"
                  ></span>
                </a>
              </li>
            </ul>
          </div>
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>

        </div>
        <a class="navbar-brand" href="">
          <img class="reesink-logo marginLeft15" src="{{ logoImage }}" />
        </a>
      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul class="nav hidden-xsm block-sm navbar-nav navbar-right language-nav-item">
          <li>
            <div class="dropdown" id="language-selector" *ngIf="languages">
              <a
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span
                  class="flag-icon flag-icon-{{ flags[current_locale] }}"
                ></span>
              </a>
              <ul class="dropdown-menu">
                <li *ngFor="let language of languages">
                  <a
                    *ngIf="language !== current_locale"
                    href="javascript:void(0);"
                    (click)="changeLocale(language)"
                  >
                    <span
                      class="flag-icon flag-icon-{{ flags[language] }}"
                    ></span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <app-header-menu [headerMenuItems]="headerMenus"></app-header-menu>
      </div>
      <!-- /.navbar-collapse -->
    </div>
    <!-- /.container-fluid -->
  </nav>
</header>
<div *ngIf="showSeparatorLine" class="separator-bar"></div>
<div *ngIf="!withoutHeaderImage" class="bg-image-full">
  <carousel>
    <slide *ngFor="let slide of slides">
      <img
        [src]="'assets/images/' + slide"
        alt="image slide"
        class="bannerSlides"
        height="100px"
      />
      <div *ngIf="slides.length == 1 && withBannerLogo" class="lgi">
        <img
          height="100px"
          class="img-fluid toro-logo"
          src="{{ bannerLogo }}"
        />
      </div>
    </slide>
  </carousel>
</div>
<div class="col-xs-12 visible-xs">
  <app-header-menu
    [headerMenuItems]="headerMenus"
    [withLogin]="false"
  ></app-header-menu>
</div>
