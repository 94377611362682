import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {MkaApiRequest} from './mka-api-request.service';
import {AuthEventService} from './auth-event.service';
import {AuthEvent} from '../models/event';
import {environment} from '../../../environments/environment';
import {Salesman} from '../models/salesman';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private currentAuthToken: string;
    private isLoggedIn: boolean = false;
    private supportUrl: string;


    constructor(private mkaApiRequest: MkaApiRequest, private authEventService: AuthEventService) {
    }

    initialiseService() {
        if (localStorage.auth_token) {
            this.saveNewToken(localStorage.auth_token, localStorage.supportUrl);
        }
    }

    /**
     * Login with the specified data
     */
    login(credentials: any, loginType: string): Observable<any> {
        this.logout();
        return this.mkaApiRequest.postRequest('auth/login', {
            username: credentials.email,
            password: credentials.password,
            grant_type: 'password',
          // todo: was 4 before changed to 2
            client_id: environment.clientId,
            client_secret: environment.clientSecret,
            scope: loginType
        });
    }

    /**
     * Logout
     */
    logout() {
        delete this.currentAuthToken;
        delete localStorage.auth_token;
        delete localStorage.supportUrl;
        delete localStorage.current_user;
        delete localStorage.dealer_setting;
        delete localStorage.currentCurrency;
        this.authEventService.emitEvent(AuthEvent.AuthenticationState, false);
        this.mkaApiRequest.setAuthHeader(false);
        this.isLoggedIn = false;
    }

    /**
     * Check if the user is logged in
     */
    getIsLoggedIn(): boolean {
        return this.isLoggedIn;
    }

    /**
     * Save the auth_token
     *
     * @param {string} authToken
     * @param supportUrl
     */
    saveNewToken(authToken: string, supportUrl: string) {
        if (typeof authToken !== 'undefined') {
            this.currentAuthToken = authToken;
            localStorage.auth_token = authToken;
            this.supportUrl = supportUrl;
            localStorage.supportUrl = supportUrl;
            this.authEventService.emitEvent(AuthEvent.AuthenticationState, authToken);
            this.mkaApiRequest.setAuthHeader(authToken);
            this.isLoggedIn = true;
        }
    }

    sendForgetPassword(email: string, scope: string) {
        return this.mkaApiRequest.postRequest('auth/forgot-password', {
            email: email,
            scope: scope,
        });
    }

    sendResetPassword(credentials: any) {
        return this.mkaApiRequest.postRequest('auth/reset', {
            token: credentials.token,
            password: credentials.password,
            password_confirmation: credentials.password_confirmation
        });
    }

    getTokenWithAutologin(token: string, scope: string) {
        return this.mkaApiRequest.postRequest('auth/autologin', {
            token: token,
            scope: scope,
        });
    }

    /**
     * Set the current user
     * @param user
     */
    saveUser(user, dealer_settings, trader_data) {
        localStorage.current_user = JSON.stringify(user);
        localStorage.dealer_settings = JSON.stringify(dealer_settings);
        localStorage.trader_data = JSON.stringify(trader_data);
    }

    /**
     * Gets currently logged in user
     * @returns {any}
     */
    getCurrentUser(): Salesman {
        return JSON.parse(localStorage.current_user);
    }

    /**
     * Gets currently logged in user
     * @returns {any}
     */
    getDealerSettings() {
        return JSON.parse(localStorage.dealer_settings);
    }

    getDealerSetting(key) {
        const settings = this.getDealerSettings();
        if (settings && typeof settings[key] !== 'undefined') {
            return settings[key];
        }
        return null;
    }

    /**
     * Returns the trader info, or if key is provided then the given field of a trader
     * @param key
     */
    getTraderData(key?: string) {
        if (!localStorage.trader_data) {
            return null;
        }
        if (!key) {
            return JSON.parse(localStorage.trader_data);
        } else {
            const data = JSON.parse(localStorage.trader_data);
            if (data && typeof data[key] !== 'undefined') {
                return data[key];
            }
            return null;
        }
    }

    getUserProfile() {
        return this.mkaApiRequest.getRequest('user/profile', {});
    }

    updateUserProfile(): Promise<any> | any {
        return this.getUserProfile().toPromise().then(
            data => {
                const response = data.data;
                const dealer_settings = response.dealer_settings;
                const trader_data = response.trader;
                delete response.dealer_settings;
                delete response.trader;
                this.saveUser(response, dealer_settings, trader_data);
            },
            (error) => {
                console.log(error.json());
            }
        );
    }
}
