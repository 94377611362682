import { Injectable } from '@angular/core';
import {MkaApiRequest} from '../../shared/services/mka-api-request.service';

@Injectable({
    providedIn: 'root'
})
export class MachineColumnsLeadsService {

    public columns: any;

    static handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    constructor(private mkaApiRequest: MkaApiRequest) {
    }

    getColumns(platform: string = ''): Promise<any>|any {

        return this.mkaApiRequest.getRequest('leads_getColumns', {
                platform: platform
            })
            .toPromise()
            .then((data: Response) => {
                this.columns = data;
                return this.columns;
            })
            .catch(MachineColumnsLeadsService.handleError);
    }
}

