import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
/**
 * This class ensures that the user is logged in
 * It is used by those components that needs to check if user is logged in
 */
export class PlatformSelect implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (environment.enablePlatformSelect) {
            this.router.navigate(['/']);
        }
        return true;
    }
}
