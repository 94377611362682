import { Directive, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GeneralDetail } from '../../../helper-classes/general-detail';
import { ErrorEventService } from '../../../services/error-event.service';
import { LoadingEventService } from '../../../services/loading-event.service';
import { GeneralService } from '../../../services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralDetailEventService } from '../../../services/general-detail-event.service';
import { FieldsdetailsService } from '../../../services/fieldsdetails.service';
import { LeadsFieldsdetailsService } from '../../../../leads-platform-module/services/leadsFieldsdetails.service';
import { GeneralDetailEvent } from '../../../models/event';
import { StandardPrice } from '../../../models/standard-price';

@Directive()
export abstract class BaseStandardPricesDetailComponent extends GeneralDetail implements OnInit {

  item: StandardPrice;
  @ViewChild('fileInput', { static: false }) fileInput;
  extrafields;
  section: string = '';

  protected constructor(
    protected errorEventService: ErrorEventService,
    protected loadingEventService: LoadingEventService,
    protected generalService: GeneralService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected renderer: Renderer2,
    protected generalDetailHandler: GeneralDetailEventService,
    protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
    super(generalService, router, route, errorEventService, loadingEventService, renderer, generalDetailHandler);

    // afterOnSubmit
    this.subscriptions.push(
      this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterOnSubmit).subscribe(() => {
        this.fieldsdetailsService.getAllFieldsDetails(true);
      })
    );
  }

  ngOnInit() {
    // initialize the item
    this.item = {
      id: 0,
      model: '',
      battery_capacity: '',
      older_than_x_years: '',
      running_hours_higher_than: '',
      standard_trade_in_price: '',
    };

    // general initialization
    this.initialize();

    // breadcrumb elements
    this.breadcrumbs = [
      {
        name: 'api.home',
        url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
        isActive: false,
      },
      {
        name: 'api.standard_prices',
        url: this.platform + (this.section ? '/' + this.section : '') + '/standard-prices',
        isActive: false,
      },
      {
        name: 'api.add',
        url: '',
        isActive: true,
      }];

    // set extrafields received from the FieldsdetailsResolver todo
    // this.extrafields = this.route.snapshot.data.fieldsDetails.data.extrafield.filter(extrafield => extrafield.deleted_at == null);

  }

  /**
   * Override the GeneralDetail getItemDetail function including the extrafields update from the response
   */
  getItemDetail() {
    this.generalService.getItem(this.id).subscribe(
      data => {
        this.item = this.processItemResponse(data);
        // todo
        // this.extrafields = this.extrafields.filter(item => !this.item.extrafields.some(x => x.field_name === item));
        this.breadcrumbs[this.breadcrumbs.length - 1].name = 'api.edit';
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
