<div class="container">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
    <div class="row">
        <div class="col-sm-6">
            <h3 class="sales-heading">{{item.id > 0 ? ('api.edit' | translate) : ('api.add' | translate)}} {{title | translate}}</h3>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <form class="form-horizontal" #form (submit)="onSubmit()">

                <div class="form-group" *ngFor="let field of fields">
                    <label class="col-sm-4">{{fieldDetails[field].formattedName | translate}}</label>
                    <div class="col-sm-8" [ngSwitch]="fieldDetails[field].type">
                        <input *ngSwitchCase="'input'" type="text" class="form-control" [attr.type]="'text'" [attr.name]="field" [(ngModel)]="item[field]" [ngModelOptions]="{standalone: true}" />
                        <div *ngSwitchCase="'select'">
                            <select [attr.name]="field" class="form-control" [(ngModel)]="item[field + '_id']" (change)="onChange(field, $event.target.value)" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let option of selects[field]" [ngValue]="option.id" [attr.selected]="(item[field + '_id'] == option.id) ? 'selected' : ''">{{option[fieldDetails[field].option_attr]}}</option>
                            </select>
                        </div>
                        <div *ngSwitchCase="'select_date'">
                            <select [attr.name]="field" class="form-control" [(ngModel)]="item[field]" (change)="onChange(field, $event.target.value)" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let option of selects[field]" [ngValue]="option.id" [attr.selected]="(item[field] == option.id) ? 'selected' : ''">{{option[fieldDetails[field].option_attr]}}</option>
                            </select>

                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4"></label>
                    <div class="col-sm-8">
                        <input type="submit" name="submit" title="" class="buttons marginTop5" id="confirm" value="{{'api.confirm' | translate}}">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>