import {Injectable} from '@angular/core';
import {MkaApiRequest} from './mka-api-request.service';


@Injectable({
    providedIn: 'root'
})
export class ComparisionSetupService {

    constructor(private mkaApiRequest: MkaApiRequest){
    }

    setupComparision(data : any) {
        return this.mkaApiRequest.putRequest('settings', data);
    }

    getComparision() {
        return this.mkaApiRequest.getRequest('settings');
    }

}
