import { Directive, OnInit } from '@angular/core';
import {Column} from '../../../../internal-platform-module/models/column';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {GeneralList} from '../../../helper-classes/general-list';
import {GeneralDetailEvent} from '../../../models/event';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';

@Directive()
export abstract class BaseColumnsComponent extends GeneralList implements OnInit {

    title: string = 'api.columns';
    rows: Column[];
    selected_platform: string = '';
    section: string = '';

    protected constructor(protected generalService: GeneralService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected generalDetailHandler: GeneralDetailEventService,
                protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
        super(generalService, route, router, generalDetailHandler);
        /*this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterRefreshAllItem).subscribe(() => {
            const firstItem = this.rows.find((item) => item.id === 1);
            this.rows[this.rows.indexOf(firstItem)].name = 'api.other';
        });*/

        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterDelete).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );


    }

    ngOnInit() {

        this.route.params.subscribe(params => {
            this.selected_platform = params['platform'];
            this.generalService.setUri((this.section ? this.section + '_' : '') + 'columns/' + params['platform']);

            const basePath = this.route.snapshot.data.basePath;
            this.actionLink = basePath + '/columns/' + params['platform'];

            // general initialization
            this.initialize();
        });


        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.columns_platforms',
                url: this.platform + (this.section ? '/' + this.section : '') + '/columns_platforms',
                isActive: false,
            },
            {
                name: 'api.columns',
                url: '',
                isActive: true,
                additional: this.selected_platform
            }];

        // specify the listing columns
        this.columns = [
            {
                name: 'name',
                formattedName: 'api.name',
                pipes: ' '
            },
            {
                name: 'formattedName',
                formattedName: 'api.formattedName',
                pipes: ' '
            },
            {
                name: 'align',
                formattedName: 'api.align',
                pipes: 'translate'
            },
            {
                name: 'goToDetailPage',
                formattedName: 'api.goToDetailPage',
                pipes: 'boolean'
            },
            {
                name: 'searchable',
                formattedName: 'api.searchable',
                pipes: 'boolean'
            },

        ];
    }
}
