import { Injectable } from '@angular/core';
import {MkaApiRequest} from './mka-api-request.service';

@Injectable({
    providedIn: 'root'
})
export class FieldsdetailsService {

    public fields: any;

    static handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    constructor(private mkaApiRequest: MkaApiRequest) {
    }

    // used by FieldsdetailsResolver
    getAllFieldsDetails(force: boolean = false): Promise<any>|any {

        if (this.fields && !force) {
          localStorage.setItem('currency_exchanges', JSON.stringify(this.fields.data.currency_exchange));
           return this.fields;
        }
        return this.mkaApiRequest.getRequest('getFieldsDetails')
            .toPromise()
            .then((data: any) => {
                this.fields = {...this.fields, data: data.data};
                localStorage.setItem('currency_exchanges', JSON.stringify(data.data.currency_exchange));
                return this.fields;
            })
            .catch(FieldsdetailsService.handleError);
    }
}

