import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import { BaseStandardPricesComponent } from '../../abstract-classes/standard-prices/standard-prices.component';

@Component({
    selector: 'app-standard-prices',
    templateUrl: '../../abstract-classes/standard-prices/standard-prices.component.html',
    styleUrls: [
        '../../abstract-classes/standard-prices/standard-prices.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        GeneralService,
        {provide: 'uri',  useValue: 'standard-prices'},
    ],
})

export class StandardPricesComponent extends BaseStandardPricesComponent implements OnInit {

    section: string = '';

    constructor(protected generalService: GeneralService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected generalDetailHandler: GeneralDetailEventService,
                protected fieldsdetailsService: FieldsdetailsService) {
        super(generalService, route, router, generalDetailHandler, fieldsdetailsService);
    }
}
