<div class="container-fluid grayBg">
  <div class="container login-border">
    <div class="container-fluid">
      <div class="row">
        <form method="post" action="content-management.html">
          <div class="col-sm-8 col-sm-offset-2 paddingTop10 paddingBottom30">
            <h3 class="fontWeightBold">{{ 'api.login' | translate }}s</h3>
            <hr class="line">

            <div class="row">
              <div class="form-group login-form-group">
                <label class="col-xs-3 margin0">{{'api.username' | translate}}:</label>
                <div class="col-xs-9 col-sm-4">
                  <input [(ngModel)]="credentials.email" name="login_username" type="text" value="" title="" class="form-control" placeholder="{{'api.username' | translate}}" id="username" required="required">
                </div>
              </div>
            </div>

            <hr class="line">

            <div class="row">
              <div class="form-group login-form-group">
                <label class="col-xs-3 margin0">{{'api.password' | translate}}:</label>
                <div class="col-xs-9 col-sm-4">
                  <input [(ngModel)]="credentials.password" name="login_password" type="password" title="" value="" class="login-passkey form-control" placeholder="{{'api.password' | translate}}"
                    id="password" required="required">

                </div>
              </div>
            </div>
            <hr class="line">

            <div class="row">
              <div class="form-group">
                <div class="col-sm-4">
                  <input type="submit" (click)="login()" name="login_submit" title="" class="login-name buttons" id="login" value="Login">
                </div>

                <div class="col-sm-4 paddingTop15 paddingLeft0">
                  <a href="{{forgotPasswordLink}}" title="" class="lost" id="reset-password">{{ 'api.forgot_your_password' | translate }}</a>
                </div>
              </div>

            </div>

          </div>
        </form>

      </div>
    </div>


  </div>
</div>
