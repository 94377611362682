import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {AvailableColumnsService} from '../services/available-columns.service';

/**
 * Resolver to get all Fieldsdetails
 */
@Injectable({
    providedIn: 'root'
})
export class AvailableColumnsResolver implements Resolve<any> {
    constructor(
        private availableColumnsService: AvailableColumnsService
    ) {}

    resolve(route?: ActivatedRouteSnapshot): Promise<any> {
        return this.availableColumnsService.getAvailableColumns();
    }
}
