<ul *ngIf="headerMenuItems?.length > 0" class="nav navbar-nav navbar-right">
  <ng-container *ngFor="let headerMenuItem of headerMenuItems">
    <li *ngIf="!headerMenuItem.hide"
        class="{{headerMenuItem.children.length>0 ? 'dropdown' : ''}} {{withLogin?'hidden-xs':'mb'}}">
      <a *ngIf="headerMenuItem.url != ''" [routerLink]="[headerMenuItem.url]"
         class="dropdown-toggle {{(currentUrl == headerMenuItem.url)? 'active' :''}}" data-toggle="dropdown"
         role="button" aria-haspopup="true" aria-expanded="false">
        {{headerMenuItem.name | translate}}
        <span *ngIf="headerMenuItem.children.length>0" class="caret"></span>
      </a>
      <a *ngIf="headerMenuItem.url == ''"
         class="dropdown-toggle {{(currentUrl == headerMenuItem.url || lastListingUrl == headerMenuItem.url )? 'active' :''}}"
         data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
        {{headerMenuItem.name | translate}}
        <span *ngIf="headerMenuItem.children.length>0" class="caret"></span>
      </a>
      <ul *ngIf="headerMenuItem.children.length>0" class="dropdown-menu">
        <li *ngFor="let headerSubMenuItem of headerMenuItem.children">
          <a *ngIf="headerSubMenuItem.fragment" routerLink="{{headerSubMenuItem.url}}"
             fragment="{{headerSubMenuItem.fragment}}">{{headerSubMenuItem.name | translate}}</a>
          <a *ngIf="!headerSubMenuItem.fragment"
             routerLink="{{headerSubMenuItem.url}}">{{headerSubMenuItem.name | translate}}</a>
        </li>
        <li>
          <a *ngIf="role === 'admin'" style="cursor: pointer"
             data-toggle="modal"
             [attr.data-target]="'#exportTime'">{{'api.export_excel'| translate}}</a>
        </li>
      </ul>
    </li>
  </ng-container>
  <app-export-time-popup [modalId]="'exportTime'" (continue)="export($event)"></app-export-time-popup>
  <li class="dropdown" *ngIf="withLogin">
    <a href="javascript:;" (click)="logout();">
      <i class="fas fa-sign-out-alt" aria-hidden="true"></i>&nbsp;Logout
    </a>
  </li>
</ul>
