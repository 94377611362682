import {Component, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import { Type } from '../../../models/type';
import { Subcategory } from '../../../models/subcategory';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorEventService} from '../../../services/error-event.service';
import {GeneralService} from '../../../services/general.service';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {BaseTypeDetailComponent} from '../../abstract-classes/types/type-detail.component';


@Component({
    selector: 'app-type-detail',
    templateUrl: '../../abstract-classes/types/type-detail.component.html',
    styleUrls: [
        '../../abstract-classes/types/type-detail.component.scss'
    ],

    // create a separate instances of the GeneralService which will use the below uri and one for Subcategories
    providers: [
        { provide: 'instance1', useClass: GeneralService },
        { provide: 'instance2', useClass: GeneralService },
        {provide: 'uri',  useValue: 'types'},
    ],
})

export class TypeDetailComponent extends BaseTypeDetailComponent implements OnInit {

    section: string = '';

    constructor(
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        @Inject('instance1')protected generalService: GeneralService,
        @Inject('instance2')protected subcategoryService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService) {
        super(errorEventService,
            loadingEventService,
            generalService,
            subcategoryService,
            route,
            router,
            renderer,
            generalDetailHandler,
            fieldsdetailsService
        );
    }
}
