import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TransApiService } from '../../../shared/services/trans-api.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  public credentials: any = {
    email: '',
    password: ''
  };
  public scope: string;
  public pathAfterLogin: string;
  public forgotPasswordLink: string;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private transApi: TransApiService
  ) {
  }

  /**
   * this makes the API call to make login action
   * saves the new token
   * sets the translation to the user’s language
   * sets the currency the the user’s currency
   * navigates the user to the first page (depending on the user type/ interface that the user is logged in)
   */
  login() {
    this.authService.login(this.credentials, <string>this.scope).subscribe(
      data => {
        const response = data.data;
        const redirect_to = JSON.parse(JSON.stringify(localStorage.getItem('redirect_to')));
        this.transApi.changeLocale(response.user.language);
        this.authService.saveNewToken(response.token, response.supportUrl);
        this.authService.saveUser(response.user, response.dealer_settings, response.trader);
        localStorage.currentCurrency = response.user.currency;
        delete localStorage.redirect_to;

        if (redirect_to) {
          this.pathAfterLogin = redirect_to;
          const splitPath = redirect_to.split('/');
          switch (response.user.user_type) {
            case 'admin':
              localStorage.platform = '/admin-platform';
              splitPath[0] = 'admin-platform';
              this.pathAfterLogin = splitPath.join('/');
              break;
            case 'sales_manager':
              localStorage.platform = '/internal-platform';
              splitPath[0] = 'internal-platform';
              this.pathAfterLogin = splitPath.join('/');
              break;
            case 'salesman':
              localStorage.platform = '/sales-platform';
              splitPath[0] = 'sales-platform';
              this.pathAfterLogin = splitPath.join('/');
              break;
            case 'trader':
              localStorage.platform = '/trader-platform';
              splitPath[0] = 'trader-platform';
              this.pathAfterLogin = splitPath.join('/');
              break;
            case 'guest':
              localStorage.platform = '/guest-platform';
              splitPath[0] = 'guest-platform';
              this.pathAfterLogin = splitPath.join('/');
              break;
            default:
              localStorage.platform = '/' + splitPath[0];
              this.pathAfterLogin = splitPath.join('/');
          }
          this.router.navigate([this.pathAfterLogin]);
          return;
        }

        switch (response.user.user_type) {
          case 'admin':
            localStorage.platform = 'admin-platform/';
            this.pathAfterLogin = 'admin-platform/';
            break;
          case 'sales_manager':
            localStorage.platform = 'internal-platform/';
            this.pathAfterLogin = 'internal-platform/machines';
            break;
          case 'salesman':
            localStorage.platform = 'sales-platform/';
            this.pathAfterLogin = 'sales-platform/my-machines';
            break;
          case 'trader':
            localStorage.platform = 'trader-platform/';
            this.pathAfterLogin = 'trader-platform/bidding-overview';
            break;
          case 'guest':
            localStorage.platform = 'guest-platform/';
            this.pathAfterLogin = 'guest-platform/';
            break;
          default:
            this.pathAfterLogin = '';
        }
        this.router.navigate([this.pathAfterLogin]);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * interprets the parameters
   * sets scope,the path that the user is taken after login, and the forgot password link
   */
  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.scope = 'web-api';
      this.pathAfterLogin = ''; // data.pathAfterLogin;
      this.forgotPasswordLink = data.forgotPasswordLink;
    });
  }
}
