<div class="container">
    <app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>


    <div class="row">
        <div class="col-sm-6">
            <h3 class="sales-heading"></h3>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <form class="form-horizontal" #form>
                <div class="form-group">
                    <label for="company_id" class="col-sm-4">{{ 'api.company' | translate }}</label>
                    <div class="col-sm-8">
                        <select [attr.name]="'company_id'" class="form-control" [(ngModel)]="item.company_id" [ngModelOptions]="{standalone: true}" id="company_id">
                            <option *ngFor="let company of companies" [value]="company.id"
                                    [selected]="(item.company_id == company.id) ? 'selected' : ''">{{company.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="name" class="col-sm-4">{{ 'api.name' | translate }}</label>
                    <div class="col-sm-8">
                        <input type="text" name="name" class="form-control" id="name"
                               placeholder="{{ 'api.name' | translate }}"
                               [(ngModel)]="item.name">
                    </div>
                </div>

                <div class="form-group">
                    <label for="email" class="col-sm-4">{{ 'api.email' | translate }}</label>
                    <div class="col-sm-8">
                        <input type="email" name="email" class="form-control" id="email"
                               placeholder="{{ 'api.email' | translate }}"
                               [(ngModel)]="item.email">
                    </div>
                </div>
                <div class="form-group">
                    <label for="mobile" class="col-sm-4">{{ 'api.mobile' | translate }}</label>
                    <div class="col-sm-8">
                        <input type="text" name="mobile" class="form-control" id="mobile"
                               placeholder="{{ 'api.mobile' | translate }}" [(ngModel)]="item.mobile">
                    </div>
                </div>

                <div class="form-group">
                    <label for="function" class="col-sm-4">{{ 'api.function' | translate }}</label>
                    <div class="col-sm-8">
                        <input type="text" name="function" class="form-control" id="function"
                               placeholder="{{ 'api.function' | translate }}" [(ngModel)]="item.function">
                    </div>
                </div>

                <div class="form-group">
                    <label for="role" class="col-sm-4">{{ 'api.authorisation' | translate }}</label>
                    <div class="col-sm-8">
                        <select id="role" name="role" class="form-control" [(ngModel)]="item.role">
                            <option *ngFor="let role of roles" [ngValue]="role.id" [selected]="role.id === item.role">
                                {{'api.' + role.name | translate}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label for="currency" class="col-sm-4">{{ 'api.currency' | translate }}</label>
                    <div class="col-sm-8">
                        <select id="currency" [attr.name]="'currency'" class="form-control" [(ngModel)]="item.currency"
                                [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let item of currencies" [value]="item"
                                    [selected]="(item.currency == item) ? 'selected' : ''">{{item}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group" *ngIf="dealers">
                    <label for="dealer_id" class="col-sm-4">{{ 'api.dealer' | translate }}</label>
                    <div class="col-sm-8">
                        <select [attr.name]="'dealer_id'" class="form-control" [(ngModel)]="item.dealer_id"
                                [ngModelOptions]="{standalone: true}" id="dealer_id">
                            <option *ngFor="let dealer of dealers" [value]="dealer.id"
                                    [selected]="(item.dealer_id == dealer.id) ? 'selected' : ''">{{dealer.name}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group" *ngIf="isSalesManager()">
                    <label for="receive_bid_insertion_email" class="col-sm-4">{{ 'api.receive_bid_insertion_email' | translate }}</label>
                    <div class="col-sm-8">
                        <select [attr.name]="'receive_bid_insertion_email'" class="form-control" [(ngModel)]="item.receive_bid_insertion_email" [ngModelOptions]="{standalone: true}" id="receive_bid_insertion_email">
                            <option value="0"> {{'api.no' | translate}} </option>
                            <option value="1"> {{'api.yes' | translate}} </option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4"></label>
                    <div class="col-sm-8">
                        <input type="submit" title="" class="login-name buttons marginTop5" id="confirm"
                               value="{{ 'api.confirm' | translate }}" (click)="onSubmit()">
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
