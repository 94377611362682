<!-- Modal -->
<div class="modal fade" id="{{modalId}}" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">{{'api.change_bid' | translate}}</h4>
      </div>
      <div class="modal-body">
        <div class="container-fluid row" *ngIf="selectedBid !== null">
          <div class="form-group row">
            <label class="col-sm-4">{{'api.company_name' | translate}}</label>
            <div class="col-sm-8">{{selectedBid.trader.company_name}}</div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4">{{'api.value' | translate}}</label>
            <div class="col-sm-8">
              <div class="row" style="display: flex;justify-content: center;align-items: center;">
                <span class="col-sm-2">{{selectedBid?.value_currency}}</span>
                <input type="number" name="value" [(ngModel)]="selectedBid.value" class="form-control col-sm-8" />
                <span class="col-sm-2" *ngIf="currentCurrency != selectedBid?.value_currency"
                      tooltip="{{selectedBid?.value}} {{selectedBid?.value_currency}}"
                      placement="left">
                    <span class="fa fa-money"></span>
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal"
                (click)="save()">{{'api.save' | translate}}</button>
      </div>
    </div>
  </div>
</div>
