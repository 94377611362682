import {Injectable} from '@angular/core';
import {MkaApiRequest} from './mka-api-request.service';


@Injectable({
    providedIn: 'root'
})
export class ContactFormService {

    constructor(private mkaApiRequest: MkaApiRequest){
    }

    sendContactForm(email: string, name: string, phone: string, description: string, attachment: any) {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('description', description);

        if (attachment.files) {
            for (let i = 0; i < attachment.files.length; ++i) {
                const file = attachment.files[i];
                formData.append('attachment[]', file, file.name);
            }
        }
        return this.mkaApiRequest.postRequest('contactForm', formData);
    }

}
