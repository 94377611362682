/**
 * Hardcoded values
 */
export class GeneralFields {

    public static GENERAL_FIELDS = [
        'category',
        'type',
        'subcategory',
    ];
    public static FIELD_DETAILS = {
        'category': {
            formattedName: 'api.category',
            colspan: false,
            type: 'select',
            option_attr: 'translated_name',
            dependent_to: null,
        },
        'type': {
            formattedName: 'api.type',
            colspan: false,
            type: 'select',
            option_attr: 'translated_name',
            dependent_to: null,
        },
        'subcategory': {
            formattedName: 'api.subcategory',
            colspan: false,
            type: 'select',
            option_attr: 'translated_name',
            dependent_to: null,
        },
    };

    public static INTERNAL_MACHINES_SORTBY = [
        {
            value: 'created_at_desc',
            name: 'api.date_intake_desc'
        },
        {
            value: 'created_at_asc',
            name: 'api.date_intake_asc'
        },
        {
            value: 'id_asc',
            name: 'api.id_asc'
        },
        {
            value: 'id_desc',
            name: 'api.id_desc'
        },
    ];

    public static GROUP = {
        'type_salesman': 1,
        'type_trader': 2
    };

    public static ATTRIBUTE_TYPES = {
        1: {id: 1, name: 'api.checkbox'},
        2: {id: 2, name: 'api.select'},
        3: {id: 3, name: 'api.select_with_other'},
        4: {id: 4, name: 'api.dropdown_year'},
        5: {id: 5, name: 'api.number'},
        6: {id: 6, name: 'api.input'},
        7: {id: 7, name: 'api.rating'},
        8: {id: 8, name: 'api.textarea'},
        9: {id: 9, name: 'api.select_date'},
    };

    public static SPECIFICATION_TYPES_SIMPLE = {
        0: {id: 0, name: 'api.none'},
        1: {id: 1, name: 'api.simple'},
        3: {id: 3, name: 'api.simple_min'}
    };

    public static SPECIFICATION_TYPES_FROM_TO = {
        0: {id: 0, name: 'api.none'},
        1: {id: 1, name: 'api.simple'},
        2: {id: 2, name: 'api.from_to'},
        3: {id: 3, name: 'api.simple_min'},
        4: {id: 4, name: 'api.from_to_min'},
    };

    public static SHOW_TYPES = [
        {id: 1, name: 'api.dropdown'},
        {id: 2, name: 'api.segment'},
    ];
}
