import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { TranslatePipe } from '@ngx-translate/core';
import { GeneralDetailEvent, TableFilterEvent } from '../../models/event';
import { TableFilterEventService } from '../../services/table-filter-event.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { Subject , of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})

export class TableFilterComponent implements OnInit {
  @Input() type: any;
  @Input() column: any;
  @Input() column_name: any;
  @Input() column_searchable: any;
  @Input() tableFilter: any = [];

  @Output() onFilterChange = new EventEmitter<any>();

  public select_options: any[] = [];
  protected fieldDetails: any;
  private searchSubject = new Subject<string>();


  current_year = new Date().getFullYear();

  currency_exchanges: any;
  currentCurrency = localStorage.currentCurrency;

  startDate = 1998;

  endDate = this.current_year;

  filter_val;
  fromValue;
  toValue;

  FILTER_TYPE_INPUT = 1;
  FILTER_TYPE_SELECT = 2;
  FILTER_TYPE_SELECT_YEAR = 3;
  FILTER_TYPE_SELECT_DATE = 4;
  FILTER_TYPE_SELECT_BOOL = 5;
  FILTER_TYPE_RANGE_PRICE = 6;
  FILTER_TYPE_RANGE_RATING = 7;
  FILTER_TYPE_MULTI_SELECT = 8;
  FILTER_TYPE_RANGE = 9;

  range_price_min = 0;
  range_price_max = 0;

  dropdownSettings = {};

  public daterange: any = {};

  start = moment();
  end = moment();

  showPopup: boolean = false;
  filtered: boolean = false;

  // see original project for full list of options
  // can also be setup using the config service to apply to multiple pickers
  public options: any = {
    startDate: this.start,
    endDate: this.end,
    ranges: {
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: true,
      opens: 'left',
      inline: true,
      ranges: {
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
      }
    }
  };

  range_price_max_option: any = {
    floor: this.range_price_min,
    ceil: this.range_price_max,
    step: 10,
    translate: (value: number): string => {
      return value + ' ' + this.currentCurrency;
    }
  };

  range_rating_option: any = {
    floor: 1,
    ceil: 10,
    step: 1
  };

  notSearchable: boolean = true;

  constructor(
    protected route: ActivatedRoute,
    public translatePipe: TranslatePipe,
    public tableFilterHandler: TableFilterEventService
  ) {
    this.tableFilterHandler.subscribeToEvent(TableFilterEvent.openPopup).subscribe((x) => {
      if (x !== this.column_name) {
        this.showPopup = false;
      }
    });
    this.searchSubject.pipe(
      debounceTime(300),         // Wait for 300ms after the last keystroke
      distinctUntilChanged(),    // Only trigger when the value changes
      switchMap(searchTerm => {
        // Wrap the result of getFilterValueSimple in an Observable
        return of(this.getFilterValueSimple(searchTerm));
      })
    ).subscribe();
  }

  private createSelect(min: number, max: number) {
    const range: any = [];
    const start = (min) ? min :
      (
        this.route.snapshot.data.fieldsDetails.data.year_selector_min_value ?
          this.route.snapshot.data.fieldsDetails.data.year_selector_min_value :
          1978
      );
    const stop = (max) ? max : ((new Date()).getFullYear());
    for (let i = start; i <= stop; i++) {
      range.push({ id: i, text: i.toString() });
    }

    return range;
  }

  ngOnInit() {
    if (typeof this.route.snapshot.data.fieldsDetails !== 'undefined') {
      this.currency_exchanges = this.route.snapshot.data.fieldsDetails.data.currency_exchange;
    }
    let localcolumn;
    let option_name_field;
    switch (this.type) {
      case this.FILTER_TYPE_SELECT_YEAR:
        // const options =  TableFilterComponent.createSelect(null, null);
        this.select_options = this.createSelect(null, null);
        this.notSearchable = false;
        break;
      case this.FILTER_TYPE_MULTI_SELECT:
        switch (this.column_name) {
          case 'type':
            const type = this.route.snapshot.data.fieldsDetails.data.type;

            for (const option of type) {
              if (option.deleted_at) {
                continue;
              }
              this.select_options.push({
                id: option.id.toString(),
                text: option.translated_name
              });
            }
            break;
          case 'category':
            const category = this.route.snapshot.data.fieldsDetails.data.category;

            this.select_options = [];
            for (const option of category) {
              if (option.deleted_at) {
                continue;
              }
              this.select_options.push({
                id: option.id.toString(),
                text: option.translated_name
              });
            }
            break;
          case 'subcategory':
            const subcategory = this.route.snapshot.data.fieldsDetails.data.subcategory;

            this.select_options = [];
            for (const option of subcategory) {
              if (option.deleted_at) {
                continue;
              }
              this.select_options.push({
                id: option.id.toString(),
                text: option.translated_name
              });
            }
            break;
          case 'incoming_status':
            const incoming_status = this.route.snapshot.data.fieldsDetails.data.machine_incoming_statuses;

            for (const option of incoming_status) {

              this.select_options.push({
                id: option.id.toString(),
                text: option.translatedName
              });
            }
            break;
          case 'outgoing_status':
            const outgoing_status = this.route.snapshot.data.fieldsDetails.data.machine_outgoing_statuses;

            for (const option of outgoing_status) {

              this.select_options.push({
                id: option.id.toString(),
                text: option.translatedName
              });
            }
            break;
          case 'owner':
            const owner = this.route.snapshot.data.fieldsDetails.data.salesman_owner;

            for (const option of owner) {

              this.select_options.push({
                id: option.id.toString(),
                text: option.name
              });
            }
            break;
          case 'owner_company':
            const companies = this.route.snapshot.data.fieldsDetails.data.companies;

            for (const option of companies) {

              this.select_options.push({
                id: option.id.toString(),
                text: option.name
              });
            }
            break;
          case 'status':
            const bid_statuses = this.route.snapshot.data.fieldsDetails.data.bid_statuses;

            for (const option of bid_statuses) {

              this.select_options.push({
                id: option.id.toString(),
                text: option.name
              });
            }
            break;
          default:
            localcolumn = this.route.snapshot.data.fieldsDetails.data[this.column_name];
            if (typeof localcolumn !== 'undefined') {
              option_name_field =
                ((typeof this.route.snapshot.data.fieldsDetails.data.attributes.find(
                    x => x.attribute_name == this.column_name) !== 'undefined') &&
                  (this.route.snapshot.data.fieldsDetails.data.attributes.find(
                    x => x.attribute_name == this.column_name)).translatable == 0
                ) ?
                  'name' :
                  'translated';
              if (typeof localcolumn !== 'undefined') {
                for (const option of localcolumn) {

                  this.select_options.push({
                    id: option.id.toString(),
                    text: option[option_name_field]
                  });
                }
              }
            }
        }


        this.notSearchable = false;
        break;

      case this.FILTER_TYPE_SELECT:
        localcolumn = this.route.snapshot.data.fieldsDetails.data[this.column_name];
        option_name_field =
          ((this.route.snapshot.data.fieldsDetails.data.attributes.find(
            x => x.attribute_name == this.column_name
          )).translatable == 1) ?
            'translated' :
            'name';
        if (typeof localcolumn !== 'undefined') {
          for (const option of localcolumn) {

            this.select_options.push({
              id: option.id.toString(),
              text: option[option_name_field]
            });
          }
        }
        this.notSearchable = false;
        break;

      case this.FILTER_TYPE_RANGE:
        if (this.route.snapshot.data.fieldsDetails.data[this.column_name]) {
          /*this.range_price_min = this.route.snapshot.data.fieldsDetails.data[this.column_name].min;
          this.range_price_max = this.route.snapshot.data.fieldsDetails.data[this.column_name].max;*/
          this.range_price_min = this.column.min;
          this.range_price_max = this.column.max;
        }
        this.notSearchable = false;
        break;
      case this.FILTER_TYPE_RANGE_PRICE:

        // if (this.route.snapshot.data.fieldsDetails.data[this.column_name]) {
        if (typeof this.column.min != 'undefined') {
          this.range_price_min = this.convertCurrency(
            // this.route.snapshot.data.fieldsDetails.data[this.column_name].min,
            this.column.min,
            'EUR',
            this.currentCurrency);
          this.range_price_max = this.convertCurrency(
            // this.route.snapshot.data.fieldsDetails.data[this.column_name].max,
            this.column.max,
            'EUR',
            this.currentCurrency);
        }
        this.notSearchable = false;
        break;
      case this.FILTER_TYPE_SELECT_BOOL:
        this.select_options.push({
          id: 1,
          text: this.translatePipe.transform('api.true')
        });
        this.select_options.push({
          id: 0,
          text: this.translatePipe.transform('api.false')
        });
        this.notSearchable = false;
        break;
      case this.FILTER_TYPE_INPUT:
      case this.FILTER_TYPE_SELECT_DATE:
      case this.FILTER_TYPE_RANGE_RATING:
        this.notSearchable = false;
        break;
      default:
        this.notSearchable = true;
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      defaultOpen: true
    };
  }

  public selectedDate(value: any) {
    // or manupulat your own internal property
    this.daterange.start = value.startDate;
    this.daterange.end = value.endDate;
    this.daterange.label = value.chosenLabel;

    const start_date = moment(value.startDate._d).format('Y-MM-DD');
    const end_date = moment(value.endDate._d).format('Y-MM-DD');

    const attribute_id = this.tableFilter.find(x => x.attr_id === this.column_name);
    const index = this.tableFilter.indexOf(attribute_id);

    if (attribute_id) {
      this.tableFilter[index] = {
        attr_id: this.column_name,
        'start_date': start_date,
        'end_date': end_date
      };
      this.filtered = true;
    } else {
      this.tableFilter.push({
        attr_id: this.column_name,
        'start_date': start_date,
        'end_date': end_date
      });
      this.filtered = true;
    }

    this.onFilterChange.emit({ table_filter: this.tableFilter });
    this.showPopup = false;

  }
  clearSelectedDate() {
    this.daterange.start = null;
    this.daterange.end = null;
    this.daterange.label = '';
    this.showPopup = false;
    this.filtered = false;
    const attribute_id = this.tableFilter.find(x => x.attr_id === this.column_name);
    const index = this.tableFilter.indexOf(attribute_id);
    this.tableFilter.splice(index, 1);
    this.onFilterChange.emit({ table_filter: this.tableFilter });
  }

  getFilterValue(val) {
    const attribute_id = this.tableFilter.find(x => x.attr_id === this.column_name);
    const index = this.tableFilter.indexOf(attribute_id);
    let searchable = '';

    if (this.column_searchable) {
      searchable = this.column_searchable;
    }

    const new_val = Array();

    for (const id of val) {

      new_val.push(this.select_options.find(x => x.id == id));
    }

    if (attribute_id) {
      if (new_val.length !== 0) {
        this.tableFilter[index] = {
          attr_id: this.column_name,
          'val': new_val,
          'searchable': searchable
        };
        this.filtered = true;
      } else {
        this.tableFilter.splice(index, 1);
        this.filtered = false;
      }
    } else {
      this.tableFilter.push({
        attr_id: this.column_name,
        'val': new_val,
        'searchable': searchable
      });
      this.filtered = true;
    }

    this.onFilterChange.emit({ table_filter: this.tableFilter });
  }

  getFilterValueSimple(val) {


    const attribute_id = this.tableFilter.find(x => x.attr_id === this.column_name);
    const index = this.tableFilter.indexOf(attribute_id);
    let searchable = '';

    if (this.column_searchable) {
      searchable = this.column_searchable;
    }

    if (attribute_id) {
      if (val == null) {
        this.tableFilter.splice(index, 1);
        this.filtered = false;
      } else if (val.length !== 0) {
        this.tableFilter[index] = {
          attr_id: this.column_name,
          'val': val,
          'searchable': searchable
        };
        this.filtered = true;
      } else {
        this.tableFilter.splice(index, 1);
        this.filtered = false;
      }
    } else {
      this.tableFilter.push({
        attr_id: this.column_name,
        'val': val,
        'searchable': searchable
      });
      this.filtered = true;
    }

    this.onFilterChange.emit({ table_filter: this.tableFilter });
  }

  selectedYear(start, end) {
    const attribute_id = this.tableFilter.find(x => x.attr_id === this.column_name);
    const index = this.tableFilter.indexOf(attribute_id);

    if (attribute_id) {
      this.tableFilter[index] = {
        attr_id: this.column_name,
        'start_date': (start !== null) ? start : 1900,
        'end_date': (end !== null) ? end : 2100
      };
      this.filtered = true;
    } else {
      this.tableFilter.push({
        attr_id: this.column_name,
        'start_date': (start !== null) ? start : 1900,
        'end_date': (end !== null) ? end : 2100
      });
      this.filtered = true;
    }

    this.onFilterChange.emit({ table_filter: this.tableFilter });
  }

  myOnFinish(range_event, ifPrice = false) {

    const attribute_id = this.tableFilter.find(x => x.attr_id === this.column_name);
    const index = this.tableFilter.indexOf(attribute_id);

    this.fromValue = range_event.from;
    this.toValue = range_event.to;

    if (attribute_id) {
      this.tableFilter[index] = {
        attr_id: this.column_name,
        'from': this.fromValue,
        'to': this.toValue
      };
      this.filtered = true;
    } else {
      if (ifPrice) {
        this.tableFilter.push({
          attr_id: this.column_name,
          'from': this.convertCurrency(this.fromValue, this.currentCurrency, 'EUR'),
          'to': this.convertCurrency(this.toValue, this.currentCurrency, 'EUR')
        });
      } else {
        this.tableFilter.push({
          attr_id: this.column_name,
          'from': this.fromValue,
          'to': this.toValue
        });
      }
      this.filtered = true;
    }

    this.onFilterChange.emit({ table_filter: this.tableFilter });
  }

  public emptyPicker(e: any) {
    const attribute_id = this.tableFilter.find(x => x.attr_id === this.column_name);
    const index = this.tableFilter.indexOf(attribute_id);
    this.tableFilter.splice(index, 1);
    this.onFilterChange.emit({ table_filter: this.tableFilter });
    this.filtered = false;
  }

  selectAll() {
    this.filter_val = this.select_options.map(x => x.id);
    this.getFilterValue(this.filter_val);
  }

  searchDebounced(event: any) {
    this.searchSubject.next(event.target.value);
  }


  preventDefault(event) {
    this.showPopup = true;
    this.tableFilterHandler.emitEvent(TableFilterEvent.openPopup, this.column_name);
    event.stopPropagation();
  }

  closeSearch(event) {
    this.showPopup = false;
    event.stopPropagation();
  }

  // convert a price to user's currency
  public convertCurrency(value, fromCurrencyCode, toCurrencyCode) {
    const fromCurrency = this.currency_exchanges.find(x => x.currency === fromCurrencyCode);
    const toCurrency = this.currency_exchanges.find(x => x.currency === toCurrencyCode);
    if (typeof toCurrency === 'undefined' || typeof fromCurrency === 'undefined') {
      return value;
    }
    return Math.round(value * toCurrency.value / fromCurrency.value * 100) / 100;
  }
}
