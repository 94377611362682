import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {FieldsdetailsService} from '../services/fieldsdetails.service';

/**
 * Resolver to get all Fieldsdetails
 */
@Injectable({
    providedIn: 'root'
})
export class FieldsdetailsResolver implements Resolve<any> {
    constructor(
        private fieldsdetailsService: FieldsdetailsService
    ) {}

    resolve(route?: ActivatedRouteSnapshot): Promise<any> {
        return this.fieldsdetailsService.getAllFieldsDetails();
    }
}
