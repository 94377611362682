<div class="container">
  <app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>

  <div class="row">
    <div class="col-sm-6">
      <h3 class="sales-heading"></h3>
    </div>
  </div>
  <div *ngIf="useRole === 'sales_manager'">
    <button
      *ngIf="mandatoryToggle"
      class="vertical-margin"
      (click)="handleMandatoryToggle()"
    >
      {{ "api.all_trader_fields" | translate }}
    </button>
    <button
      *ngIf="!mandatoryToggle"
      class="vertical-margin"
      (click)="handleMandatoryToggle()"
    >
      {{ "api.show_mandatory" | translate }}
    </button>
  </div>

  <div class="row">
    <form #form class="form-horizontal">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="company" class="col-sm-5">{{
            "api.company_name" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="company"
              name="company_name"
              placeholder="{{ 'api.company_name' | translate }}"
              [(ngModel)]="item.company_name"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="billing-address" class="col-sm-5">{{
            "api.billing_address" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="billing-address"
              placeholder="{{ 'api.billing_address' | translate }}"
              name="billing_address"
              [(ngModel)]="item.billing_address"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="postal" class="col-sm-5">{{
            "api.postal_code" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="postal"
              placeholder="{{ 'api.postal_code' | translate }}"
              name="billing_postcode"
              [(ngModel)]="item.billing_postcode"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="billing_place" class="col-sm-5">{{
            "api.place" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="billing_place"
              placeholder="{{ 'api.place' | translate }}"
              name="billing_place"
              [(ngModel)]="item.billing_place"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="billing_country" class="col-sm-5">{{
            "api.country" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="billing_country"
              placeholder="{{ 'api.country' | translate }}"
              name="billing_country"
              [(ngModel)]="item.billing_country"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="delivery_address" class="col-sm-5">{{
            "api.delivery_address" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="delivery_address"
              placeholder="{{ 'api.delivery_address' | translate }}"
              name="delivery_address"
              [(ngModel)]="item.delivery_address"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="delivery_postcode" class="col-sm-5">{{
            "api.postal_code" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="email"
              class="form-control"
              id="delivery_postcode"
              placeholder="{{ 'api.postal_code' | translate }}"
              name="delivery_postcode"
              [(ngModel)]="item.delivery_postcode"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="delivery_place" class="col-sm-5">{{
            "api.place" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="delivery_place"
              placeholder="{{ 'api.place' | translate }}"
              name="delivery_place"
              [(ngModel)]="item.delivery_place"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="delivery_country" class="col-sm-5">{{
            "api.country" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="delivery_country"
              placeholder="{{ 'api.country' | translate }}"
              name="delivery_country"
              [(ngModel)]="item.delivery_country"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="telephone" class="col-sm-5">{{
            "api.telephone" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="telephone"
              placeholder="{{ 'api.telephone' | translate }}"
              name="telephone"
              [(ngModel)]="item.telephone"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="fax" class="col-sm-5">{{ "api.fax" | translate }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="fax"
              placeholder="{{ 'api.fax' | translate }}"
              name="fax"
              [(ngModel)]="item.fax"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="email" class="col-sm-5">{{
            "api.email" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="{{ 'api.email' | translate }}"
              name="email"
              [(ngModel)]="item.email"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label class="col-sm-5">{{ "api.website" | translate }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="website"
              placeholder="{{ 'api.website' | translate }}"
              name="website"
              [(ngModel)]="item.website"
            />
          </div>
        </div>

        <div class="form-group top-margin">
          <label for="contact_lastname" class="col-sm-5">{{
            "api.contact_person" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="contact_lastname"
              placeholder="{{ 'api.contact_person' | translate }}"
              name="contact_lastname"
              [(ngModel)]="item.contact_lastname"
            />
          </div>
        </div>

        <div class="form-group">
          <label for="contact_firstname" class="col-sm-5">{{
            "api.first_name" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="contact_firstname"
              placeholder="{{ 'api.first_name' | translate }}"
              name="contact_firstname"
              [(ngModel)]="item.contact_firstname"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="function" class="col-sm-5">{{
            "api.function" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="email"
              class="form-control"
              id="function"
              placeholder="{{ 'api.function' | translate }}"
              name="contact_function"
              [(ngModel)]="item.contact_function"
            />
          </div>
        </div>

        <div class="form-group">
          <label for="contact_email" class="col-sm-5">{{
            "api.email" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="contact_email"
              placeholder="{{ 'api.email' | translate }}"
              name="contact_email"
              [(ngModel)]="item.contact_email"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="contact_telephone" class="col-sm-5">{{
            "api.telephone" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="contact_telephone"
              placeholder="{{ 'api.telephone' | translate }}"
              name="contact_telephone"
              [(ngModel)]="item.contact_telephone"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="contact_mobile" class="col-sm-5">{{
            "api.mobile" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="contact_mobile"
              placeholder="{{ 'api.mobile' | translate }}"
              name="contact_mobile"
              [(ngModel)]="item.contact_mobile"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="contact_website" class="col-sm-5">{{
            "api.website" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="contact_website"
              placeholder="{{ 'api.website' | translate }}"
              name="contact_website"
              [(ngModel)]="item.contact_website"
            />
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group" *ngIf="!mandatoryToggle">
          <div class="col-sm-12 paddingBottom10">
            <label>{{ "api.financial" | translate }}</label>
          </div>

          <label for="bank" class="col-sm-5">{{
            "api.iban_bank_account" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="bank"
              placeholder="{{ 'api.iban_bank_account' | translate }}"
              name="bank_account"
              [(ngModel)]="item.bank_account"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="swift" class="col-sm-5">{{
            "api.swift" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="swift"
              placeholder="{{ 'api.swift' | translate }}"
              name="swift"
              [(ngModel)]="item.swift"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="vat" class="col-sm-5">{{ "api.vat" | translate }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="vat"
              placeholder="{{ 'api.vat' | translate }}"
              name="vat_number"
              [(ngModel)]="item.vat_number"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="chamber" class="col-sm-5">{{
            "api.chamber_of_commerce" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="chamber"
              placeholder="{{ 'api.chamber_of_commerce' | translate }}"
              name="chamber_of_commerce"
              [(ngModel)]="item.chamber_of_commerce"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <div class="col-sm-12 paddingTop10 paddingBottom10">
            <label for="function">{{ "api.administration" | translate }}</label>
          </div>

          <label for="administration_lastname" class="col-sm-5">{{
            "api.contact_person" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="administration_lastname"
              placeholder="{{ 'api.contact_person' | translate }}"
              name="administration_lastname"
              [(ngModel)]="item.administration_lastname"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="administration_firstname" class="col-sm-5">{{
            "api.first_name" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="administration_firstname"
              placeholder="{{ 'api.first_name' | translate }}"
              name="administration_firstname"
              [(ngModel)]="item.administration_firstname"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="administration_email" class="col-sm-5">{{
            "api.email" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="administration_email"
              placeholder="{{ 'api.email' | translate }}"
              name="administration_email"
              [(ngModel)]="item.administration_email"
            />
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="administration_email_digital_invoice" class="col-sm-5">{{
            "api.email_digital_invoices" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="administration_email_digital_invoice"
              placeholder="{{ 'api.email_digital_invoices' | translate }}"
              name="administration_email_digital_invoice"
              [(ngModel)]="item.administration_email_digital_invoice"
            />
          </div>
        </div>

        <div class="form-group top-margin" *ngIf="!mandatoryToggle">
          <label for="customer-number" class="col-sm-5">{{
            "api.customer_number" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              id="customer-number"
              placeholder="{{ 'api.customer_number' | translate }}"
              name="customer_number"
              [(ngModel)]="item.customer_number"
            />
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-5">{{ "api.expert_in" | translate }}</label>
          <div class="col-sm-7">
            <app-mka-tag-input
              [(model)]="item.expert_in"
              [ngModelOptions]="{ standalone: true }"
              [maxItems]="4"
              [modelAsStrings]="true"
              [placeHolder]="'api.expert_in' | translate"
            >
            </app-mka-tag-input>
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label class="col-sm-5">{{ "api.currency" | translate }}</label>
          <div class="col-sm-7">
            <select
              [attr.name]="'currency'"
              class="form-control"
              [(ngModel)]="item.currency"
              [ngModelOptions]="{ standalone: true }"
            >
              <option
                *ngFor="let item of currencies"
                [value]="item"
                [selected]="item.currency == item ? 'selected' : ''"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" *ngIf="!mandatoryToggle">
          <label for="has_dealer_inventory" class="col-sm-5">{{
            "api.see_dealer_inventory_tab" | translate
          }}</label>
          <div class="col-sm-7">
            <select
              id="has_dealer_inventory"
              name="role"
              class="form-control"
              [(ngModel)]="item.has_dealer_inventory"
            >
              <option value="0">{{ "api.no" | translate }}</option>
              <option value="1">{{ "api.yes" | translate }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-sm-12"></div>

      <div class="col-sm-6">
        <div class="form-group">
          <label class="col-sm-5"></label>
          <div class="col-sm-7">
            <input
              type="submit"
              name="login_submit"
              title=""
              class="login-name buttons"
              id="submit"
              value="{{ 'api.confirm' | translate }}"
              (click)="onSubmit()"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
