import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot} from '@angular/router';
import {ExtraFieldsService} from '../services/extra-fields.service';
import {GeneralService} from '../services/general.service';

import {CompanyListService} from '../services/company-list.service';


/**
 * Resolver to get all Companies
 */
@Injectable({
    providedIn: 'root'
})
export class CompanyListResolver implements Resolve<any> {
    constructor(
        private companyListService: CompanyListService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<any> | any {
        return this.companyListService.getAll();
    }
}
