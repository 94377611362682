import { Component, OnInit, Renderer2 } from '@angular/core';
import { Errorhandler } from 'app/shared/helper-classes/errorhandler';
import { ComparisionSetupService } from 'app/shared/services/comparison-setup.service';
import { ContactFormService } from 'app/shared/services/contact-form.service';
import { ErrorEventService } from 'app/shared/services/error-event.service';

@Component({
    selector: 'app-comparison-setup',
    templateUrl: './comparison-setup.component.html',
    styleUrls: ['./comparison-setup.component.scss']
})
export class ComparisonSetupComponent extends Errorhandler implements OnInit {

    breadcrumbs: { name: string; url: string; isActive: boolean; additional?: string }[];
    constructor(private comparisonServie: ComparisionSetupService, protected errorEventService: ErrorEventService, protected renderer: Renderer2) {
            super(errorEventService, renderer);
        }

    selected_platform: string = '';
    platform: string = 'admin';
    section: string = 'comparison-setup';

    comparisonSetup: {
        selling_prices: boolean,
        traders_bids: boolean,
        salesman_bids: boolean,
        trade_price: boolean,
    } = {
        selling_prices: false,
        traders_bids: false,
        salesman_bids: false,
        trade_price: false,
    }



    ngOnInit(): void {

        this.comparisonServie.getComparision().subscribe(
            data => {
                const response = data.data;
                this.comparisonSetup = {
                    trade_price: response.comparison_trade_prices == 1 ? true : false,
                    selling_prices: response.comparison_selling_prices == 1 ? true : false,
                    traders_bids: response.comparison_traders_bids == 1 ? true : false,
                    salesman_bids: response.comparison_salesman_bids == 1 ? true : false,
                }
            },
            (error) => {
                console.log(error);
            });



        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.comparison_setup',
                url: '',
                isActive: true,
                additional: this.selected_platform
            }

        ];
    }

     // breadcrumb elements

     changeComparisonSetup(key , value){
        this.comparisonSetup[key] = value;
     }

     saveSetup(){
         let data = {
            comparison_trade_prices: this.comparisonSetup.trade_price,
            comparison_selling_prices: this.comparisonSetup.selling_prices,
            comparison_traders_bids: this.comparisonSetup.traders_bids,
            comparison_salesman_bids: this.comparisonSetup.salesman_bids,
         }
         this.comparisonServie.setupComparision(data).subscribe(
            data => {
                const response = data;
            },
            (error) => {
                console.log(error);
            });
     }

}
