// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//     production: false,
//     apiUrl: 'https://van-der-sluis.nextlevelvalue.com/api/v1/',
//     clientSecret: 'sjsG6R5D644wNJ9gd9wQ2Kzfx4XRBfl5dWMPAQfe',
//     toro_logo: '/assets/images/logo_vandersluis.jpg',
//     toro_banner_logo: '/assets/images/logo-pon.jpg',
//     toro_footer: '',
//     toro_slides: 'banner_pon.jpg',
//     has_admin: true,
//     enablePlatformSelect: false,
//     leads_platform: false,
//     platforms: false,
//     tradersHaveOwnMachines: false,
//     locales: ['en', 'nl'],
//     default_locale: 'en',
//     show_shared_between_dealerships_tab_for_sales: true,
//     showWholesalesPrice: false
// };


// abemec environment
// export const environment = {
//     production: false,
//     apiUrl: 'https://abemec-valuation.nextlevelvalue.com/api/v1/',
//     clientSecret: 'HtjA4Ja81E94hPGDwIxsXi0V9Yonz3YUleqXO2wI',
//     toro_logo: '/assets/images/logo_abemec.png',
//     toro_banner_logo: '/assets/images/logo_abemec.png',
//     toro_footer: '',
//     toro_slides: 'banner_abemec.png',
//     has_admin: true,
//     enablePlatformSelect: false,
//     leads_platform: false,
//     platforms: false,
//     tradersHaveOwnMachines: false,
//     locales: ['en', 'nl'],
//     default_locale: 'en',
//     show_shared_between_dealerships_tab_for_sales: true,
//     showWholesalesPrice: false
// };

// volvo environment
// export const environment = {
//     production: false,
//     apiUrl: 'https://volvogrouptruckcenter.nextlevelvalue.com/api/v1/',
//     clientSecret: 'TyAulCHjI2iKmJ4j0Mi9fih0TaWdKluMSAHYJVkH',
//     toro_logo: '/assets/images/logo_volvo.jpeg',
//     toro_banner_logo: '/assets/images/logo-pon.jpg',
//     toro_footer: '',
//     toro_slides: 'banner_volvo.png',
//     has_admin: true,
//     enablePlatformSelect: false,
//     leads_platform: false,
//     platforms: false,
//     tradersHaveOwnMachines: false,
//     locales: ['en', 'nl'],
//     default_locale: 'en',
//     show_shared_between_dealerships_tab_for_sales: true,
//     showWholesalesPrice: false
// };

// toyota environment
// export const environment = {
//     production: true,
//     apiUrl: 'https://api.nlvremarketingequipment.com/toyota/v1/',
//     clientSecret: 'tmSqAWSoPIX0n1WwE2rqbSFFHfTlR41mRvS1p6S1',
//     toro_logo: 'assets/images/logo-toyota.png',
//     toro_banner_logo: 'assets/images/logo_toyota.png',
//     toro_footer: ' Royal Reesink Landbouw en Agri Platform ',
//     toro_slides: 'banner/Picture_Toyota_one.jpg',
//     has_admin: true,
//     enablePlatformSelect: false,
//     leads_platform: false,
//     platforms: false,
//     tradersHaveOwnMachines: false,
//     locales: ['en', 'nl'],
//     default_locale: 'nl',
//     show_shared_between_dealerships_tab_for_sales: false,
//     showWholesalesPrice: true
// };

// troost environment
// export const environment = {
//     production: true,
//     apiUrl: 'https://api.remarketingequipment.com/troost/v1/',
//     clientSecret: 'tmSqAWSoPIX0n1WwE2rqbSFFHfTlR41mRvS1p6S1',
//     toro_logo: 'assets/images/logo-troost.jpg',
//     toro_banner_logo: 'assets/images/bannerlogotroost.jpg',
//     toro_footer: ' Royal Reesink Landbouw en Agri Platform ',
//     toro_slides: 'banner/Picture_Troost_one.jpg,banner/Picture_Troost_two.jpg',
//     has_admin: true,
//     enablePlatformSelect: false,
//     leads_platform: false,
//     platforms: false,
//     tradersHaveOwnMachines: false,
//     locales: ['en', 'nl'],
//     default_locale: 'nl',
//     show_shared_between_dealerships_tab_for_sales: true,
//     showWholesalesPrice: true
// };

// verachter env
// export const environment = {
//   production: false,
//   apiUrl: 'https://used-app.nextlevelvalue.com/api/v1/',
//   // apiUrl: 'http://localhost:8000/api/v1/',
//   clientSecret: 'qbMtWw9xMEW55yB3lGvmi7oMORn9MsqYTeQhypKi',
//   toro_logo: '/assets/images/logo-verachtert.png',
//   toro_banner_logo: '/assets/images/verachtert-logo.jpg',
//   toro_footer: ' Verachtert trader bid platform',
//   leads_platform: true,
//   toro_slides: 'banner3.jpg,banner3.jpg,banner3.jpg',
//   has_admin: true,
//   enablePlatformSelect: false,
//   appName: 'verachtert',
//   platforms: true,
//   tradersHaveOwnMachines: false,
//   locales: ['en', 'nl'],
//   default_locale: 'en',
//   showWholesalesPrice: false
// };

// pon env
// export const environment = {
//   production: false,
//   apiUrl: 'https://test-pon-usedapp.nextlevelvalue.com/api/v1/',
//   // apiUrl: 'http://localhost:8000/api/v1/',
//   clientSecret: 'qbMtWw9xMEW55yB3lGvmi7oMORn9MsqYTeQhypKi',
//   toro_logo: '/assets/images/logo-pon.jpg',
//   toro_banner_logo: '/assets/images/logo-pon.jpg',
//   toro_footer: '',
//   toro_slides: 'banner_pon.jpg',
//   has_admin: true,
//   enablePlatformSelect: false,
//   leads_platform: false,
//   platforms: false,
//   tradersHaveOwnMachines: false,
//   locales: ['en', 'nl'],
//   default_locale: 'en',
//   show_shared_between_dealerships_tab_for_sales: true,
//   showWholesalesPrice: false
// };


// toyota env
export const environment = {
  production: true,
  apiUrl: 'https://api.nlvremarketingequipment.com/toyota/v1/',
  clientSecret: 'tmSqAWSoPIX0n1WwE2rqbSFFHfTlR41mRvS1p6S1',
  clientId: 2,
  toro_logo: 'assets/images/logo-toyota.png',
  toro_banner_logo: 'assets/images/logo_toyota.png',
  toro_footer: ' Royal Reesink Landbouw en Agri Platform ',
  toro_slides: 'banner/Picture_Toyota_one.jpg',
  has_admin: true,
  enablePlatformSelect: false,
  leads_platform: false,
  platforms: false,
  tradersHaveOwnMachines: false,
  locales: ['en', 'nl'],
  default_locale: 'nl',
  show_shared_between_dealerships_tab_for_sales: false,
  showWholesalesPrice: true
};
