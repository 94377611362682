import { Directive, OnInit } from '@angular/core';
import {Category} from '../../../models/category';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoryService} from '../../../services/category.service';
import {GeneralList} from '../../../helper-classes/general-list';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {GeneralDetailEvent} from '../../../models/event';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';

@Directive()
export abstract class BaseCategoriesComponent extends GeneralList implements OnInit {

    rows: Category[];
    section: string = '';

    protected constructor(protected categoryService: CategoryService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected generalDetailHandler: GeneralDetailEventService,
                protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
        super(categoryService, route, router, generalDetailHandler);

        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterDelete).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );
    }


    ngOnInit() {
        // specify the listing columns
        this.columns = [
            {
                name: 'id',
                formattedName: 'api.id'
            },
            {
                name: 'name',
                formattedName: 'api.name'
            },
            {
                name: 'image_url',
                formattedName: 'api.image',
                pipes: 'image:100'
            }
        ];

        // general initialization
        this.initialize();

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.categories',
                url: '',
                isActive: true,
            }];
    }
}
