import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './shared/services/auth.service';
import { ErrorEventService } from './shared/services/error-event.service';
import { FieldsdetailsService } from './shared/services/fieldsdetails.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { TransApiService } from './shared/services/trans-api.service';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { GeneralDetailEventService } from './shared/services/general-detail-event.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingEventService } from './shared/services/loading-event.service';
import { Global } from './shared/helper-classes/global';
import { ContactFormService } from './shared/services/contact-form.service';
import { MachineColumnsService } from './internal-platform-module/services/machine-columns.service';
import { AvailableColumnsService } from './shared/services/available-columns.service';
import { HasAdminEnabled } from './shared/access-guards/has-admin-guard';
import { PlatformSelect } from './shared/access-guards/platform-select-guard';
import { MachineColumnsLeadsService } from './leads-platform-module/services/machine-columns-leads.service';
import { MachineListingService } from './shared/services/machineListing.service';
import { CustomErrorHandler } from './custom-error-handler';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MkaApiRequest } from './shared/services/mka-api-request.service';
import { ErrorComponent } from './shared/components/error/error.component';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { HttpClientModule } from '@angular/common/http';
import { NotifierModule } from 'angular-notifier';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    DragulaModule.forRoot(),
    ModalModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    NotifierModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    MkaApiRequest,
    AuthService,
    ErrorEventService,
    LoadingEventService,
    GeneralDetailEventService,
    FieldsdetailsService,
    MachineColumnsService,
    MachineColumnsLeadsService,
    AvailableColumnsService,
    TransApiService,
    ContactFormService,
    Global,
    HasAdminEnabled,
    PlatformSelect,
    MachineListingService,
    DragulaService


    // {
    //     provide: ErrorHandler,
    //     useClass: CustomErrorHandler,
    // },
  ],
  bootstrap: [AppComponent],
  exports: [
    ErrorComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // allow custom elements in ng-content (e.g <popup-body>)
  ]
})
export class AppModule {
}
