import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {ErrorEventService} from '../../../services/error-event.service';
import {LoadingEventService} from '../../../services/loading-event.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {
    BaseSelectAttributeOptionDetailComponent
} from '../../abstract-classes/select-attribute-options/select-attribute-option-detail.component';
import {environment} from '../../../../../environments/environment';
import {TransApiService} from '../../../services/trans-api.service';

@Component({
    selector: 'app-attribute-option-detail',
    templateUrl: '../../abstract-classes/select-attribute-options/select-attribute-option-detail.component.html',
    styleUrls: [
        '../../abstract-classes/select-attribute-options/select-attribute-option-detail.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        GeneralService,
        {provide: 'uri',  useValue: 'selects'},
    ],
})

export class SelectAttributeOptionDetailComponent extends BaseSelectAttributeOptionDetailComponent implements OnInit {

    section: string = '';
    public machine_links = (environment.has_admin) ? 'admin-platform/machines' : 'internal-platform/machines';

    constructor(
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected generalDetailHandler: GeneralDetailEventService,
        protected fieldsdetailsService: FieldsdetailsService,
        protected transApiService: TransApiService) {
        super(errorEventService,
            loadingEventService,
            generalService,
            route,
            router,
            renderer,
            generalDetailHandler,
            fieldsdetailsService,
            transApiService
        );
    }
}
