import { Directive, OnInit } from '@angular/core';
import {Column} from '../../../../internal-platform-module/models/column';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {GeneralList} from '../../../helper-classes/general-list';
import {GeneralDetailEvent} from '../../../models/event';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';

@Directive()
export abstract class BaseColumnsPlatformsComponent extends GeneralList implements OnInit {

    title: string = 'api.columns_platforms';
    rows: Column[];
    section: string = '';

    protected constructor(protected generalService: GeneralService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected generalDetailHandler: GeneralDetailEventService,
                protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
        super(generalService, route, router, generalDetailHandler);
        /*this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterRefreshAllItem).subscribe(() => {
            const firstItem = this.rows.find((item) => item.id === 1);
            this.rows[this.rows.indexOf(firstItem)].name = 'api.other';
        });*/

        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterDelete).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );


    }

    ngOnInit() {

        this.route.params.subscribe(params => {
            this.generalService.setUri((this.section ? this.section + '_' : '') + 'columns_platforms');

            // general initialization
            this.initialize();
        });


        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.columns_platforms',
                url: '',
                isActive: true,
            }];

        // specify the listing columns
        this.columns = [
            {
                name: 'platform',
                formattedName: 'api.platform',
                pipes: 'titlecase'
            },
        ];
    }

    rowClick(row) {
        const basePath = this.route.snapshot.data.basePath;
        this.router.navigate([basePath + '/columns/' + row.platform]);
    }
}
