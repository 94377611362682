<div class="container">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
    <div class="row">
        <div class="col-sm-6">
            <h3 class="sales-heading">{{item.id > 0 ? ('api.edit' | translate) : ('api.add' | translate)}} {{title | translate}}</h3>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <form class="form-horizontal" #form (submit)="onSubmit()">
                <div class="form-group">
                    <label class="col-sm-4">{{'api.value' | translate}}</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="name" name="name" placeholder="{{'api.value' | translate}}" [(ngModel)]="item.name">
                    </div>
                </div>
                <div *ngIf="item.attribute && item.attribute.translatable">
                    <div class="form-group" *ngFor="let trans of item.lang; let i=index">
                        <label class="col-sm-4">{{'api.value' | translate}} {{trans.lang}}</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="{{'api.value' | translate}}" name="lang[{{trans.lang}}]" [(ngModel)]="item.lang[i].value">
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4"></label>
                    <div class="col-sm-8">
                        <input type="submit" name="submit" title="" class="buttons marginTop5" id="confirm" value="{{'api.confirm' | translate}}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4">{{'api.used_by_machines' | translate}}:</label>
                    <div class="col-sm-8">
                        <span *ngFor="let id of item.machine_ids">
                            <a href="{{machine_links}}/{{id}}">{{id}}</a>
                        </span>
                    </div>
                </div>
            </form>


        </div>
    </div>
</div>