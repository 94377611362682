<div *ngIf="hasHeaderWithFilter" class="breadcrumbs marginbottom_5">
  <div class="breadcrumb ">
    <div class="rows" *ngIf="hasHeaderWithFilter">
          <ng-container *ngFor="let incoming_status of machine_incomming_statuses">
        <div class="col-sm-2 col-6 pl0 ">
          <input type="button" name="button" title="" class="status-filter-buttons buttons pull-left width100"
            [ngStyle]="{'background-color' :  selected_machine_incomming_status_filter == incoming_status.id ? '#C00F23' : 'grey'}"
                     style="text-overflow: ellipsis; overflow: hidden;"
                     tooltip="{{incoming_status.translatedName}}"
                     value="{{ incoming_status.translatedName}}" (click)="filterByIncommingStatus(incoming_status.id)">
            </div>
          </ng-container>
          <ng-container *ngFor="let outgoing_status of machine_outgoing_statuses">
        <div class="col-sm-2 col-6 pl0 " *ngIf="outgoing_status.id === 6">
          <input type="button" name="button" title="" class="status-filter-buttons buttons pull-left width100"
            [ngStyle]="{'background-color' :  selected_machine_outgoing_status_filter == outgoing_status.id ? '#C00F23' : 'grey'}"
                     style="text-overflow: ellipsis; overflow: hidden;"
                     tooltip="{{outgoing_status.translatedName}}"
                     value="{{ outgoing_status.translatedName}}" (click)="filterByOutgoingStatus(outgoing_status.id)">
            </div>
          </ng-container>
      <div class="col-sm-2 col-6 pl0 ">
        <input type="button" name="button" title="" class="status-filter-buttons buttons pull-left width100"
          [ngStyle]="{'background-color' :  selected_machine_incomming_status_filter == null && selected_machine_outgoing_status_filter == null ? '#C00F23' : 'grey'}"
                   value="{{ 'api.all' | translate}}" (click)="filterByIncommingStatus(null)">
          </div>
        </div>
  </div>
  <div style="display: flex; align-items: center; justify-content: space-between;">
    <div class="col-10 padding0 col-md-6 col-lg-4  " style="display: flex; gap: 10px;" >
      <form class="form-group padding0 marginBottom0" role="search">
        <div class="input-group search-group">
          <span class="input-group-addon search-icon" (click)="search(searchinput.value)">
            <i class="fa fa-search"></i>
          </span>
          <input type="text" class="form-control searchinput" #searchinput placeholder="{{'api.search' | translate}}"
            (keydown)="keyDownFunction($event, searchinput.value)" value="{{searchText}}">
          <span class="input-group-addon submit-icon" (click)="search(searchinput.value)">
            <i class="fa fa-arrow-right whiteImportant"></i>
          </span>
                </div>
            </form>
      <button
          *ngIf="table_filter.length >0"
          (click)="resetFilters()">{{ 'api.reload' | translate }}
      </button>
        </div>
    <div style="display: flex; align-items: center; justify-content: center; gap: 10px;">
      <div style="display: flex; align-items: center; justify-content: center;"
        *ngIf="numberOfPages > 1">
        <span (click)="changePage(1)" class="page">
          <i class="fas fa-angles-left"></i>
        </span>
                <div *ngFor="let item of createPages(numberOfPages)" class="inlineBlock padding0">
                    <ng-component *ngIf="item!=currentPage; then standard else current_page"></ng-component>

                    <ng-template #standard>
                        <ng-component *ngIf="item!='...'; then link else points"></ng-component>
                        <ng-template #link><a (click)="changePage(item)" class="page">{{item}}</a></ng-template>
                        <ng-template #points><span>.....</span></ng-template>
                    </ng-template>
                    <ng-template #current_page><span *ngIf="item==currentPage || item=='...'" class="page active">{{item}}</span>
                    </ng-template>
                </div>
        <span (click)="changePage(numberOfPages)" class="page">
          <i class="fas fa-angles-right"></i>
        </span>
            </div>
                <select class="form-control box" #t (change)=changeItemPerPage(t.value)>
                  <ng-container *ngFor="let option of itemsPerPageSelect">
                    <option [selected]="(option == itemPerPage) ? 'selected' : ''">{{option}}</option>
                  </ng-container>
                </select>
            </div>
    </div>
</div>


<div *ngIf="hasHeader" class="breadcrumbs">
    <div class="row">
    <div style="display: flex; align-items: center; justify-content: space-between;">
      <div class="col-10 padding0 col-md-6 col-lg-4 " style="display: flex; gap: 10px;">
        <form class=" padding0 form-group marginBottom0 " role="search">
          <div class="input-group search-group">
            <span class="input-group-addon search-icon" (click)="search(searchinput.value)">
              <i class="fa fa-search"></i>
            </span>
            <input type="text" class="form-control searchinput" #searchinput placeholder="{{'api.search' | translate}}"
              (keydown)="keyDownFunction($event, searchinput.value)" value="{{searchText}}">
            <span class="input-group-addon submit-icon" (click)="search(searchinput.value)">
              <i class="fa fa-arrow-right whiteImportant"></i>
          </span>
                </div>
            </form>
        <button
          *ngIf="table_filter.length >0"
          (click)="resetFilters()">{{ 'api.reload' | translate }}
        </button>
        </div>
      <div style="display: flex; align-items: center; justify-content: center; gap: 10px;">
        <div style="display: flex; align-items: center; justify-content: center;"
        *ngIf="numberOfPages > 1">
        <span (click)="changePage(1)" class="page">
          <i class="fas fa-angles-left"></i>
        </span>
                <div *ngFor="let item of createPages(numberOfPages)" class="inlineBlock padding0">
                    <ng-component *ngIf="item!=currentPage; then standard else current_page"></ng-component>

                    <ng-template #standard>
                        <ng-component *ngIf="item!='...'; then link else points"></ng-component>
                        <ng-template #link><a (click)="changePage(item)" class="page">{{item}}</a></ng-template>
                        <ng-template #points><span>.....</span></ng-template>
                    </ng-template>
                    <ng-template #current_page><span *ngIf="item==currentPage || item=='...'" class="page active">{{item}}</span>
                    </ng-template>
                </div>
          <span (click)="changePage(numberOfPages)" class="page">
            <i class="fas fa-angles-right"></i>
          </span>
            </div>
                <select class="form-control box" #t (change)=changeItemPerPage(t.value)>
                  <ng-container *ngFor="let option of itemsPerPageSelect">
                    <option [selected]="(option == itemPerPage) ? 'selected' : ''">{{option}}</option>
                  </ng-container>
                </select>
            </div>
    </div>
  </div>
</div>




<div class="col-lg-12 col-md-12 col-sm-12 col-12 machines-table">

  <div class="filter-overlay active" (click)="onChangeToggleFilter(false)" *ngIf="toggleFilter"></div>

  <div class="filter-box" *ngIf="totalSerachAble > 0" [ngClass]="{
        'active': toggleFilter,
        'inactive': !toggleFilter
    }">
    <div class="filter-button" [ngClass]="{ 'active': toggleFilter }" (click)="onChangeToggleFilter(!toggleFilter)">
      <i class="fas fa-filter"></i>
      <span style="font-size: 16px;">Filter</span>
      <i class="fas fa-xmark" *ngIf="toggleFilter"></i>
    </div>
    <div class="filter-content">
      <div class="filter-header">
        <h3 style="font-size: 20px; font-weight: 600;">{{
          'api.filter' | translate
          }}</h3>
        <i class="fas fa-xmark filter-header-cross" (click)="onChangeToggleFilter(false)"></i>
      </div>
      <div class="filter-body">



        <div *ngIf="hasHeader && hasHeaderWithSortBy" class="row breadcrumbPart">
          <div class="inlineBlock marginRight10">
            <label for="selectbox" class="control-label">{{ 'api.sort_on' | translate }}:&nbsp;</label>
          </div>
            <div class="inlineBlock">
            <select id="selectbox" class="form-control box" #sort (change)=changeSortBy(sort.value)>
              <option *ngFor="let item of sortBy" value="{{item.value}}"
                [selected]="(item.value == currentSortBy) ? 'selected' : ''">{{ item.name | translate }}
              </option>
            </select>
          </div>
        </div>

        <div *ngFor="let column of columns">
          <app-table-filter #valami (onFilterChange)="onFilterChange($event)" class="table-filter"
            [type]="filter_type[column.name]" [column]="column" [column_name]="column.name"
            [column_searchable]="column.searchable" [tableFilter]="tableFilter"></app-table-filter>
        </div>

        <div class="absoluteBottom" *ngIf="table_filter.length >0">
          <button class="" (click)="resetFilters()">{{ 'api.reset_filters' | translate }}
          </button>
        </div>

            </div>
        </div>

  </div>

  <div *ngIf="compare.length > 0" class="compare-button">
    <div class="compare-header" (click)="onToggleCompare()">
      <i class="fas " [ngClass]="{
                      'fa-chevron-down': toggleCompare,
                      'fa-chevron-up': !toggleCompare
                  }"></i>
      <span style="font-size: 16px;">{{'api.compare' | translate}} ({{ compare.length }}/3) </span>
    </div>
    <div *ngIf="toggleCompare">
      <div *ngFor="let item of compareItemsArray">
        <!-- <div class="compare-item" *ngIf="item.isTractor">
          <div>
            <h6>{{ item.brand }} - {{ item.subcategory.name }} [ {{ item.construction_year }} ] </h6>
            <p>Series : {{ item.series }} , Running : {{ item.running_hours }} </p>

          </div>
          <div>
            <i class="fas fa-times" (click)="removeCompareItem(item.id)"></i>
          </div>
        </div> -->
        <div class="compare-item">
          <div>
            <h6>{{ item.brand }} - {{ item.subcategory.name }} <span
              *ngIf="item.construction_year"
              >[ {{ item.construction_year }} ]</span> </h6>
            <p
              *ngIf="item.running_houres_mh"
            >Running Hours : {{ item.running_houres_mh }} </p>

          </div>
          <div>
            <i class="fas fa-times" (click)="removeCompareItem(item.id)"></i>
          </div>
        </div>

    </div>
      <div class="compare-btn">
        <!-- disabled=" {{compare.length < 2 ? true : false}}" -->
        <button [disabled]="compare.length < 2" (click)="generateRouterLink()" class="login-name buttons ">
          {{
          'api.compare' | translate
          }}
        </button>
</div>
    </div>
</div>

  <div style="width: 100%; overflow: auto;">
<table class="table table-bordered table-hover sales-list fontSize11Important marginTop10">

    <thead>
    <tr *ngIf="columns.length > 0">
          <th *ngIf="showCompare">
            {{ 'api.compare' | translate }}
          </th>
        <ng-container *ngFor="let column of columns">

            <th ngClass="open-search" style="position: relative; padding-top: 10px; padding-bottom: 10px;">
            <!--{{column.formattedName | translate}}-->

          <!--<div class="search-popup" (click)="preventDefault($event)">-->
            <!--<div class="close-btn" (click)="closeSearch($event)" style="float: right;">-->
              <!--<img src="assets/images/close.png">-->
            <!--</div>-->
            <!--<div style="padding-right: 35px;">-->
<!--          todo fix this [()] bindings-->
              <!-- <app-table-filter #valami (onFilterChange)="onFilterChange($event)" class="table-filter" [type]="filter_type[column.name]" [column]="column" [column_name]="column.name" [column_searchable]="column.searchable" [tableFilter]="tableFilter" ></app-table-filter> -->
              {{
              column.formattedName | translate
              }}
            <!--</div>-->
          <!--</div>-->
            <!--<div *ngIf="column.searchable">-->
                <!--<div style="border: 1px solid grey; top: 0; right: 0; padding: 2px; position: absolute;" (click)="openSearch($event, column.searchable)" ><i class="fa fa-sort-down" aria-hidden="true"></i></div>-->
                <!--<div style="border: 1px solid grey; top: 0; right: 0; padding: 2px; position: absolute;"><i class="fa fa-filter" aria-hidden="true"></i></div>-->
            <!--</div>-->

            <!--<ng-container *ngFor="let selected of selected_filter" >-->

            <!--&lt;!&ndash;<div class="selected-filter" *ngIf="selected.text != '' && selected.attr == column.name"> {{selected.text}} </div>&ndash;&gt;-->

                    <!--&lt;!&ndash;<div *ngIf="selected.text != '' && selected.attr == column.name" style="border: 1px solid grey; top: 0; right: 0; padding: 2px; position: absolute;"><i class="fa fa-filter" aria-hidden="true"></i></div>&ndash;&gt;-->

            <!--</ng-container>-->


        </th>

        </ng-container>

        <th *ngIf="hasActions" class="actions-column textAlignCenter"><a
                (click)="addAction()"><i class="fa fa-user-plus" aria-hidden="true"></i></a></th>

    </tr>
    </thead>
  <tbody *ngIf="rows" [dragula]='"table-box"' [(dragulaModel)]='rows'>
    <tr *ngIf="hasActions && !draggable">
          <td *ngFor="let column of columns;"></td>
      <td class="actions-column textAlignCenter"><a (click)="addAction()"><i class="fa fa-user-plus" aria-hidden="true"></i></a></td>
    </tr>
        <tr *ngFor="let row of rows;" (dblclick)="rowClickAction(row)">
          <!-- if showCompare -->
          <td *ngIf="showCompare">
            <div class="compare-checkbox">
              <input type="checkbox" (change)="compareItems($event, row.id , row)" [checked]="compare.includes(row.id)"
                [disabled]="compare.length >= 3 && !compare.includes(row.id)">
            </div>
          </td>
          <td *ngFor="let column of columns;" [attr.align]="(!column.align)?'left':column.align"
            [ngClass]="{'bold': row.attr_is_bold}">

            <span *ngIf="!column.goToDetailPage"
                  [innerHtml]="
                    column.pipes
                        ? (
                            nameOrKey(row, column.name, column.key)  | multipipe:column.pipes.replace('currency_type', row[column.name + '_currency']).replace('customStatuses1', customStatuses1).replace('customStatuses2', customStatuses2).replace('customStatuses3', customStatuses3))
                        : (nameOrKey(row, column.name, column.key) == '1' && (column.type == '3' || column.type == '4' || column.name == 'year_of_manufacture' ))?'other' : nameOrKey(row, column.name, column.key)"
            [ngClass]=" column.name == 'location' ? 'location' : '' "></span>
            <span *ngIf="column.goToDetailPage"
                  [innerHtml]="column.pipes
                        ? ( (column.key ? row[column.name][column.key] : row[column.name]) | multipipe:column.pipes.replace('currency_type', row[column.name + '_currency']) | translate)
                        :((column.key ? row[column.name][column.key] : row[column.name]) =='1' && (column.type == '3' || column.type == '4' || column.name == 'year_of_manufacture' ))?'other' : (column.key ? row[column.name][column.key] : row[column.name])"
                  (click)="rowClickAction(row)" class="link"
            ></span>

            <span *ngIf="column.type == 'checkbox'">
                <input type="checkbox" value="{{row[column.valueField]}}" (change)="checkboxCheckedAction()" [(ngModel)]="formElementsData[column.name][row[column.valueField]]">
            </span>

        </td>
        <td *ngIf="hasActions" class="actions-column">
            <a (click)="editAction(row)"><i class="fa fa-cogs" aria-hidden="true"></i></a>
            <a (click)="deleteAction(row)"><i class="fa fa-minus-circle pull-right sales-delete" aria-hidden="true"></i></a>
        </td>
    </tr>
    <tr *ngIf="hasActions && !draggable">
      <td *ngFor="let column of columns;"></td>
      <td class="actions-column textAlignCenter"><a (click)="addAction()"><i class="fa fa-user-plus" aria-hidden="true"></i></a></td>
    </tr>
    </tbody>
</table>

    <div style="display: flex; align-items: center; justify-content: center; margin-top: 12px;"
      *ngIf="numberOfPages > 1">
      <span (click)="changePage(1)" class="page">
        <i class="fas fa-angles-left"></i>
      </span>
      <div *ngFor="let item of createPages(numberOfPages)" class="inlineBlock padding0">
        <ng-component *ngIf="item!=currentPage; then standard else current_page"></ng-component>
        <ng-template #standard>
          <ng-component *ngIf="item!='...'; then link else points"></ng-component>
          <ng-template #link><a (click)="changePage(item)" class="page">{{ item }}</a></ng-template>
          <ng-template #points><span>.....</span></ng-template>
        </ng-template>
        <ng-template #current_page><span *ngIf="item==currentPage || item=='...'" class="page active">{{ item }}</span>
        </ng-template>
      </div>
      <span (click)="changePage(numberOfPages)" class="page">
        <i class="fas fa-angles-right"></i>
      </span>
    </div>


  </div>
</div>
