import {Injectable} from '@angular/core';
import {GeneralService} from './general.service';

@Injectable({
    providedIn: 'root'
})
export class MachineListingService extends GeneralService {

    //searchTerm: SearchTerm[];
    searchTerm: any = {};
    listType: string = '';
    sortBy: any = {};

    setListType(listType) {
        this.listType = listType;
    }

    setSearchTerm(searchTerm, listType) {
        this.listType = listType;
        this.searchTerm[listType] = searchTerm;
    }

    getSearchTerm() {
        if (typeof this.searchTerm[this.listType] !== 'undefined') {
            return this.searchTerm[this.listType];
        } else {
            return {'filtered_categories': [],
                    'filtered_types': [],
                    'filtered_brands': [],
                    'filtered_subcategories': [],
                    'filtered_statuses': [],
                    'filtered_incoming_statuses': [],
                    'filtered_outgoing_statuses': [],
                    'filtered_owners': [],
                    'string': '',
                    'status_filter': '',
                    'table_filter': []};
        }
    }

    getListType() {
        return this.listType;
    }

    setSortBy(sortBy) {
        this.sortBy[this.listType] = sortBy;
    }

    getSortBy() {
        if (typeof this.sortBy[this.listType] !== 'undefined') {
            return this.sortBy[this.listType];
        } else {
            return 'id_desc';
        }
    }
}
