import {Injectable} from '@angular/core';
import {MkaApiRequest} from '../../shared/services/mka-api-request.service';

@Injectable({
    providedIn: 'root'
})
export class GroupSelectionService {

    constructor(private mkaApiRequest: MkaApiRequest) {
    }

    // Get the groups list
    list(type: string) {
        return this.mkaApiRequest.getRequest('my_groups/' + type);
    }

    // Machine sent to a group for bidding
    send(machine_id: number, type: string, data: any, scope: any) {
        if (scope === 'send_to_bid') {
            return this.mkaApiRequest.postRequest('machine/' + machine_id + '/send_to_group/' + type, data);
        } else {
            return this.mkaApiRequest.postRequest('machine/' + machine_id + '/send_sales_price', data);
        }
    }
}

