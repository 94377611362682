<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>{{'api.contact_form' | translate}}</h3>
        </div>
    </div>
    <div class="row">
        <form #form>
            <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-addon"><i class="fa fa-user"></i></div>
                        <input type="text" class="form-control" id="contactName" name="name" placeholder="{{'api.contact_name' | translate}}" [(ngModel)]="name">
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-addon"><i class="fa fa-at"></i></div>
                        <input type="email" class="form-control" id="contactEmail" name="email" placeholder="{{'api.contact_email' | translate}}" [(ngModel)]="email">
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-addon"><i class="fa fa-phone"></i></div>
                        <input type="text" class="form-control" id="contactPhone" name="phone" placeholder="{{'api.contact_phone' | translate}}" [(ngModel)]="phone">
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group">
                    <textarea class="form-control" id="contactDescription" name="description" placeholder="{{'api.contact_description' | translate}}" [(ngModel)]="description"></textarea>
                </div>
                <div class="row">
                    <div class="col-sm-8">
                        <input type="file" id="attachment" name="attachment" multiple #attachmentInput>
                    </div>
                    <div class="col-sm-4 text-right">
                        <input (click)="sendContact()" type="submit" id="submit" class="buttons" value="{{send_button_text | translate}}" placeholder="{{'api.contact_attachment' | translate}}" >
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>