import {
    Component, Input, Output, EventEmitter
} from '@angular/core';
import {MkaApiRequest} from '../../services/mka-api-request.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-export-time-popup',
    templateUrl: './export-time-popup.component.html',
    styleUrls: [
        './export-time-popup.component.scss'
    ],
})

export class ExportTimePopupComponent {

    fromTime: string = '';
    untilTime: string = '';
    @Input() modalId: string;
    @Output() continue = new EventEmitter<any>();

    constructor(protected mkaApiRequest: MkaApiRequest, protected route: ActivatedRoute) {
        const tmp = new Date();
        this.fromTime = this.dateToString(new Date(tmp.setMonth(tmp.getMonth() - 3)));
        this.untilTime = this.dateToString(new Date());
    }

    continueClicked() {
        this.continue.emit({fromTime: this.fromTime, untilTime: this.untilTime});
    }

    dateToString(date) {
        const pad = '00';
        const year = date.getFullYear();
        const month = '' + (date.getMonth() + 1);
        const day = '' + date.getDate();
        const hours = '' + date.getHours();
        const minutes = '' + date.getMinutes();
        const seconds = '' + date.getSeconds();

        return year + '-'
            + pad.substring(0, pad.length - month.length) + month + '-'
            + pad.substring(0, pad.length - day.length) + day + ' '
            + pad.substring(0, pad.length - hours.length) + hours + ':'
            + pad.substring(0, pad.length - minutes.length) + minutes + ':'
            + pad.substring(0, pad.length - seconds.length) + seconds;
    }
}
