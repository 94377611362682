<div class="row breadcrumb custom-breadcrumb marginTop10">
    <div class="col-6 links verticalAlignMiddle">
        <a href="javascript:;"  [routerLink]="[this.url + this.prev]" *ngIf="this.prev!==0 && this.prev!==''">
            <span>⟨&nbsp;</span>
            <span> {{'api.prev_id' | translate}}</span>
        </a>
    </div>
    <div class="col-6 links verticalAlignMiddle textAlignRight">
        <a href="javascript:;" [routerLink]="[this.url + this.next]" *ngIf="this.next!==0 && this.next!==''">
            <span> {{'api.next_id' | translate}}</span>
            <span>&nbsp;&nbsp;⟩</span>
        </a>
    </div>
</div>
