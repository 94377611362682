import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralList} from '../../../helper-classes/general-list';
import {GeneralService} from '../../../services/general.service';
import {Role} from '../../../models/roles';
import {GeneralDetailEvent} from '../../../models/event';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';

@Component({
    selector: 'app-salesman-listing',
    templateUrl: './salesman-listing.component.html',
    styleUrls: [
        './salesman-listing.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        GeneralService,
        {provide: 'uri', useValue: 'salesmen'},
    ]
})

export class SalesmanListingComponent extends GeneralList implements OnInit {

    @Input() actionLink: string;
    @Input() showDealer: boolean = false;

    constructor(
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected generalDetailHandler: GeneralDetailEventService
    ) {
        super(generalService, route, router, generalDetailHandler);

        this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterRefreshAllItem).subscribe(() => {
            for (const row of this.rows) {
                row.attr_is_bold = (row.roles[0].name === Role.SalesManager) ? 1 : 0;
            }
        });
    }

    ngOnInit() {
        // general initialization
        this.initialize();


        this.columns = [];

        if (this.showDealer) {
            this.columns.push(
                {
                    name: 'dealer',
                    formattedName: 'api.dealer',
                    key: 'name'
                }
            );
        }
        // specify the listing columns
        this.columns.push(
            {
                name: 'company',
                formattedName: 'api.company_name',
                key: 'name'
            },
            {
                name: 'name',
                formattedName: 'api.contact_person'
            },
            {
                name: 'email',
                formattedName: 'api.email_address'
            },
            {
                name: 'mobile',
                formattedName: 'api.mobile'
            },
            {
                name: 'function',
                formattedName: 'api.function'
            }
        );
    }
}
