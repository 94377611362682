import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../services/general.service';
import {GeneralDetail} from '../../helper-classes/general-detail';
import {Company} from '../../models/company';
import {ErrorEventService} from '../../services/error-event.service';
import {LoadingEventService} from '../../services/loading-event.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-company-detail',
    templateUrl: './company-detail.component.html',
    styleUrls: [
        './company-detail.component.scss'
    ],

    providers: [
        GeneralService,
        {provide: 'uri', useValue: 'companies'},
    ],
})

export class CompanyDetailComponent extends GeneralDetail implements OnInit {

    public item: Company;

    public salesmen_links = (environment.has_admin) ? 'admin-platform/salesmen' : 'internal-platform/salesmen';

    constructor(
        protected generalService: GeneralService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected renderer: Renderer2
    ) {
        super(generalService, router, route, errorEventService, loadingEventService, renderer);
    }

    ngOnInit() {

        // initialize the item
        this.item = new Company();

        // general initialization
        this.initialize();

        // set the onSubmitNavigateUrl (where the page redirect after commit) based on groupType
        this.onSubmitNavigateUrl = this.platform + '/companies';

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.companies',
                url: this.platform + '/companies',
                isActive: false,
            },
            {
                name: (this.item.id > 0) ? 'api.edit' : 'api.add',
                url: '',
                isActive: true,
            }];
    }
}
