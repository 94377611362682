<div class="container">
    <app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>

    <div class="row">
        <div class="col-sm-6">
            <h3 class="sales-heading">{{ (item.id ? 'api.edit_company':'api.add_company') | translate }}</h3>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <form class="form-horizontal" #form>
                <div class="form-group">
                    <label for="name" class="col-sm-4">{{ 'api.company_name' | translate }}</label>
                    <div class="col-sm-8">
                        <input type="text" name="name" class="form-control" id="name"
                               placeholder="{{ 'api.company_name' | translate }}" [(ngModel)]="item.name">
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4"></label>
                    <div class="col-sm-8">
                        <input type="submit" title="" class="login-name buttons marginTop5" id="confirm"
                               value="{{ 'api.confirm' | translate }}" (click)="onSubmit()">
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4">{{'api.related_users' | translate}}:</label>
                    <div class="col-sm-8">
                        <span *ngFor="let id of item.user_ids">
                            <a href="{{salesmen_links}}/{{id}}">{{id}}</a>
                        </span>
                    </div>
                </div>


            </form>

        </div>

    </div>
</div>
