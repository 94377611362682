import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {NotFoundComponent} from './shared/components/notfound/notfound.component';
import {WelcomeComponent} from './shared/components/welcome/welcome.component';
import {AuthModule} from './auth-module/auth.module';
import {DisclaimerComponent} from './shared/components/disclaimer/disclaimer.component';
import {PrivacyComponent} from './shared/components/privacy/privacy.component';
import {ContactFormComponent} from './shared/components/contact-form/contact-form.component';
import {HasAdminEnabled} from './shared/access-guards/has-admin-guard';
import {PlatformSelect} from './shared/access-guards/platform-select-guard';
import {UnsubscribeComponent} from './shared/components/unsubscribe/unsubscribe.component';
import {LoginComponent} from './auth-module/components/login/login.component';
import {ForgotPasswordComponent} from './auth-module/components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './auth-module/components/reset-password/reset-password.component';
import {AutologinComponent} from './auth-module/components/autologin/autologin-component';
import {UserProfileResolver} from './shared/resolves/user-profile.resolver';
import { ExtraFieldsResolver } from './shared/resolves/extra-fields.resolver';
import { FieldsdetailsResolver } from './shared/resolves/fieldsdetails.resolver';

const routes: Routes = [
    {
        path: 'trader-platform',
        loadChildren: () => import('./trader-platform-module/trader-platform.module').then(m => m.TraderPlatformModule),
        canActivate: [PlatformSelect],
        resolve: {
            userProfile: UserProfileResolver
        }
    },
    {
        path: 'sales-platform',
        loadChildren: () => import('./sales-platform-module/sales-platform.module').then(m => m.SalesPlatformModule),
        canActivate: [PlatformSelect]
    },
    {
        path: 'internal-platform',
        loadChildren: () => import('./internal-platform-module/internal-platform.module').then(m => m.InternalPlatformModule),
    canActivate: [PlatformSelect],
    resolve: {
      extraFields: ExtraFieldsResolver,
      fieldsDetails: FieldsdetailsResolver,
    }
    },
    {
        path: 'internal-platform/leads',
        loadChildren: () => import('./leads-platform-module/leads-platform-module.module').then(m => m.LeadsPlatformModuleModule),
        canActivate: [PlatformSelect]
    },
    {
        path: 'admin-platform',
        loadChildren: () => import('./admin-platform-module/admin-platform.module').then(m => m.AdminPlatformModule),
    canActivate: [HasAdminEnabled && PlatformSelect],
    resolve: {
      extraFields: ExtraFieldsResolver,
      fieldsDetails: FieldsdetailsResolver,
    }
    },
    {
    path: 'guest-platform',
    loadChildren: () => import('./guest-platform-module/guest-platform.module').then(m => m.GuestPlatformModule),
    canActivate: [PlatformSelect]
  },
  {
        path: 'welcome',
        component: WelcomeComponent
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
      forgotPasswordLink: 'forgot-password'
        },
        canActivate: [PlatformSelect]
    },
    {
        path: 'forgot-password', component: ForgotPasswordComponent,
        canActivate: [PlatformSelect]
    },
    {
        path: 'reset-password/:token', component: ResetPasswordComponent, data: {
            pathAfterLogin: 'login'
        },
        canActivate: [PlatformSelect]
    },
    {
        path: 'autologin/:token', component: AutologinComponent,
        canActivate: [PlatformSelect]
    },
    {
        path: 'disclaimer',
        component: DisclaimerComponent
    },
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    {
        path: 'contact',
        component: ContactFormComponent
    },
    {
        path: 'unsubscribe',
        component: UnsubscribeComponent
    },

    // default redirect to welcome
    {
        path: '', redirectTo: 'welcome', pathMatch: 'full'
    },

    // page not found
  { path: '**', component: NotFoundComponent }


];

@NgModule({
    imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}), AuthModule],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {
}
