import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorEventService } from '../../../shared/services/error-event.service';
import { LoadingEventService } from '../../../shared/services/loading-event.service';
import { GeneralService } from '../../../shared/services/general.service';
import { AuthService } from '../../../shared/services/auth.service';
import { TranslatePipe } from '@ngx-translate/core';
import { MachineListingService } from '../../../shared/services/machineListing.service';
import {
  CompareBaseMachineDetailSingleComponent
} from 'app/shared/components/abstract-classes/machines/compare/compare-machine-detail-single.component';
import { ComparisionSetupService } from 'app/shared/services/comparison-setup.service';
import { GeneralDetailEventService } from 'app/shared/services/general-detail-event.service';

@Component({
  selector: 'app-compare-machine-detail-single-admin',
  templateUrl: '../../../shared/components/abstract-classes/machines/compare/compare-machine-detail-single.component.html',
  styleUrls: [
    '../../../shared/components/abstract-classes/machines/compare/compare-machine-detail.component.scss'
  ],
  providers: [
    { provide: 'instance1', useClass: GeneralService },
    { provide: 'instance2', useClass: GeneralService },
    { provide: 'instance3', useClass: GeneralService },
    { provide: 'uri', useValue: 'machines' },
    TranslatePipe
  ],
})
export class CompareMachineDetailAdminSingleComponent extends CompareBaseMachineDetailSingleComponent implements OnInit {
  section: string = '';

  selectedMachineIds: number[] = [];


      constructor(
          protected errorEventService: ErrorEventService,
          protected loadingEventService: LoadingEventService,
          @Inject('instance1') protected generalService: GeneralService,
          @Inject('instance2') protected statusService: GeneralService,
          @Inject('instance3') protected machineService: GeneralService,
          protected route: ActivatedRoute,
          protected router: Router,
          protected renderer: Renderer2,
          protected authService: AuthService,
          protected translatePipe: TranslatePipe,
          protected machineListingService: MachineListingService,
          protected generalDetailHandler: GeneralDetailEventService) {
          super(errorEventService,
              loadingEventService,
              generalService,
              statusService,
              machineService,
              route,
              router,
              renderer,
              generalDetailHandler,
              translatePipe,
              true,
              machineListingService);
  
      }

  ngOnInit() {
    super.ngOnInit(); // Call the parent ngOnInit to handle the logic from the base class
    this.url = '/admin-platform/machines/';
  }
}