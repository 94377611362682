<div class="container text-center padding10x0" #form>
    <div class="container login-bg">
        <img class="reesink-logo" src={{bannerLogo}}>
    </div>
    <h3 class="marginTop15 marginBottom15">{{'api.request_new_password' | translate}}</h3>

    <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
            <div class="col-sm-12 reset-background">
                <i class="fa fa-user fontSize150" aria-hidden="true"></i>
                <form method="post">
                    <div class="login-form">
                        <div class="form-group marginBottom0">
                            <input [(ngModel)]="credentials.password" name="password" type="password" title="{{'api.type_new_password' | translate}}"
                                   value=""
                                   class="form-control login-field marginBottom15" id="password" placeholder="{{'api.type_new_password' | translate}}"
                                   required="required">
                            <input [(ngModel)]="credentials.password_confirmation" name="repassword" type="password"
                                   title="{{'api.retype_new_password' | translate}}" value=""
                                   class="form-control login-field" id="repassword"
                                   placeholder="{{'api.retype_new_password' | translate}}" required="required">
                            <label class=""></label>
                        </div>
                        <p><input (click)="resetPassword()" type="submit" name="password_reset_submit" title="" value="{{'api.confirm_new_password' | translate}}"
                                  id="submit-button" class="btn btn-primary btn-lg btn-block reset-btn"></p>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-sm-4"></div>
    </div>
</div>
