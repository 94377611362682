import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoryService} from '../../../services/category.service';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {BaseCategoriesComponent} from '../../abstract-classes/categories/categories.component';

@Component({
    selector: 'app-categories',
    templateUrl: '../../abstract-classes/categories/categories.component.html',
    styleUrls: [
        '../../abstract-classes/categories/categories.component.scss'
    ],

    // create a separate instance of the GeneralService which will use the below uri
    providers: [
        CategoryService,
        {provide: 'uri',  useValue: 'categories'},
    ]
})

export class CategoriesComponent extends BaseCategoriesComponent implements OnInit {

    section: string = '';

    constructor(protected categoryService: CategoryService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected generalDetailHandler: GeneralDetailEventService,
                protected fieldsdetailsService: FieldsdetailsService) {
        super(categoryService, route, router, generalDetailHandler, fieldsdetailsService);
    }
}
