import {Component, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../../shared/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralDetail} from '../../../shared/helper-classes/general-detail';
import {GeneralService} from '../../../shared/services/general.service';
import {GroupSelectionService} from '../../../internal-platform-module/services/group-selection.service';
import {ErrorEventService} from '../../../shared/services/error-event.service';
import {LoadingEventService} from '../../../shared/services/loading-event.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-forgot-password',
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent extends GeneralDetail implements OnInit {

    public credentials: any = {
        email: '',
    };
    private scope: string;
    public bannerLogo = environment.toro_banner_logo;
    constructor(
        private authService: AuthService,
        protected errorEventService: ErrorEventService,
        protected loadingEventService: LoadingEventService,
        protected generalService: GeneralService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected renderer: Renderer2,
        protected groupSelectionService: GroupSelectionService) {
        super(generalService, router, route, errorEventService, loadingEventService, renderer);
        super.parentInitialize();
    }

    /**
    * makes the API call to send out forgotten password email
    */
    forgotPassword() {
        this.authService.sendForgetPassword(this.credentials.email, this.scope).subscribe(
            () => {
                this.credentials.email = '';
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
    * interprets the parameters
    * sets scope
    */
    ngOnInit() {
        this.route.data.subscribe((data) => {
            this.scope = 'web-api';
        });
    }
}
