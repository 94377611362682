import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {GeneralService} from './general.service';
import {environment} from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GroupsService extends GeneralService {
     private isDealersEnabled = environment.has_admin;
    // Get users list what can be included in a group
    getUserList(type: string): Observable<any> {
        return this.mkaApiRequest.getRequest('group_users/' + type).pipe(map((item) => {
            const data = item.data;
            data.map((user) => {
                user.text = user.email;
                return user;
            });
            return data;
        }));
    }

    // get a group item modifying the users email
    getItem(id: number): Observable<any> {
        return super.getItem(id).pipe(map((item) => {
            const data = item.data;
            if (!this.isDealersEnabled) {
              delete data.dealer_id;
            }
            // the "ng-select multiple" works only with text
            data.users.map((user) => {
                user.text = user.email;
            });
            return data;
        }));
    }

    // set the user_ids to be able to send to the API
    save(item) {
        item.user_ids = item.users.map((user) => {
            return user.id;
        });
        return super.save(item);
    }
}

