<div class="container">
    <app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>

    <div class="row group-select-container">
        <div class="col-sm-2">
            <label for="group_selector" class="">{{'api.select_group' | translate}} </label>
        </div>
        <div class="col-sm-6">
            <select name="group_selector" id="group_selector" class="custom-select" [(ngModel)]="selectedGroup"
                    (change)="groupSelectionChanged($event)">
                <option *ngFor="let group of translationGroups" [ngValue]="group">{{group}}</option>
            </select>
        </div>
        <div class="col-sm-3">
            <button type="button" class="btn publish-button" (click)="publishTranslations()">
                {{'api.publish_translations' | translate}}
            </button>
        </div>
        <div class="col-sm-1">
            <button *ngIf="selectedGroup" type="button" class="btn publish-button" (click)="openModalForAdd()">
                <i class="fa fa-plus-square"></i>
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12">
            <div class="row key-container" *ngFor="let translation of translationItems">
                <div class="col-sm-5">{{translation.key}}</div>
                <div class="col-sm-6">
                    <div class="row" *ngFor="let locale of translation.values">
                        <div class="col-sm-2"><b>{{locale.locale}}</b></div>
                        <div class="col-sm-10">
                            <a class="value" [ngClass]="{'changed': locale.status == 1}"
                               (click)="openModal(translation.key, locale.locale, locale.value, locale)">
                                {{locale.value}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1">
                    <a (click)="deleteTranslation(translation.key)"><i class="fa fa-minus-circle pull-right sales-delete" aria-hidden="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div #translationEditModal class="modal fade" id="translationEditModal" tabindex="-1" role="dialog"
     aria-labelledby="translationEditModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row new-translation" *ngIf="newTranslation">
                    <div class="col-sm-12">
                        <h4>{{'api.add_new_translation' | translate}}: {{newTranslation.group}}</h4>
                    </div>
                    <div class="col-sm-4">
                        {{'api.translation_key' | translate}}
                    </div>
                    <div class="col-sm-8">
                        <input type="text" [(ngModel)]="newTranslation.key"/>
                    </div>
                    <ng-container *ngFor="let lng of languageList">
                        <div class="col-sm-4 key-input">
                            {{'api.translation_value' | translate}}:{{lng}}
                        </div>
                        <div class="col-sm-8 key-input">
                            <input type="text" [(ngModel)]="newTranslation.values[lng]"/>
                        </div>
                    </ng-container>
                </div>
                <div class="row" *ngIf="!newTranslation">
                    <div class="col-sm-12">
                        <textarea [(ngModel)]="currentTranslationValue"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <button type="button" class="btn close-button float-right" data-dismiss="modal">{{'api.close' |
                            translate}}
                        </button>
                        <button type="button" class="btn save-button float-right" (click)="saveTranslation()">
                            {{'api.save' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>