import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {TransApiService} from '../../services/trans-api.service';
import {TranslatePipe} from '@ngx-translate/core';
import {GeneralService} from '../../services/general.service';
import {GeneralDetailEvent} from '../../models/event';

@Component({
    selector: 'app-unsubscribe',
    templateUrl: './unsubscribe.component.html',
    styleUrls: ['./unsubscribe.component.scss'],
    providers: [TranslatePipe]
})
export class UnsubscribeComponent implements OnInit {

    public token: string;
    public message: string;

    constructor(
        private route: ActivatedRoute,
        public translatePipe: TranslatePipe,
        protected unsubscribeService: GeneralService,
    ) {
        this.message = this.translatePipe.transform('api.unsubscribe_in_progress');
        this.unsubscribeService.setUri('unsubscribe');
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.token = params['token'];
            if (!this.token) {
                this.message = this.translatePipe.transform('api.unsubscribe_failed');
                return false;
            }
            this.unsubscribeService.save({token: this.token}).subscribe(
                data => {
                    const response = data;
                    if (response.data.message) {
                        this.message = response.data.message;
                    }
                },
                (error) => {
                    console.log(error);
                });
        });
    }

}
