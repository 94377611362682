export class Trader {
  id?: number = 0;
  company_name?: string;
  billing_address?: string;
  billing_postcode?: string;
  billing_place?: string;
  billing_country?: string;
  delivery_address?: string;
  delivery_postcode?: string;
  delivery_place?: string;
  delivery_country?: string;
  telephone?: string;
  fax?: string;
  website?: string;
  contact_lastname?: string;
  contact_firstname?: string;
  contact_function?: string = "trader";
  contact_email?: string;
  contact_telephone?: string;
  contact_mobile?: string;
  bank_account?: string;
  swift?: string;
  chamber_of_commerce?: string;
  administration_lastname?: string;
  administration_firstname?: string;
  administration_email?: string;
  administration_email_digital_invoice?: string;
  customer_number?: string;
  expert_in?: any[] = [];
  email?: string;
  contact_website?: string;
  vat_number?: string;
  currency?: string = "EUR";
  has_dealer_inventory?: string;

  constructor() {}
}
