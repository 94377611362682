import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ContactFormService} from '../../services/contact-form.service';
import {Errorhandler} from '../../helper-classes/errorhandler';
import {ErrorEventService} from '../../services/error-event.service';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent extends Errorhandler implements OnInit {

    @ViewChild('attachmentInput', { static: true }) attachmentFiles;
    @ViewChild('form', { static: true }) form: ElementRef;
    email: string;
    phone: string;
    name: string;
    description: string;
    send_button_text: string = 'api.contact_submit';

    constructor(private contactService: ContactFormService, protected errorEventService: ErrorEventService, protected renderer: Renderer2) {
        super(errorEventService, renderer);
    }

    ngOnInit() {
        this.initialize();
        this.email = this.name = this.description = this.phone = '';
        this.attachmentFiles.nativeElement.value = '';
    }

    sendContact() {
        this.send_button_text = 'api.contact_sending';
        this.contactService.sendContactForm(this.email, this.name, this.phone, this.description, this.attachmentFiles.nativeElement).subscribe(
            data => {
                this.send_button_text = 'api.contact_submit';
                const response = data;
                this.email = this.name = this.description = this.phone = '';
            },
            (error) => {
                this.send_button_text = 'api.contact_submit';
                console.log(error);
            }
        );
    }

}
