import {Component, Input, ViewEncapsulation} from '@angular/core';
import {GeneralService} from '../../../services/general.service';

@Component({
    selector: 'app-regenerate-pdf-button',
    templateUrl: './regenerate-pdf-button.component.html',
    styleUrls: [
        './regenerate-pdf-button.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})

export class RegenaretePdfButtonComponent {
    @Input() machine;
    @Input() section: string = '';
    loading: boolean = false;

    constructor(protected machineService: GeneralService) {
    }

    generatePdf() {
        this.loading = true;
        this.machineService.setUri((this.section ? this.section + '_' : '') + 'machines/generatePdf');
        this.machineService.getItem(this.machine.id).subscribe(
            data => {
                this.machine.pdf_link = data.data;
                this.loading = false;
            }
        );
    }


}
