import { Directive, OnInit } from '@angular/core';
import {Attribute} from '../../../models/attribute';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralService} from '../../../services/general.service';
import {GeneralList} from '../../../helper-classes/general-list';
import {GeneralDetailEvent} from '../../../models/event';
import {GeneralDetailEventService} from '../../../services/general-detail-event.service';
import {FieldsdetailsService} from '../../../services/fieldsdetails.service';
import {LeadsFieldsdetailsService} from '../../../../leads-platform-module/services/leadsFieldsdetails.service';

@Directive()
export abstract class BaseAttributesComponent extends GeneralList implements OnInit {

    title: string = 'api.attributes';
    rows: Attribute[];
    loading: boolean = false;
    section: string = '';

    protected constructor(protected generalService: GeneralService,
                protected route: ActivatedRoute,
                protected router: Router,
                protected generalDetailHandler: GeneralDetailEventService,
                protected fieldsdetailsService: FieldsdetailsService | LeadsFieldsdetailsService) {
        super(generalService, route, router, generalDetailHandler);
        /*this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterRefreshAllItem).subscribe(() => {
            const firstItem = this.rows.find((item) => item.id === 1);
            this.rows[this.rows.indexOf(firstItem)].name = 'api.other';
        });*/

        this.subscriptions.push(
            this.generalDetailHandler.subscribeToEvent(GeneralDetailEvent.afterDelete).subscribe(() => {
                this.fieldsdetailsService.getAllFieldsDetails(true);
            })
        );


    }

    ngOnInit() {

        this.initialize();

        // breadcrumb elements
        this.breadcrumbs = [
            {
                name: 'api.home',
                url: this.platform + (this.section ? '/' + this.section : '') + '/machines',
                isActive: false,
            },
            {
                name: 'api.attributes',
                url: '',
                isActive: true,
            }];

        // specify the listing columns

        this.columns = [
            {
                name: 'id',
                formattedName: 'api.id'
            },
            {
                name: 'attribute_name',
                formattedName: 'api.attribute_name',
                pipes: ' '
            },
            {
                name: 'type',
                formattedName: 'api.type',
                pipes: 'attribute_type'
            }
        ];
    }

    rowClick(row) {
        // ATTRIBUTE_TYPES - select
        if (row.type === 2 || row.type === 3) {
            this.router.navigate([this.platform + (this.section ? '/' + this.section : '') + '/select-attribute-options/' + row.id]);
        }

    }

    syncAttributes() {
        this.loading = true;
        this.generalService.syncInternalAttributes();
        this.loading = false;
    }
}
