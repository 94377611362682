<div class="container">
    <app-breadcrumb  [breadcrumbs]="breadcrumbs" ></app-breadcrumb>
    <div class="row">
        <div class="col-sm-6">
            <h3 class="sales-heading">{{item.id > 0 ? ('api.edit' | translate) : ('api.add' | translate)}} {{title | translate}}</h3>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <form class="form-horizontal" #form (submit)="onSubmit()">
                <div class="form-group">
                    <label class="col-sm-4">{{'api.name' | translate}}</label>
                    <div class="col-sm-8">
                        <select class="form-control" [attr.name]="'name'" [(ngModel)]="item.name" [ngModelOptions]="{standalone: true}" (change)="attributeChanged()">
                            <option *ngFor="let column of available_columns" [value]="column.name" [selected]="(item.name == column.name) ? 'selected' : ''">{{column.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4">{{'api.formattedName' | translate}}</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="formattedName" name="formattedName" placeholder="{{'api.formattedName' | translate}}" [(ngModel)]="item.formattedName">
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4">{{'api.align' | translate}}</label>
                    <div class="col-sm-8">
                        <select class="form-control" [attr.name]="'align'" [(ngModel)]="item.align" [ngModelOptions]="{standalone: true}">
                            <option [value]="'left'" [selected]="(item.align == 'left') ? 'selected' : ''">{{'api.left' | translate}}</option>
                            <option [value]="'middle'" [selected]="(item.align == 'middle') ? 'selected' : ''">{{'api.middle' | translate}}</option>
                            <option [value]="'right'" [selected]="(item.align == 'right') ? 'selected' : ''">{{'api.right' | translate}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4">{{'api.goToDetailPage' | translate}}</label>
                    <div class="col-sm-8">
                        <select class="form-control" [attr.name]="'goToDetailPage'" [(ngModel)]="item.goToDetailPage" [ngModelOptions]="{standalone: true}">
                            <option [value]="0" [selected]="(item.goToDetailPage == 0) ? 'selected' : ''">{{'api.no' | translate}}</option>
                            <option [value]="1" [selected]="(item.goToDetailPage == 1) ? 'selected' : ''">{{'api.yes' | translate}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4">{{'api.searchable' | translate}}</label>
                    <div class="col-sm-8">

                        <select *ngIf="showSearchDropdown" class="form-control" [attr.name]="'searchable'" [(ngModel)]="item.searchable" [ngModelOptions]="{standalone: true}">
                            <option [value]="0" [selected]="(item.searchable == 0) ? 'selected' : ''">{{'api.no' | translate}}</option>
                            <option [value]="1" [selected]="(item.searchable == 1) ? 'selected' : ''">{{'api.yes' | translate}}</option>
                        </select>
                        <label *ngIf="!showSearchDropdown">No</label>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-sm-4"></label>
                    <div class="col-sm-8">
                        <input type="submit" name="submit" title="" class="buttons marginTop5" id="confirm" value="{{'api.confirm' | translate}}">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>